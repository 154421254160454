import React, { Component } from 'react';
import './body.scss';
import {
    Container,
    Row,
    Col,
    DropdownButton,
    Dropdown
} from "react-bootstrap";
import './dashboard.css';
import axios from 'axios';
import Barchart from '../chart/BarChart3';
import PieChart from '../chart/PieChart';
import { uAPIlocal } from '../lib/config'
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'react-calendar/dist/Calendar.css';
import dropdownIcon from "../../assets/br_down.png";
import GrowthArrow from "../../assets/Group 9.png";
import Navbar from './Navbar';
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Navigation } from "swiper";
// import "./assets/style.css"

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    // PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
// import filterFactory, {
//   dateFilter,
//   Comparator,
// } from "react-bootstrap-table2-filter";
import ToolkitProvider, {
    Search,
    // CSVExport,
} from "react-bootstrap-table2-toolkit";
// import cellEditFactory from "react-bootstrap-table2-editor";
import { withRouter, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import CryptoJS from "crypto-js";
let source = axios.CancelToken.source();
axios.defaults.withCredentials = true;
axios.defaults.headers.post["X-CSRF-Token"] = Cookies.get('mcu-csrf');

class Department extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirctTo: false,
            selectYear: [],
            tableTop10Individual: true,
            year1: "",
            yearLoaded: "",
            optDept: "",
            optTest: [],
            optTest1: "",
            optYear: [],
            chooseDept: "",
            chooseTest: "",
            totalPeserta: "",
            jenisKelamin: "",
            grafik1: "",
            umur: "",
            grafik2: "",
            NormalAbnormal_ID: [],
            TotalNormal: "",
            TotalAbnormal: "",
            NormalAbnormal: [],
            grafik3: "",
            Temuan: "",
            TemuanPeserta: "",
            _state: "",
            grafik4: "",

            // nextpage
            redirect: false,
            pathname: "",
            idSelected: "",
            optSelected: "",
            prevState: "",
            prevPage: "",
            goTo: "",
            action: "false",

            // props
            _id: this.props.location.state ? this.props.location.state.id : null,
            _opt: this.props.location.state ? this.props.location.state.opt : null,
            _prevPage: this.props.location.state ? this.props.location.state.prevPage : null,
            _prevState: this.props.location.state ? this.props.location.state.prevState : null,
            _goTo: this.props.location.state ? this.props.location.state.goTo : null,

            datas: [],
            loading: true,
            PesertaNormalAbnormal: [],
            isSmallScreen: false,
            hideIconUser: false,
            selectedRow: null,
            top10TemuanID: [],
            arrayConcDept: [],
            arrayConcDeptID: [],
        };
        // this.getTopDepartment = this.getTopDepartment.bind(this);
        this.getSelectYear = this.getSelectYear.bind(this);
        // this.yearLoaded = this.yearLoaded.bind(this);
        // this.getDemografiGender = this.getDemografiGender.bind(this);
        // this.getDemografiUmur = this.getDemografiUmur.bind(this);
        // this.getNormalAbnormal1 = this.getNormalAbnormal1.bind(this);
        // this.getNormalAbnormal2 = this.getNormalAbnormal2.bind(this);
        // this.getTemuan = this.getTemuan.bind(this);
        this.resizeScreen = this.resizeScreen.bind(this);
        this.autoLogout = this.autoLogout.bind(this);

    }
    async componentDidMount() {
        this.props.i18n.changeLanguage(localStorage.getItem("lang"));
        if (this.state._id != null &&
            this.state._prevPage == "people" &&
            this.state._prevState == "back" &&
            this.state._goTo == "department_peserta") {
            this.setState({ selectedRow: [this.state._id] });
            this.setState({ optTest1: this.state._opt }, () => this.changePesertaAll());
        } else { }

        this.getSelectYear();
        localStorage.setItem("path", "DASBOR");
        window.addEventListener("resize", this.resizeScreen());
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            withCredentials: true
        };

        formData.append('name', localStorage.getItem("name"));
        formData.append('message', "Akses Department");

        await axios.post(uAPIlocal + '/api/v1/log', formData, config)
            .then(response => {
                console.log(response)
                if (response.data.status === 406) {
                    console.log(response)

                } else {
                    console.log(response)
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    resizeScreen() {
        // alert(Math.round(window.innerHeight))
        if (window.innerWidth <= 1200) {
            this.setState({ isSmallScreen: true });
        } else {
            this.setState({ isSmallScreen: false });
        }

        if (window.innerWidth <= 1280) {
            this.setState({ hideIconUser: true });
        } else {
            this.setState({ hideIconUser: false });
        }

    }

    // componentWillUnmount() {
    //     if (source) {
    //         source.cancel("Landing Component got unmounted");
    //     }
    // }

    redirectPeserta(id) {
        this.setState({
            redirect: true,
            pathname: "/admin/people",
            idSelected: id,
            optSelected: this.state.optTest1,
            prevState: "department_peserta",
            prevPage: "department",
            goTo: ""
        });
    }


    changeSelect = (e) => {
        console.log('change', e)
        this.setState({ chooseDept: e }, () => this.changeAll())
    }

    setYearAll = (eventkey, event) => {
        event.persist();
        this.setState({ year1: eventkey }, () => this.changeAll())
        this.changeDept(eventkey);
    };

    setTest = (eventkey, event) => {
        event.persist();
        this.setState({ optTest1: eventkey }, () => this.changePesertaAll());
    };

    async changeAll() {
        this.getDemografiGender()
        this.getDemografiUmur()
        this.getNormalAbnormal1()
        // this.getTemuan()

    }


    async changePesertaAll() {
        this.getTopDepartment()
        // this.getTemuanDepartmentNew()
    }


    async getSelectYear() {
        await axios.post(uAPIlocal + '/api/v1/getselectyear',
            { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
            .then((res) => {
                let { results } = res.data
                let datas = []
                if (results.length > 0) {
                    results.forEach(element => {
                        if (element.mcu_year) {
                            datas.push({
                                key: element.mcu_year,
                                value: element.mcu_year
                            })
                        }
                    });
                    this.setState({ year1: datas[0]?.value })
                }
                this.setState({ optYear: datas, yearLoaded: true },

                    () => this.yearLoaded()
                )
            })
            .catch((e) => {
                console.log(e);
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
                this.autoLogout();
            });

    }

    autoLogout = () => {
        this.setState({ redirctTo: true });
    }

    async yearLoaded() {
        if (this.state.yearLoaded && this.state.year1) {
            await axios.post(uAPIlocal + '/api/v1/patient_dept', { _y: this.state.year1 },
                { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
                .then((res) => {
                    let { results } = res.data
                    console.log("INI DEPT", results)
                    let datas = []
                    if (results.length > 0) {
                        results.forEach(element => {
                            if (element.Patient_Dept) {
                                datas.push({
                                    value: element.Patient_Dept,
                                    label: element.Patient_Dept
                                })
                            }
                        });
                    }
                    this.setState({ optDept: datas, chooseDept: datas[0] }, () =>
                        this.changeAll()
                    )
                })
                .catch((err) => {
                    console.log('err', err)
                })


            await axios.post(uAPIlocal + '/api/v1/dept_test_code', {},
                { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
                .then((res) => {
                    let { results } = res.data
                    let datas = []
                    if (results.length > 0) {
                        results.forEach(element => {
                            if (element.Test_Group != "") {
                                if (element.Test_Group != "NARKOBA") {
                                    datas.push({
                                        key: element.Test_Group,
                                        value: element.Test_Group
                                    })
                                }
                            }
                        });
                    }

                    this.setState({ optTest: datas, chooseTest: datas[0] });

                })
                .catch((err) => {
                    console.log('err', err)
                })
        }

    }

    async changeDept(year) {
        await axios.post(uAPIlocal + '/api/v1/patient_dept', { _y: year },
            { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
            .then((res) => {
                let { results } = res.data
                console.log("INI DEPT", results)
                let datas = []
                if (results.length > 0) {
                    results.forEach(element => {
                        if (element.Patient_Dept) {
                            datas.push({
                                value: element.Patient_Dept,
                                label: element.Patient_Dept
                            })
                        }
                    });
                }
                this.setState({ optDept: datas, chooseDept: datas[0] });
            })
            .catch((err) => {
                console.log('err', err)
            })

    }

    async getDemografiGender() {
        if (this.state.chooseDept && this.state.year1) {
            this.setState({ jenisKelamin: "", loading: true })
            await axios.post(uAPIlocal + '/api/v1/departement/jenisKelamin', { dept: this.state.chooseDept?.value, _y: this.state.year1 },
                { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
                .then((res) => {
                    let { grafik } = res.data;
                    console.log('grafik1', grafik.jumlah)

                    let resultTotalPeserta = grafik.jumlah.reduce((a, b) => {
                        return a + b;
                    });

                    let m_label = this.props.t('lakiLaki');
                    let f_label = this.props.t('perempuan');

                    this.setState({
                        totalPeserta: resultTotalPeserta,
                        grafik: {
                            labels: [m_label, f_label],
                            datasets: [
                                {
                                    label: 'Jumlah',
                                    data: grafik.jumlah,
                                    hoverBorderColor: "rgba(0,192,185,255)",
                                    hoverBorderWidth: 8,
                                    backgroundColor: ["#63B6C0", "#C5615F"],
                                    borderWidth: 0,
                                    borderSkipped: false,
                                },
                            ],
                        }, loading: false
                    })

                })
                .catch((err) => {
                    console.log('err', err)
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message);
                    } else {
                        // handle error
                    }
                })
        }

    }


    async getDemografiUmur() {
        if (this.state.yearLoaded && this.state.year1) {
            this.setState({ loading2: true })
            await axios.post(uAPIlocal + '/api/v1/departement/umur', { dept: this.state.chooseDept?.value, _y: this.state.year1 },
                { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
                .then((res) => {
                    let { grafik } = res.data;
                    // console.log('grafik', grafik)
                    this.setState({
                        grafik2: {
                            labels: grafik.kesimpulan,
                            datasets: [
                                {
                                    label: 'Jumlah',
                                    data: grafik.jumlah,
                                    hoverBorderColor: "rgba(0,192,185,255)",
                                    hoverBorderWidth: 8,
                                    backgroundColor: ['#63B6C0', '#C5615F', '#30638A', '#D0AD6E'],
                                    borderWidth: 0,
                                    borderSkipped: false,
                                },
                            ],
                        }, loading2: false
                    })

                })
                .catch((err) => {
                    console.log('err', err)
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message);
                    } else {
                        // handle error
                    }
                })
        }
    }


    async getNormalAbnormal1() {
        this.setState({ NormalAbnormal_ID: [], loading3: true })
        await axios.post(uAPIlocal + '/api/v1/departement/getidnormalabnormal', { dept: this.state.chooseDept?.value, _y: this.state.year1 },
            { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
            .then((res) => {
                //let { grafik } = res.data;
                console.log('_NormalAbnormal_ID', res.data.results)
                res.data.results.map((item, index) => {
                    this.setState({ NormalAbnormal_ID: [...this.state.NormalAbnormal_ID, item] })
                })

                this.getNormalAbnormal2();
                this.getTopDepartment();
                this.getTemuanDepartmentNew();
                // this.testKosong();

            })
            .catch((err) => {
                console.log('err', err)
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message);
                } else {
                    // handle error
                }
            })

    }

    async getNormalAbnormal2() {
        this.setState({ _totalAbnormal: [], _totalNormal: [] })
        this.state.NormalAbnormal_ID.map((item, index) => {
            axios.post(uAPIlocal + '/api/v1/departement/normalAbnormal2', { dept: this.state.chooseDept?.value, _y: this.state.year1, _id: item.Patient_id },
                { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
                .then((res) => {
                    // console.log('hasil temuan ' + item, res.data.results[0])

                    if (res.data.results[0].temuan > 0) {
                        this.setState({ _totalAbnormal: [...this.state._totalAbnormal, res.data.results[0].temuan] })
                    } else {
                        this.setState({ _totalNormal: [...this.state._totalNormal, res.data.results[0].temuan] })
                    }

                    if (this.state.NormalAbnormal_ID.length == (index + 1)) {
                        this.setState({
                            grafik3: {
                                labels: ["Normal", "Abnormal"],
                                datasets: [
                                    {
                                        label: 'Jumlah',
                                        data: [this.state._totalNormal.length, this.state._totalAbnormal.length],
                                        hoverBorderColor: "rgba(0,192,185,255)",
                                        hoverBorderWidth: 8,
                                        backgroundColor: ["#63B6C0", "#C5615F"],
                                        borderWidth: 0,
                                        borderSkipped: false,
                                    },
                                ],
                            }, loading3: false
                        })
                    }

                })
                .catch((err) => {
                    console.log('err', err)
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message);
                    } else {
                        // handle error
                    }
                })


        })

    }

    async getTopDepartment() {
        // alert(this.state.optTest1);
        this.setState({ PesertaNormalAbnormal: [] })
        this.state.NormalAbnormal_ID.map((item, index) => {
            axios.post(uAPIlocal + '/api/v1/departement/normalAbnormal', { dept: this.state.chooseDept?.value, _y: this.state.year1, _id: item.Patient_id, test_group: this.state.optTest1 },
                { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
                .then((res) => {
                    let { grafik } = res.data;
                    console.log('hasil temuan XXX' + index, res.data.results[0])

                    let status = "";
                    if (res.data.results[0].temuan > 0) {
                        status = "A";
                    } else {
                        status = "N";
                    }

                    // console.log('hasil temuan STATUS -> ' + item.Patient_name + " -- " + res.data.results[0].temuan, status)

                    if (this.state.optTest1 == "ALL") {

                        this.setState({ PesertaNormalAbnormal: [...this.state.PesertaNormalAbnormal, { "Patient_ID": item.Patient_ID, "Patient_name": item.Patient_name, "Test_Name": res.data.results[0].Test_Name, "status": status }] })

                        if (this.state.NormalAbnormal_ID.length == (index + 1)) {
                            this.setState({
                                loading4: false
                            })
                        }


                    } else {
                        // alert(res.data.results[0].Test_Name)
                        if (res.data.results[0].Test_Name != null) {

                            if (res.data.results[0].Test_Group == this.state.optTest1) {

                                this.setState({ PesertaNormalAbnormal: [...this.state.PesertaNormalAbnormal, { "Patient_ID": item.Patient_ID, "Patient_name": item.Patient_name, "Test_Name": res.data.results[0].Test_Name, "status": status }] })

                                if (this.state.NormalAbnormal_ID.length == (index + 1)) {
                                    this.setState({
                                        loading4: false
                                    })
                                }

                            }
                        }



                    }



                })
                .catch((err) => {
                    console.log('err', err)
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message);
                    } else {
                        // handle error
                    }
                })

        })
    }


    async getTemuanDepartmentNew() {
        this.setState({ arrayConcDept: [], arrayConcDeptID: [] })
        this.state.NormalAbnormal_ID.map((item, index) => {
            axios.post(uAPIlocal + '/api/v1/departement/normalAbnormal', { dept: this.state.chooseDept?.value, _y: this.state.year1, _id: item.Patient_id, test_group: "ALL" },
                { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
                .then((res) => {
                    let { grafik } = res.data;
                    console.log('hasil temuan ZZZ' + index, res.data.results[0].Conc_Name)
                    // console.log('hasil temuan ZZZ2' + index, item)

                    let status = "";
                    if (res.data.results[0].temuan > 0) {
                        this.setState({ arrayConcDept: [...this.state.arrayConcDept, res.data.results[0].Conc_Name] })
                        // this.setState({ arrayConcDeptID: [...this.state.arrayConcDeptID, res.data.results[0].Conc_ID] })
                    } else {
                        status = "N";
                    }

                    // if (res.data.results[0].Conc_ID == null) {
                    //     console.log("this null1", this.state.chooseDept?.value + " - " + item.Patient_ID)
                    //     // alert(item.Patient_ID)
                    // }

                    if (this.state.NormalAbnormal_ID.length == (index + 1)) {
                        this.getConcDept();
                    }


                })
                .catch((err) => {
                    console.log('err', err)
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message);
                    } else {
                        // handle error
                    }
                })

        })
    }

    // async testKosong() {
    //     ["B130502248",
    //         "B090800224",
    //         "B211201407",
    //         "C090500545",
    //         "B090800240",
    //         "B170901141",
    //         "B190702287",
    //         "C080301124",
    //         "B100701073",
    //         "B090800167",
    //         "B211201453",
    //         "C151001444",
    //         "C180500706",
    //         "C180300766",
    //         "B090800323",
    //         "B090800414",
    //         "C181200108",
    //         "C191200397",
    //         "C180600194",
    //         "C190800409",
    //         "C190200640",
    //         "C190300173",
    //         "C190800402",
    //         "C181200388",
    //         "C190800414",
    //         "C190200507",
    //         "M210400087",
    //         "C190800551",
    //         "C191200398",
    //         "C190500523",
    //         "C190800403",
    //         "C191100927",
    //         "C190200641",
    //         "C190800413",
    //         "C190800408",
    //         "C190900213",
    //         "C171000319",
    //         "C181200322",
    //         "C200100149",
    //         "C131200874"].map(item => {
    //             // alert(item)
    //             axios.get(uAPIlocal + '/api/v1/departement/normalAbnormal', {
    //                 cancelToken: source.token,
    //                 params: {
    //                     dept: this.state.chooseDept?.value,
    //                     Company_ID: this.state.id_company,
    //                     _y: this.state.year1,
    //                     _id: item,
    //                     test_group: "ALL"
    //                 }
    //             })
    //                 .then((res) => {
    //                     let { grafik } = res.data;
    //                     // console.log('hasil temuan WWW', res.data.results[0])
    //                     if (res.data.results[0].Conc_ID == null) {
    //                         // console.log("this null2", item)
    //                     }

    //                 })
    //                 .catch((err) => {
    //                     console.log('err', err)
    //                     if (axios.isCancel(err)) {
    //                         console.log('Request canceled', err.message);
    //                     } else {
    //                         // handle error
    //                     }
    //                 })

    //         })
    // }

    async getConcDept() {
        console.log("arrayConcDept", this.state.arrayConcDept)
        this.setState({ loading5: true });
        var uniqs = this.state.arrayConcDept.reduce((acc, val) => {
            acc[val] = acc[val] === undefined ? 1 : acc[val] += 1;
            return acc;
        }, {});
        // console.log("kingkong", uniqs)

        var keys = Object.keys(uniqs)
        // console.log("kingkong", keys)
        var values = Object.values(uniqs)
        // console.log("kingkong", values)

        // var uniqsID = this.state.arrayConcDeptID.reduce((acc, val) => {
        //     acc[val] = acc[val] === undefined ? 1 : acc[val] += 1;
        //     return acc;
        // }, {});

        // var keysID = Object.keys(uniqsID)
        // console.log("kingkong", keysID)

        // console.log("JALAN SAMPAI SINI")


        this.setState({
            // top10TemuanID: keysID,
            grafik4: {
                labels: keys,
                datasets: [
                    {
                        label: 'Jumlah',
                        data: values,
                        hoverBorderColor: "rgba(0,192,185,255)",
                        hoverBorderWidth: 8,
                        backgroundColor: ['#63B6C0', '#30638A', '#C5615F', '#D0AD6E', '#6792AC', '#8E8E8E', '#C2CD9C', '#5FA36F', '#A87255', '#71292A'],
                        // borderColor: '#165BAA',
                        borderWidth: 0,
                        borderSkipped: false,
                    },
                ]
            },
            options: {
                maintainAspectRatio: false,
                cornerRadius: 10,
                plugins: {
                    datalabels: {
                        display: false,
                        color: "black",
                        formatter: Math.round,
                        anchor: "end",
                        offset: -20,
                        align: "start"
                    }
                },
                legend: {
                    display: false,
                    position: "right",
                    align: "start",
                    fontSize: 22,
                    maxSize: {
                        "height": 800
                    },
                    labels: {
                        generateLabels: (chart) => {
                            const datasets = chart.data.datasets;
                            return datasets[0].data.map((data, i) => ({
                                text: `${chart.data.labels[i]} :${data}`,
                                fillStyle: datasets[0].backgroundColor[i],
                                index: i
                            }))
                        },
                        usePointStyle: true,
                    },
                },

                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            // display: false,
                        },
                        gridLines: {
                            borderDash: [12, 8],
                            color: "#D3D3D3",
                            drawBorder: false,
                        }

                    }],
                    xAxes: [{
                        ticks: {
                            fontSize: 10,
                            minRotation: 90,
                            display: false,
                        },
                        gridLines: {
                            display: false
                        },
                        barThickness: 32,

                    }]

                },

            },
            loading5: false
        })

    }


    // async getTemuan() {
    //     this.setState({ loading5: true })
    //     axios.get(uAPIlocal + '/api/v1/departement/temuan', {
    //         cancelToken: source.token,
    //         // signal: controller.signal,
    //         params: {
    //             dept: this.state.chooseDept?.value,
    //             Company_ID: this.state.id_company,
    //             _y: this.state.year1,
    //         }
    //     })
    //         .then((res) => {
    //             let { grafik } = res.data;
    //             // console.log('grafik4', grafik)

    //             this.setState({
    //                 top10TemuanID: grafik.id,
    //                 grafik4: {
    //                     labels: grafik.kesimpulan,
    //                     datasets: [
    //                         {
    //                             label: 'Jumlah',
    //                             data: grafik.jumlah,
    //                             hoverBorderColor: "rgba(0,192,185,255)",
    //                             hoverBorderWidth: 8,
    //                             backgroundColor: ['#63B6C0', '#30638A', '#C5615F', '#D0AD6E', '#6792AC', '#8E8E8E', '#C2CD9C', '#5FA36F', '#A87255', '#71292A'],
    //                             // borderColor: '#165BAA',
    //                             borderWidth: 0,
    //                             borderSkipped: false,
    //                         },
    //                     ]
    //                 },
    //                 options: {
    //                     maintainAspectRatio: false,
    //                     cornerRadius: 10,
    //                     plugins: {
    //                         datalabels: {
    //                             display: false,
    //                             color: "black",
    //                             formatter: Math.round,
    //                             anchor: "end",
    //                             offset: -20,
    //                             align: "start"
    //                         }
    //                     },
    //                     legend: {
    //                         display: false,
    //                         position: "right",
    //                         align: "start",
    //                         fontSize: 22,
    //                         maxSize: {
    //                             "height": 800
    //                         },
    //                         labels: {
    //                             generateLabels: (chart) => {
    //                                 const datasets = chart.data.datasets;
    //                                 return datasets[0].data.map((data, i) => ({
    //                                     text: `${chart.data.labels[i]} :${data}`,
    //                                     fillStyle: datasets[0].backgroundColor[i],
    //                                     index: i
    //                                 }))
    //                             },
    //                             usePointStyle: true,
    //                         },
    //                     },

    //                     scales: {
    //                         yAxes: [{
    //                             ticks: {
    //                                 beginAtZero: true,
    //                                 // display: false,
    //                             },
    //                             gridLines: {
    //                                 borderDash: [12, 8],
    //                                 color: "#D3D3D3",
    //                                 drawBorder: false,
    //                             }

    //                         }],
    //                         xAxes: [{
    //                             ticks: {
    //                                 fontSize: 10,
    //                                 minRotation: 90,
    //                                 display: false,
    //                             },
    //                             gridLines: {
    //                                 display: false
    //                             },
    //                             barThickness: 32,

    //                         }]




    //                     },

    //                 },
    //                 loading5: false
    //             })


    //         })
    //         .catch((err) => {
    //             console.log('err', err)
    //             if (axios.isCancel(err)) {
    //                 console.log('Request canceled', err.message);
    //             } else {
    //                 // handle error
    //             }
    //         })
    // }

    render() {

        if (this.state.redirctTo) {
            Cookies.remove("mcu-login", { path: '/', domain: '.biomedika.co.id' })
        } else { }

        if (this.state.redirect === true) {
            return (<Redirect to={{
                pathname: this.state.pathname,
                state: {
                    id: this.state.idSelected,
                    opt: this.state.optSelected,
                    prevPage: this.state.prevPage,
                    prevState: this.state.prevState,
                    goTo: "",
                    year_selected: this.state.year1,
                }
            }} />)
        }


        const { tableTop10Individual } = this.state;

        const paginationOpt = {
            custom: true,
            paginationSize: this.state.PesertaNormalAbnormal.length,
            pageStartIndex: 1,
            firstPageText: "First",
            prePageText: "Back",
            nextPageText: "Next",
            lastPageText: "Last",
            nextPageTitle: "First page",
            prePageTitle: "Pre page",
            firstPageTitle: "Next page",
            lastPageTitle: "Last page",
            showTotal: true,
            sizePerPage: this.state.PesertaNormalAbnormal.length,
            totalSize: this.state.PesertaNormalAbnormal.length,
        };

        const imgFormatter = (cell, row) => {
            return (
                <div className="rounded-corners-users"></div>
            );
        }
        const arrowClick = (props) => {
            // console.log('arrowClick', props);
            // this.setState({ redirect: true, _state: props })
        }
        const arrowFormatter = (cell, row, rowIndex, formatExtraData) => {

            if (rowIndex % 2 == 0) {
                return (
                    <button type="submit" onClick={() => arrowClick(row)} className="btn-cirlce2"></button>
                );
            } else {
                return (
                    <button type="submit" onClick={() => arrowClick(row)} className="btn-cirlce1"></button>
                );
            }
        }

        const columns = [
            {
                dataField: "Patient_ID",
                text: "",
                formatter: imgFormatter,
                style: {
                    // height: '132px',
                    textAlign: 'center',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    minWidth: '2%',
                    // display: this.state.hideIconUser == true ? 'none' : 'null'
                },
                headerAttrs: {
                    // hidden: this.state.hideIconUser == true ? true : false
                },
                headerStyle: (colum, colIndex) => {
                    return { borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#000" };
                },
            },
            {
                dataField: "Patient_name",
                text: this.props.t('nama'),
                sort: false,
                style: {
                    // height: '132px',
                    textAlign: 'center',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    maxWidth: '60px',
                },
                headerAttrs: {
                    hidden: false
                },
                headerStyle: (colum, colIndex) => {
                    return { maxWidth: '60px', borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#000" };
                },
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (<div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}><p>{row.Patient_name}</p></div>);
                },
            },
            {
                dataField: "Test_Name",
                text: this.props.t('pemeriksaan'),
                sort: false,
                style: {
                    // height: '132px',
                    textAlign: 'center',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    maxWidth: '60px',
                },
                headerAttrs: {
                    hidden: false
                },
                headerStyle: (colum, colIndex) => {
                    return { maxWidth: '60px', borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#000" };
                },
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (<div><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{row.Test_Name}</p></div>);
                },
            },
            {
                dataField: "status",
                text: this.props.t('status'),
                sort: false,
                style: {
                    // height: '132px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    margin: '2px auto',
                    fontSize: '12px',
                    maxWidth: '30px',
                },
                headerAttrs: {
                    hidden: false
                },
                headerStyle: (colum, colIndex) => {
                    return { maxWidth: '30px', borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#000" };
                },
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (<div><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{row.status}</p></div>);
                },
            },
            // {
            //     dataField: "Patient_id",
            //     formatter: arrowFormatter,
            //     text: "",
            //     style: {
            //         height: '60px', textAlign: 'center',
            //         fontWeight: 'normal',
            //         margin: '2px auto',

            //     },
            //     sort: false,
            //     headerAttrs: {
            //         hidden: false
            //     },
            //     headerStyle: (colum, colIndex) => {
            //         return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', backgroundColor: "#EFEFEF", color: "#000" };
            //     },
            // },
        ];

        const tableRowEvents = {
            onClick: (e, row, rowIndex) => {
                // console.log(e);
                switch (e.detail) {
                    case 1:
                        console.log("click");
                        this.redirectPeserta(row.Patient_ID);
                        break;
                    case 2:
                        this.handleShowModalDetail();
                        console.log("double click");
                        break;
                    case 3:
                        console.log("triple click");
                        break;
                }
            },
            onMouseEnter: (e, row, rowIndex) => {
                console.log(`enter on row with index: ${rowIndex}`);
            },
        };

        const indication = () => {
            return (
                <div style={{ textAlign: 'center' }}>
                    Data Not Found!
                </div>
            )
        }

        const contentTableTop10Individual = ({ paginationProps, paginationTableProps }) => (
            <div style={{ marginLeft: "20px", marginRight: "20px", marginTop: '0px' }}>

                <ToolkitProvider keyField="Patient_ID" columns={columns} data={this.state.PesertaNormalAbnormal} search>
                    {(toolkitprops) => (
                        <div>
                            <BootstrapTable
                                striped
                                bordered={false}
                                hover
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                rowEvents={tableRowEvents}
                                noDataIndication={indication}
                                selectRow={selectRow}
                            />
                        </div>
                    )}
                </ToolkitProvider>

            </div>
        );


        const selectRow = {
            mode: 'radio',
            // style: { backgroundColor: '#c8e6c9', borderWidth: '2px', borderColor:'#0f7051' },
            classes: 'selection-row',
            clickToSelect: true,
            selected: this.state.selectedRow != null ? this.state.selectedRow : null,
            hideSelectColumn: true,
        };


        const list_ = [
            { key: "1", value: "KIMIA" },
            { key: "2", value: "LEUKOSIT" },
            { key: "3", value: "DARAH" },
            { key: "4", value: "LEUKIMIA" },
            { key: "5", value: "TROMBOSIT" }
        ];

        const optTest = this.state.optTest;


        return (
            <>

                <Row style={{ marginBottom: '-20px' }}>
                    <Col xl={8} md={12} sm={12} className="departColumn1">

                        <div className="dashboard1_new">

                            <Row style={{ paddingTop: "0px", marginBottom: "10px" }}>

                                <Col md={6}>

                                    <div className="form-group" style={{ marginTop: "10px", marginLeft: '50px' }}>
                                        <Select
                                            className='title'
                                            value={this.state.chooseDept}
                                            isSearchable={true}
                                            name="dept"
                                            options={this.state.optDept}
                                            placeholder="Pilih Departemen"
                                            onChange={this.changeSelect}

                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? 'grey' : '#7E7E7E',
                                                    color: "#000",
                                                    backgroundColor: 'rgba(231,230,227,255)',
                                                    borderRadius: '10px',
                                                    width: "140px",
                                                    fontSize: '12px',
                                                }),
                                                option: provided => ({
                                                    ...provided,
                                                    fontSize: '12px',
                                                    zIndex: 99,
                                                }),
                                                dropdownIndicator: styles => ({
                                                    ...styles,
                                                    color: '#000',
                                                }),
                                                indicatorsContainer: (base) => ({
                                                    ...base,
                                                    minHeight: 1,
                                                }),
                                                indicatorSeparator: () => null,
                                            }}
                                        />
                                    </div>

                                </Col>

                                <Col md={6}>
                                    <div className="form-group">
                                        <DropdownButton
                                            align="start"
                                            id="dropdown-menu-align-end"
                                            onSelect={this.setYearAll}
                                            style={{ float: "right", marginBottom: "10px", marginRight: '20px', marginTop: '10px' }}
                                            title={
                                                <span>
                                                    {this.state.year1} <img alt='dropdownicon1' src={dropdownIcon} />
                                                </span>
                                            }
                                        >
                                            {this.state.optYear.map((item, index) => {
                                                return (
                                                    <Dropdown.Item key={index} className={item.value == this.state.year1 ? 'dropdown-item-checked' : null} active={item.value == this.state.year1 ? true : false} eventKey={item.key}>
                                                        {item.value}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </DropdownButton>
                                    </div>
                                </Col>
                            </Row>


                            <div className="grid-container">
                                <div className="grid-item">
                                    {
                                        this.state.loading ? (
                                            <div className="text-center pd-90" >
                                                <Loader type="Bars" color="#0f473e" height={60} width={100} />
                                                Loading
                                            </div>
                                        ) : (
                                            this.state.grafik ? (
                                                <PieChart data={this.state.grafik} totalPeserta={this.state.totalPeserta} title={this.props.t('jenisKelamin')} typeText={"jenisKelamin"} />
                                            ) : <div>
                                                <h1 className='dashboard27'>Jenis Kelamin</h1>
                                                <h5 className='dashboard27'>Data not found</h5>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="grid-item">
                                    {
                                        this.state.loading2 ? (
                                            <div className="text-center pd-90" >
                                                <Loader type="Bars" color="#0f473e" height={60} width={100} />
                                                Loading
                                            </div>
                                        ) : (
                                            this.state.grafik2 ? (
                                                <PieChart data={this.state.grafik2} title={this.props.t('usia')} typeText={"usia"} />
                                            ) : <div>
                                                <h1 className='dashboard27'>Umur</h1>
                                                <h5 className='dashboard27'>Data not found</h5>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="grid-item">
                                    {
                                        this.state.loading3 ? (
                                            <div className="text-center pd-90" >
                                                <Loader type="Bars" color="#0f473e" height={60} width={100} />
                                                Loading
                                            </div>
                                        ) : (
                                            this.state.grafik3 ? (
                                                <PieChart data={this.state.grafik3} title={this.props.t('temuan')} typeText={"temuan"} />
                                            ) : <div>
                                                <h1 className='dashboard27'>Normal / Abnormal</h1>
                                                <h5 className='dashboard27'>Data not found</h5>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>


                            <div style={{ display: 'flex' }}>
                                <div className="box_1"></div>
                                <div className="box"></div>
                                <div className="box_2"></div>
                            </div>




                            <Row style={{ paddingTop: "0px", marginTop: '-40px' }}>

                                <Col md={12}>
                                    <h1 className='dashboard13-new'>{this.props.t('temuanDepartemen')} : {this.state.chooseDept?.value}</h1>
                                </Col>

                            </Row>


                            {
                                this.state.loading5 ? (
                                    <div className="text-center pd-90" >
                                        <Loader type="Bars" color="#0f473e" height={60} width={100} />
                                        Loading
                                    </div>
                                ) : (
                                    this.state.grafik4 ? (
                                        <Barchart type='bar' id={this.state.top10TemuanID} year={this.state.year1} dept={this.state.chooseDept?.value} data={this.state.grafik4} options={this.state.options} />
                                    ) : <div>
                                        <h5 className='dashboard27'>Data not found</h5>
                                    </div>
                                )
                            }

                            {/* <Row style={{ marginTop: '-40px' }}>
                                <Col xl={12} md={12} sm={12} className="departColumn3" >

                                    <div className="dashboard1Child_new">

                                        <Row style={{ paddingTop: "0px" }}>

                                            <Col md={12}>
                                                <h1 className='dashboard13-new'>Temuan Departemen : {this.state.chooseDept?.value}</h1>
                                            </Col>

                                        </Row>


                                        {
                                            this.state.loading5 ? (
                                                <div className="text-center pd-90" >
                                                    <Loader type="Bars" color="#0f473e" height={60} width={100} />
                                                    Loading
                                                </div>
                                            ) : (
                                                this.state.grafik4 ? (
                                                    <Barchart type='bar' id={this.state.top10TemuanID} year={this.state.year1} dept={this.state.chooseDept?.value} data={this.state.grafik4} options={this.state.options} />
                                                ) : <div>
                                                    <h5 className='dashboard27'>Data not found</h5>
                                                </div>
                                            )
                                        }


                                    </div>


                                </Col>


                            </Row> */}



                        </div>


                    </Col>

                    <Col className="departColumn2" xl={4} md={12} sm={12}>
                        <div className="dashboard1_new">

                            <Row style={{ paddingTop: "0px" }}>
                                <Col md={12}>
                                    <div style={{ display: "flex", flexDirection: "column", flexWrap: 'wrap' }}>
                                        <h1 className="titleText">{(this.props.t('pesertaDepartemen').split(" "))[0]}</h1>
                                        <h1 className="titleText" style={{ marginTop: '0px' }}>{(this.props.t('pesertaDepartemen').split(" "))[1]}:</h1>
                                    </div>
                                    <span className="titleSpan">{this.state.chooseDept?.value}</span>
                                </Col>

                                <Col md={12}>

                                    <div className="form-group">
                                        <DropdownButton
                                            align="start"
                                            id="dropdown-menu-align-end"
                                            onSelect={this.setTest}
                                            style={{ float: "right", marginBottom: "10px", marginRight: '20px', marginTop: '-40px' }}
                                            title={
                                                <span>
                                                    {/* {"Pilih Pemeriksaan".substring(0, 5)}{"pilih" > 5 ? "..." : null} <img alt='img4' src={dropdownIcon} /> */}
                                                    {/* {this.state.optTest1 ? this.state.optTest1 : "Pilih"} <img alt='img4' src={dropdownIcon} /> */}

                                                    {
                                                        this.state.optTest1 ? (
                                                            this.state.optTest1.length > 5 ? (
                                                                this.state.optTest1.substring(0, 5) + '... '
                                                            ) : this.state.optTest1
                                                        ) : (this.props.t('pilih') + " ")
                                                    }
                                                    <img alt='img4' src={dropdownIcon} />
                                                </span>
                                            }
                                        >
                                            <Dropdown.Item key={"_0"} className={this.state.optTest1 == "" ? 'dropdown-item-checked' : null} active={this.state.optTest1 == "" ? true : false} eventKey={"ALL"}>
                                                {"ALL"}
                                            </Dropdown.Item>

                                            {optTest.map((item, index) => {
                                                // console.log("sakuraSchool", item.value)
                                                return (
                                                    <Dropdown.Item key={index} className={item.value == this.state.optTest1 ? 'dropdown-item-checked' : null} active={item.value == this.state.optTest1 ? true : false} eventKey={item.key}>
                                                        {item.value}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </DropdownButton>
                                    </div>
                                </Col>
                            </Row>


                            <div className="departmentSubColumn2" style={{ overflowY: 'scroll', cursor: 'pointer' }}>

                                {
                                    this.state.loading4 ? (
                                        <div className="text-center pd-90" >
                                            <Loader type="Bars" color="#0f473e" height={60} width={100} />
                                            Loading
                                        </div>
                                    ) : (
                                        <PaginationProvider pagination={paginationFactory(paginationOpt)}>
                                            {contentTableTop10Individual}
                                        </PaginationProvider>

                                    )
                                }


                                {/* <Col md={12} style={{ marginBottom: '20px' }}>
                                    <div className="d-flex justify-content-center" style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', backgroundColor: 'white', borderRadius: '20px', textAlign: "justify", paddingBottom: '0px', paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
                                        <Row>

                                            <Col md={12}>
                                                <p style={{ fontSize: '12px', fontFamily: "Montserrat", marginBottom: '0px' }}><b>Notes:</b></p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ fontSize: '12px', fontFamily: "Montserrat" }}>Top 10 Peserta: Peserta dengan temuan tertinggi sesuai dengan pemeriksaan</p>
                                            </Col>

                                        </Row>
                                    </div>
                                </Col> */}


                            </div>



                        </div>

                    </Col>
                </Row>

            </>
        )

    }
}
export default withRouter(withTranslation()(Department));

