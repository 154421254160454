import React, { Component } from "react";
import './body.scss';
import {
  Container,
  Row,
  Col,
  // Card,
  Button,
  ButtonGroup,
  // Form,
  // FormControl,
  // Breadcrumb,
  // Carousel,
  // List,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
// import ListGroup from "react-bootstrap/ListGroup";
import InputGroup from "react-bootstrap/InputGroup";
// import Modal from "react-bootstrap/Modal";
// import Collapse from "react-bootstrap/Collapse";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
// import filterFactory, {
//   dateFilter,
//   Comparator,
// } from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
  // CSVExport,
} from "react-bootstrap-table2-toolkit";
// import cellEditFactory from "react-bootstrap-table2-editor";
import Navbar from './Navbar';
import maskGroup from "../../assets/maskGroup.png";
import dropdownIcon from "../../assets/br_down.png";
import axios from 'axios';
import { uAPIlocal } from '../lib/config';
import { withRouter, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import CryptoJS from "crypto-js";
let source = axios.CancelToken.source();
axios.defaults.withCredentials = true;
axios.defaults.headers.post["X-CSRF-Token"] = Cookies.get('mcu-csrf');

class People extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.state = {
      redirctTo: false,
      lvl: localStorage.getItem("lvl"),
      tableInbox: true,
      datas: [],
      _totalPage: 0,
      showModal: false,
      dataDetail: [],
      dataDetailTemuan: [],
      idPatient: null,
      selectedRows: null,
      isSelected: false,
      detailPatient: [],

      // nextpage
      redirect: false,
      pathname: "",
      idSelected: "",
      optSelected: "",
      prevState: "",
      prevPage: "",
      goTo: "",
      action: "false",


      // props
      _id: this.props.location.state ? this.props.location.state.id : null,
      _conc_id: this.props.location.state ? this.props.location.state.conc_id : null,
      _conc_name: this.props.location.state ? this.props.location.state.conc_name : null,
      _opt: this.props.location.state ? this.props.location.state.opt : null,
      _prevPage: this.props.location.state ? this.props.location.state.prevPage : null,
      _prevState: this.props.location.state ? this.props.location.state.prevState : null,
      _year_selected: this.props.location.state ? this.props.location.state.year_selected : null,
      selectYear: [],
      selectYearTable: [],
      _yAll: "",

      searchValue: this.props.location.state ? this.props.location.state.id : null,
      searchWord: '',

      peserta_selected: [],
      filter_peserta: false,
      temuanClicked: false,
      redirectDetail: [],
      isSearch: false,
      dataSearch: [],
      getVal: null,
      pageStartIndex: 1,

      clr_child: true,
    };
    this.getPatient = this.getPatient.bind(this);
    this.handleRowsClick = this.handleRowsClick.bind(this)
    this.fetchDataDetail = this.fetchDataDetail.bind(this)
    // this.getDataDetailPatient = this.getDataDetailPatient.bind(this)
    // this.modalRef = this.modalRef.bind(this)
    this.getSelectYear = this.getSelectYear.bind(this);
    this.setTemuanState = this.setTemuanState.bind(this);
    this.temuanClick = this.temuanClick.bind(this);
    this.temuanClickFetch = this.temuanClickFetch.bind(this);
    this.handleModalRedirect = this.handleModalRedirect.bind(this);
    this.redValueFormatter = this.redValueFormatter.bind(this);
    this.autoLogout = this.autoLogout.bind(this);
    source = axios.CancelToken.source()

  }



  async componentDidMount() {
    this.props.i18n.changeLanguage(localStorage.getItem("lang"));
    this.getSelectYearTable();
    localStorage.setItem("path", "DASBOR");
    // window.addEventListener("resize", this.resizeScreen());
    const formData = new FormData();
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      withCredentials: true
    };
    formData.append('name', localStorage.getItem("name"));
    formData.append('message', "Akses Peserta");

    await axios.post(uAPIlocal + '/api/v1/log', formData, config)
      .then(response => {
        console.log(response)
        if (response.data.status === 406) {
          console.log(response)

        } else {
          console.log(response)
        }

      })
      .catch(function (error) {
        console.log(error);
      });


    // this.getSelectYear();
    // this.getSelectYearTable();

    // console.log("detail_login", this.state.detail_login);
    // console.log(this.modalRef, "test refff")
    // console.log("detail id click", this.state.idPatient)
    // this.getDataDetailPatient();
    // document.addEventListener("mousedown", this.handleClose)
    // this.handleClose();
  }
  componentDidUpdate(prevProps, prevState) {
    console.log(prevState, "prev state")
    if (prevState.selectedRows !== this.state.selectedRows) {
      // document.('custom-border')
      // console.log(this.state.selectedRows, "selectedRows")
      // console.log(prevState, "prev")
      var element = document.getElementById(`border-custom-${this.state.selectedRows}`)
      if (element) {
        element.classList.add('row-custom-selected')
        var element2 = document.getElementById(`border-custom-${prevState.selectedRows}`)
        if (element2) {
          element2.classList.remove('row-custom-selected')
          // console.log(this.state.showModal, "showModal")
        }


      }

      console.log(element, 'element')

    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClose)
    if (source) {
      source.cancel("Landing Component got unmounted");
    }
  }


  async getSelectYear() {
    await axios.post(uAPIlocal + '/api/v1/getselectyear',
      { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
      .then(function (response) {
        return response.data;
      })
      .then(response => {
        const res = response.results;
        console.log("year", res);

        if (res.length === 0) {
          this.getSelectYear();
        } else {
          res.map((item, index) => {
            this.setState({ selectYear: [...this.state.selectYear, item.mcu_year] })
          })

          console.log("yearX", this.state.selectYear);

          if (this.state._year_selected == null) {
            this.setState({
              _year_selected: this.state.selectYear[0],
            })
          }

          if (this.state._conc_id != null) {
            console.log("kondisi 3");
            this.getGrafikDash()
          } else if (this.state._id != null) {
            console.log("kondisi 2")

            if (this.state._prevPage == "top10" &&
              this.state._prevState == "top10_peserta") {
              this.temuanClickFetch(this.state._id);
              this.getPatient();
            }

            if (this.state._prevPage == "department" &&
              this.state._prevState == "department_peserta") {
              this.handleModalRedirect(this.state._id);
              this.getPatient();
            }
          } else {
            this.getPatient();
          }



        }




      })
      .catch(function (error) {
        console.log(error);
      });

  }

  async getSelectYearTable() {
    this.getSelectYear();
    await axios.post(uAPIlocal + '/api/v1/getselectyear',
      { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
      .then(function (response) {
        return response.data;
      })
      .then(response => {
        const res = response.results;
        console.log("year", res);

        if (res.length === 0) {
          this.getSelectYearTable();
        } else {
          res.map((item, index) => {
            this.setState({ selectYearTable: [...this.state.selectYearTable, item.mcu_year] })
          })

          console.log("yearX", this.state.selectYearTable);

          if (this.state._year_selected == null) {
            this.setState({
              _year_selected: this.state.selectYearTable[0],
            })
          }

          this.setState({
            _yAll: this.state._year_selected,
          })

          if (this.state._conc_id != null) {
            console.log("kondisi 3");
            this.getGrafikDash()
          } else if (this.state._id != null) {
            console.log("kondisi 2")

            if (this.state._prevPage == "top10" &&
              this.state._prevState == "top10_peserta") {
              this.temuanClickFetch(this.state._id);
              this.getPatient();
            }

            if (this.state._prevPage == "department" &&
              this.state._prevState == "department_peserta") {
              this.handleModalRedirect(this.state._id);
              this.getPatient();
            }
          } else {
            this.getPatient();
          }

          this.getDept();


        }


      })
      .catch((e) => {
        console.log(e);
        // setTimeout(() => {
        //   window.location.reload()
        // }, 2000);
        // this.autoLogout();
      });

  }

  autoLogout = () => {
    this.setState({ redirctTo: true });
  }


  setYearAll = (eventkey, event) => {
    event.persist();
    console.log(eventkey);

    // this.getPatient();
    this.setState({
      _yAll: eventkey,
      _year_selected: eventkey,
    }, () => this.locationReload())

  };

  locationReload() {

    this.setState({
      tableInbox: false,
    })

    this.getPatient();

    setTimeout(() => {
      this.setState({
        tableInbox: true
      })
    }, 1000);

  };


  async getGrafikDash() {
    // alert(this.state._conc_name)
    var conc_name = this.state._conc_name.replace(/\s+$/, '');
    await axios.post(uAPIlocal + '/api/v1/gettop10pemeriksaanpeserta', { _y: this.state._year_selected, conc_id: this.state._conc_id, conc_name: conc_name },
      { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
      .then(function (response) {
        return response.data;
        console.log("grafik_dash", response.data);
      })
      .then(response => {
        let datas = [];
        response.results.map((item, index) => {
          datas.push(item.Patient_id);
        })
        console.log("grafik_dash_untuk_peserta", datas);
        this.setState({ peserta_selected: datas, filter_peserta: true }, () => this.getPatient());

      })
      .catch(function (error) {
        console.log(error);
      });

  }

  fetchDataDetail(_patientId, id_company) {
    axios.post(uAPIlocal + `/api/v1/detail-patient/${_patientId}?year_selected=${this.state._year_selected}`, {},
      { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
      .then(function (response) {
        // console.log(response, "this is response")
        return response.data;
      })
      .then(response => {

        this.setState({
          detailPatient: response.results.patient.map((e) => { return e })
        })

      })
      .catch(function (error) {
        console.log(error);
      });

    setTimeout(() => {
      if (this.state.temuanClicked == false) {
        axios.post(uAPIlocal + `/api/v1/detail-patient/${_patientId}?year_selected=${this.state._year_selected}`, {},
          { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })

          .then(function (response) {
            // console.log(response, "this is response")
            return response.data;
          })
          .then(response => {
            // console.log("mydata", response.results.rows.map((e) => { return e }));
            this.setState({
              dataDetail: response.results.rows.map((e) => { return e })
            })
            // console.log("myData222", response.results.patient.map((e) =>{return e}))
            this.setState({
              detailPatient: response.results.patient.map((e) => { return e })
            })

            this.setState({ showModal: true })
            this.setState({ isSelected: true })
            this.setState({ clr_child: false })
            // this.getPatient();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else { }
    }, 1000);

  }

  async temuanClickFetch(id) {
    this.setState({ temuanClicked: true })
    await axios.post(uAPIlocal + `/api/v1/detail-temuan/${id}?year_selected=${this.state._year_selected}`, {},
      { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
      .then(function (response) {
        return response.data;
      })
      .then(response => {
        console.log('response temuan', response)
        // console.log("mydata", response.results.rows.map((e) => { return e }));
        this.setState({
          dataDetailTemuan: response.results.map((e) => { return e })
        })
        // // console.log("myData222", response.results.patient.map((e) =>{return e}))
        // this.setState({
        //   detailPatient: response.results.patient.map((e) => { return e })
        // })


        // this.fetchDataDetail(id, id_company)

        // this.setState({ showModal: true })
        // this.setState({ isSelected: true })
        // this.setState({ temuanClicked: false })

        if (this.state._prevPage == "top10" && this.state._prevState == "top10_peserta") {
          this.setState({
            redirect: true,
            pathname: "/admin/top10",
            idSelected: this.state._id,
            prevState: "back",
            prevPage: "people",
            goTo: this.state._prevState,
          });
        }

        this.setState({
          searchValue: this.state._id
        })

      })
      .catch(function (error) {
        console.log(error);
      });
    await axios.post(uAPIlocal + `/api/v1/detail-temuan2/${id}`, {},
      { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
      .then(function (response) {
        return response.data;
      })
      .then(response => {

        this.setState({
          detailPatient: response.results.map((e) => { return e })
        })

        this.setState({ showModal: true })
        this.setState({ isSelected: true })
        this.setState({ clr_child: false })
      })
      .catch(function (error) {
        console.log(error);
      });


  }

  handleModalRedirect(id) {
    if (this.state._prevPage == "department" && this.state._prevState == "department_peserta") {
      this.setState({
        redirect: true,
        pathname: "/admin/departemen",
        idSelected: this.state._id,
        optSelected: this.state._opt,
        prevState: "back",
        prevPage: "people",
        goTo: this.state._prevState,
      });
    }

    if (id) {
      this.fetchDataDetail(id)
    }
  }

  handleClose = event => {
    if (this.modalRef && !this.modalRef.textContent) {
      // console.log("test test")
      this.setState({ showModal: false })
      this.setState({ isSelected: false })
      this.setState({ temuanClicked: false })
      var element = document.getElementById(`border-custom-${this.state.selectedRows}`)
      if (element) {
        element.classList.remove(`row-custom-selected`)
      }
    }

  }



  async getPatient() {
    console.log(this.state.searchValue, "search value")
    await axios.post(uAPIlocal + `/api/v1/patient_suspect?year=${this.state._year_selected}`, {},
      { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
      .then(function (response) {
        console.log(response, "this is response")
        return response.data;
      })
      .then(response => {
        console.log("mydata", response.results);
        const _datas = response.results.sort(function (a, b) {
          return a.Patient_name > b.Patient_name;
        });

        const matchedPatient = _datas.filter(i => this.state.peserta_selected.indexOf(i.Patient_id) >= 0)
        console.log("matchedPatient1", this.state.peserta_selected);
        console.log("matchedPatient2", matchedPatient);

        if (this.state.filter_peserta == true) {
          this.setState({ datas: matchedPatient })
        } else {
          this.setState({ datas: _datas })
        }


        if (Number.isInteger(response.results.length / 10) == true) {
          var x = (response.results.length / 10);
          this.setState({
            _totalPage: x
          })
        } else {
          var x = Math.round(this.state.datas.length / 10) < (this.state.datas.length / 10) ? Math.round(this.state.datas.length / 10) + 1 : Math.round(this.state.datas.length / 10);
          this.setState({
            _totalPage: x
          })

        }


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // async getDataDetailPatient() {
  //   var id_company = localStorage.getItem("Company_ID");
  //   console.log(this.state.idPatient, "lihat isinya apa")
  //   await axios.post(uAPIlocal + `/api/v1/detail-patient/${this.state.idPatient}?Company_ID=${id_company}`)
  //     .then(function (response) {
  //       console.log(response, "this is response")
  //       return response.data;
  //     })
  //     .then(response => {
  //       console.log("mydata", response.results);
  //       this.setState({
  //         dataDetail: response.results
  //       })
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }



  actFormatter = (cell, row) => {
    const onclick = (event) => {
      // console.log("clicked on row ", row);
      this.handleShowModalDetail();
    };
    return (
      <ul className="list-inline m-0 d-flex justify-content-center">
        <li className="list-inline-item">
          <button
            className="btn btn-primary btn-sm rounded-0"
            type="button"
            data-toggle="tooltip"
            data-placement="top"
            title="View"
            onClick={onclick}
          >
            <i className="fa fa-list" />
          </button>
        </li>
        <li className="list-inline-item">
          <button
            className="btn btn-success btn-sm rounded-0"
            type="button"
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa fa-edit" />
          </button>
        </li>
        <li className="list-inline-item">
          <button
            className="btn btn-danger btn-sm rounded-0"
            type="button"
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <i className="fa fa-trash" />
          </button>
        </li>
      </ul>
    );
  };

  imgFormatter(cell, row) {
    return (
      <div className="rounded-corners-users"></div>
      // <p>{row.Patient_id}</p>
      // <PhotoProvider>
      //   <PhotoView src={'../../uploads/photo/'+row.Patient_id+'.bmp'}>
      //     <img className="rounded-corners-photo" src={'../../uploads/photo/'+row.Patient_id+'.bmp'}  />
      //   </PhotoView>
      // </PhotoProvider>
      // <img className="rounded-corners-photo" src={'../../uploads/photo/'+row.Patient_id+'.bmp'}  />
    );
  }

  handleRowsClick(cell, row, rowIndex) {
    console.log(this.state.selectedRows, "handle function")
    return (
      <div className={`border-custom-${rowIndex}`} id={`border-custom-${rowIndex}`}>
        {cell}
      </div>
    )
  }
  emptyValueFormatter(cell, row, rowIndex) {
    if (cell === null || cell === undefined || cell === "") {
      return "";
    }
    return cell;
  };
  btnFormatter = (cell, row) => {
    const onclick = (event) => {
      // console.log("clicked on row ", row);
      this.handleShowModalSentTo();
    };
    return (
      <Button
        className="button-block btn-tropical-rain-forest"
        onClick={onclick}
        style={{ zIndex: 99 }}
      >
        1/1
      </Button>
    );
  };
  redValueFormatter(cell, row, rowIndex) {
    console.log(cell, "ini cell")
    // console.log(row.F2022, "ini row")
    console.log(rowIndex, "ini rowindex")
    // console.log("INI YEAR YEAR YEAR",this.state._year_selected);

    if (this.state._year_selected == "2024") {

      if (row.F2024 === "High") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2024 === "Low") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2024 === "2+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2024 === "1+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2024 === "3+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2024 === "4+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2024 === "5+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2024 === "reaktif") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }


    } else if (this.state._year_selected == "2023") {

      if (row.F2023 === "High") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2023 === "Low") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2023 === "High") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2023 === "Low") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2023 === "2+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2023 === "1+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2023 === "3+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2023 === "4+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2023 === "5+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2023 === "reaktif") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }


    } else if (this.state._year_selected == "2022") {
      if (row.F2022 === "High") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2022 === "Low") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2022 === "2+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2022 === "1+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2022 === "3+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2022 === "4+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2022 === "5+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2022 === "reaktif") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
    } else if (this.state._year_selected == "2021") {
      if (row.F2021 === "High") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2021 === "Low") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2021 === "2+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2021 === "1+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2021 === "3+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2021 === "4+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2021 === "5+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2021 === "reaktif") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
    } else if (this.state._year_selected == "2020") {
      if (row.F2020 === "High") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2020 === "Low") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2020 === "2+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2020 === "1+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2020 === "3+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2020 === "4+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2020 === "5+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2020 === "reaktif") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
    } else if (this.state._year_selected == "2019") {
      if (row.F2019 === "High") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2019 === "Low") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2019 === "2+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2019 === "1+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2019 === "3+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2019 === "4+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2019 === "5+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2019 === "reaktif") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
    } else if (this.state._year_selected == "2018") {
      if (row.F2018 === "High") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2018 === "Low") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2018 === "2+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2018 === "1+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2018 === "3+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2018 === "4+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2018 === "5+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2018 === "reaktif") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
    } else if (this.state._year_selected == "2017") {
      if (row.F2017 === "High") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2017 === "Low") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2017 === "2+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2017 === "1+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2017 === "3+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2017 === "4+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2017 === "5+") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
      if (row.F2017 === "reaktif") {
        return (<p style={{ color: 'red' }}>{cell}</p>);
      }
    } else { }


    return cell;
  };

  setTemuanState = () => {
    console.log('setTemuanState')
    this.setState({ temuanClicked: true })
  }
  temuanClick = (data) => {
    axios.post(uAPIlocal + `/api/v1/detail-temuan/${data.Patient_id}?year_selected=${this.state._year_selected}`, {},
      { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
      .then(function (response) {
        return response.data;
      })
      .then(response => {
        console.log('response temuan', response)
        // console.log("mydata", response.results.rows.map((e) => { return e }));
        this.setState({
          dataDetailTemuan: response.results.map((e) => { return e })
        })
        // // console.log("myData222", response.results.patient.map((e) =>{return e}))
        // this.setState({
        //   detailPatient: response.results.patient.map((e) => { return e })
        // })
        this.setState({ showModal: true })
        this.setState({ isSelected: true })
        this.setState({ clr_child: false })
        // this.setState({ temuanClicked: false })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleLastPage = ({
    page,
    onPageChange
  }) => () => {
    onPageChange(this.state._totalPage);
  }


  handleNextPage = ({
    page,
    onPageChange
  }) => () => {
    onPageChange(page + 1);
    this.setState({ pageStartIndex: page + 1 });
  }

  handlePrevPage = ({
    page,
    onPageChange
  }) => () => {
    onPageChange(page - 1);
    this.setState({ pageStartIndex: page - 1 });
  }

  handleSizePerPage = ({
    page,
    onSizePerPageChange
  }, newSizePerPage) => {
    onSizePerPageChange(newSizePerPage, page);
  }



  handleCloseModalDetail = () => () => {

    this.setState({ showModal: false })
    this.setState({ isSelected: false })
    this.setState({ temuanClicked: false })
    this.setState({ clr_child: true })

    var element = document.getElementById(`border-custom-${this.state.selectedRows}`)
    if (element) {
      // element.classList.remove(`row-custom-selected`)
    }

    if (this.state._prevPage == "top10" && this.state._prevState == "top10_peserta") {
      this.setState({ action: true })
    }

    if (this.state._prevPage == "department" && this.state._prevState == "department_peserta") {
      this.setState({ action: true })
    }
  }


  render() {

    if (this.state.redirctTo) {
      Cookies.remove("mcu-login", { path: '/', domain: '.biomedika.co.id' })
    } else { }

    const { tableInbox } = this.state;
    const { SearchBar, ClearSearchButton } = Search;
    // console.log('this.props', this.props?.location?.state)



    const dummyDetail = [
      {
        Patien_id: "B100500228",
        Test_name: "Hemoglobin",
        Ref_value: "14.0-16.0",
        Units: "g/dL",
        2024: "16.1",
        2023: "16.2",
        2022: "16.4"
      },
      {
        Patien_id: "B100500228",
        Test_name: "Eritrosit",
        Ref_value: "14.0-16.0",
        Units: "g/dL",
        2024: "16.1",
        2023: "16.2",
        2022: "16.4"
      },
      {
        Patien_id: "B100500228",
        Test_name: "Hematoktrit",
        Ref_value: "14.0-16.0",
        Units: "g/dL",
        2024: "16.1",
        2023: "16.2",
        2022: "16.4"
      },
      {
        Patien_id: "B100500228",
        Test_name: "MCV",
        Ref_value: "14.0-16.0",
        Units: "g/dL",
        2024: "16.1",
        2023: "16.2",
        2022: "16.4"
      },
      {
        Patien_id: "B100500228",
        Test_name: "MCH",
        Ref_value: "14.0-16.0",
        Units: "g/dL",
        2024: "16.1",
        2023: "16.2",
        2022: "16.4"
      },
      {
        Patien_id: "B100500228",
        Test_name: "MCHC",
        Ref_value: "14.0-16.0",
        Units: "g/dL",
        2024: "16.1",
        2023: "16.2",
        2022: "16.4"
      },
      {
        Patien_id: "B100500228",
        Test_name: "RDW",
        Ref_value: "14.0-16.0",
        Units: "g/dL",
        2024: "16.1",
        2023: "16.2",
        2022: "16.4"
      },
      {
        Patien_id: "B100500228",
        Test_name: "Laju endap darah",
        Ref_value: "14.0-16.0",
        Units: "g/dL",
        2024: "16.1",
        2023: "16.2",
        2022: "16.4"
      },
      {
        Patien_id: "B100500228",
        Test_name: "Hbsag dgn cut off",
        Ref_value: "Non Reaktif",
        Units: "g/dL",
        2024: "16.1",
        2023: "16.2",
        2022: "16.4"
      },
      {
        Patien_id: "B100500228",
        Test_name: "RDW",
        Ref_value: "14.0-16.0",
        Units: "",
        2024: "Non Reaktif",
        2023: "Reaktif",
        2022: "16.4"
      },


    ]

    // B110500233
    const products = [
      {
        id: 1,
        name: "Reyhan Salsabil",
        nik: "31740316041005",
        pid: "8161400644",
        dob: "13/02/1991",
        age: "23 Tahun",
        gender: "Male",
        department: "Finance",
      },
      {
        id: 2,
        name: "Albert Hartanto",
        nik: "31740316041005",
        pid: "8161400644",
        dob: "13/02/1991",
        age: "23 Tahun",
        gender: "Male",
        department: "Marketing",
      },
      {
        id: 3,
        name: "Indira",
        nik: "31740316041005",
        pid: "8161400644",
        dob: "13/02/1991",
        age: "23 Tahun",
        gender: "Female",
        department: "Finance",
      },
    ];


    let columnsDetail = [];

    if (this.state.lvl == '0') {

      columnsDetail = [{
        dataField: "test",
        text: this.props.t('pemeriksaan'),
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '200px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '200px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },

      },
      {
        dataField: "reference",
        text: this.props.t('nilaiReferensi'),
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '100px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '100px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "units",
        text: this.props.t('satuan'),
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "status",
        text: this.props.t('status'),
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.redValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'bold',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2024",
        text: "2024",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2024" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2023",
        text: "2023",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2023" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2022",
        text: "2022",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2022" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2021",
        text: "2021",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2021" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2020",
        text: "2020",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2020" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2019",
        text: "2019",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2019" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2018",
        text: "2018",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2018" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      ]
    }


    if (this.state.lvl == '1') {

      columnsDetail = [{
        dataField: "test",
        text: this.props.t('pemeriksaan'),
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '200px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '200px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },

      },
      {
        dataField: "reference",
        text: this.props.t('nilaiReferensi'),
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '100px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '100px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "units",
        text: this.props.t('satuan'),
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2024",
        text: "2024",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2024" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2023",
        text: "2023",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2023" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2022",
        text: "2022",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2022" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2021",
        text: "2021",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2021" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2020",
        text: "2020",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2020" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2019",
        text: "2019",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2019" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "2018",
        text: "2018",
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.state._year_selected == "2018" ? this.redValueFormatter : this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
          minWidth: '80px',
        },
        headerStyle: (colum, colIndex) => {
          return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      ]

    }



    const columnsDetailTemuan = [
      {
        dataField: "Conc_Name",
        text: this.props.t('temuan'),
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
        },
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },

      },
      {
        dataField: "Test_Name",
        text: this.props.t('pemeriksaan'),
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
        },
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },

      },
      {
        dataField: "N" + this.state._year_selected,
        text: this.props.t('normalValue') + ' ' + this.state._year_selected,
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
        },
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },

      },
      {
        dataField: "hasilTemuan",
        text: this.props.t('testValue') + ' ' + this.state._year_selected,
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
        },
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },

      },
      {
        dataField: "F" + this.state._year_selected,
        text: this.props.t('status') + ' ' + this.state._year_selected,
        sort: true,
        headerClasses: 'table-head-fixed',
        formatter: this.emptyValueFormatter,
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textAlign: 'center',
        },
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },

      },
    ]


    const columns = [
      // {
      //   dataField: "id",
      //   text: "act",
      //   formatter: this.actFormatter,
      //   style: {
      //     width: "100px",
      //   },
      // },
      {
        dataField: "id",
        text: "",
        formatter: this.imgFormatter,
        style: {
          width: "20px",
          textAlign: 'center',
          minWidth: '2%',

        },
        headerAttrs: {
          hidden: false
        },
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '20px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "Patient_name",
        style: {
          // height: '75px',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          textTransform: 'uppercase',
        },
        text: this.props.t('namaLengkap'),
        sort: true,
        formatter: this.handleRowsClick,
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "Patient_NIK",
        style: {
          fontSize: '12px',
          textAlign: 'center',
        },
        text: "NIK",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "Patient_id",
        style: {
          fontSize: '12px',
          textAlign: 'center',
        },
        text: "PID",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "Reg_No",
        style: {
          fontSize: '12px',
          textAlign: 'center',
        },
        text: "Reg No",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "Patient_DOB",
        style: {
          fontSize: '12px',
          textAlign: 'center',
        },
        text: this.props.t('tanggalLahir'),
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },

      {
        dataField: "Patient_Gender",
        style: {
          fontSize: '12px',
          textAlign: 'center',
        },
        text: this.props.t('jenisKelamin'),
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "Patient_Dept",
        style: {
          fontSize: '12px',
          textAlign: 'center',
        },
        text: this.props.t('departemen'),
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      },
      {
        dataField: "temuan",
        style: {
          fontSize: '12px',
          textAlign: 'center',
        },
        text: this.props.t('temuan'),
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            e.preventDefault();
            // console.log('temuan klik', e)
            // console.log('temuan klik', column)
            // console.log('temuan klik', columnIndex)
            // console.log('temuan klik', row)
            // console.log('temuan klik', rowIndex)
            this.setTemuanState();
            this.temuanClick(row);
          },
        },
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },
        formatter: (cell, row, rowIndex) => {
          return (
            <div className={`${cell > 0 ? "text-danger" : "text-dark"}`}>
              {cell}
            </div>
          )
        },
      },
      {
        dataField: "fitunfit",
        style: {
          fontSize: '12px',
          textAlign: 'center',
        },
        text: "Fit Unfit",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
        },
      }
    ];


    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };

      console.log("pageACTIVEX", title)
      if (title != "Pre page") {
        if (title != "First page")
          if (active) {
            // this.setState({ pageStartIndex: page });
            console.log("ini page active", title)
            return (
              <li className="paginationSectionList">
                <a href="#" className="paginationSectionLinkActive" onClick={handleClick} >{page}</a>
              </li>
            );
          } else {

            return (
              <li className="paginationSectionList">
                <a href="#" className="paginationSectionLink" onClick={handleClick} >{page}</a>
              </li>
            );

          }

      }

    };




    const options = {
      pageButtonRenderer,
      custom: true,
      paginationSize: 4,
      pageStartIndex: this.state.tableInbox == false ? this.state.pageStartIndex : 1,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      withFirstAndLast: false,
      alwaysShowAllBtns: false,
      showTotal: true,
      totalSize: this.state.datas.length,

      // hidePageListOnlyOnePage:
    };

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        // console.log(e.detail, "apa isi e?");
        // console.log(row, "ini apa?")
        // console.log('temuanClicked', this.state.temuanClicked)
        switch (e.detail) {
          case 1:
            console.log("click");
            handleShow(row)
            handleRowClick(rowIndex)

            break;
          case 2:
            console.log("double click");
            break;
          case 3:
            console.log("triple click");
            break;
        }
      },
      onMouseEnter: (e, row, rowIndex) => {
        // console.log('onMouseEnter', e);
        // console.log('onMouseEnter', row);
        // console.log('onMouseEnter', rowIndex);
      },
    };

    const handleRowClick = (row) => {
      // Memperbarui state untuk menandai baris yang dipilih
      // console.log(row, "isSelected0 test")

      this.setState({ selectedRows: row });

      // let isSelected = row === this.state.selectedRows;

      // console.log(this.state.selectedRows, "isSelectedROws")
    };

    const formattedAge = (age) => {
      var from = age.split("/");
      var birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
      var cur = new Date();
      var diff = cur - birthdateTimeStamp;
      // This is the difference in milliseconds
      var currentAge = Math.floor(diff / 31557600000);
      return currentAge + " " + "Tahun";
    }

    const handleShow = (data) => {
      var id_fromOnClick = data.Patient_id
      this.fetchDataDetail(id_fromOnClick)
    }

    const rowClasses = () => {
      return 'table-custom-width'; // Gunakan class CSS 'row-height' pada baris genap
    };


    const updateInput = (event) => {
      // console.log(props, "props")
      var val = event.target.value
      // console.log(props.onSearch(event.target.value), "coba")
      console.log(val, "val")
      this.setState({
        searchValue: event.target.value
      })

      // var result = []
      // var temp = this.state.datas
      // console.log(temp, "temp")
      // var filter = temp.filter(e => e.Patient_name === val)
      // // result.push(filter)
      // this.setState({
      //   datas: filter
      // })
      // console.log(filter, "filter")
      // e.onSearch() 
    }
    const ModalDetail = ({ paginationProps, paginationTableProps, isTemuan }) => {
      // console.log('isTemuan', isTemuan)
      // console.log('this.state.dataDetail', this.state.dataDetail)
      return (

        <div className="position-absolute" style={{
          top: "102px",
          zIndex: 6,
        }} ref={this.modalRef} id="slide" >
          {/* {console.log(this.state.dataDetail, "ini apaa ya?")} */}
          <ToolkitProvider keyField="id" columns={isTemuan ? columnsDetailTemuan : columnsDetail} data={isTemuan ? this.state.dataDetailTemuan : this.state.dataDetail} search>
            {(toolkitprops) => (
              <div className="shadow rounded zindex-fixed overflow-scroll mr-3 peopleSubColumn1" style={{ backgroundColor: '#EFEFEF' }}   >

                <button className="btnCloseTable" onClick={this.handleCloseModalDetail()}></button>

                <div className="peopleSubColumn2 text-center bg-darkGreen rounded-top h-[50]" style={{ position: 'fixed' }}>
                  <div className="row justify-content-center ">
                    <div className="col-2">
                      <div className="d-flex flex-row gap-4">
                        <div className="flex-column text-start" style={{ color: "#EFEFEF" }}>
                          <div className="peopleSubColumn2Textheader1">Photo</div>
                        </div>
                        <div className="flex-column text-start" style={{ color: "#EFEFEF" }}>
                          {this.state.detailPatient.map((e) => {
                            return (
                              <>
                                <div className="peopleSubColumn2Textheader1" >
                                  <PhotoProvider>
                                    <PhotoView src={'../../uploads/photo/' + e.Patient_id + '.bmp'}>
                                      <img className="rounded-corners-photo-detail" src={'../../uploads/photo/' + e.Patient_id + '.bmp'} width={'100px'} height={'100px'} />
                                    </PhotoView>
                                  </PhotoProvider>

                                </div>
                              </>
                            )

                          })}

                        </div>
                      </div>
                      {/* One of two columns */}
                    </div>
                    <div className="col-5">
                      <div className="d-flex flex-row gap-4">
                        <div className="flex-column text-start" style={{ color: "#EFEFEF" }}>
                          <div className="peopleSubColumn2Textheader1">{this.props.t('nama')} </div>
                          <div className="peopleSubColumn2Textheader1">NIK </div>
                          <div className="peopleSubColumn2Textheader1">PID </div>
                        </div>
                        <div className="flex-column text-start" style={{ color: "#EFEFEF" }}>
                          {this.state.detailPatient.map((e) => {
                            return (
                              <>
                                <div className="peopleSubColumn2Textheader1" >: {e.Patient_name}</div>
                                <div className="peopleSubColumn2Textheader1" >: {e.Patient_NIK}</div>
                                <div className="peopleSubColumn2Textheader1" >: {e.Patient_id}</div>
                              </>
                            )

                          })}

                        </div>
                      </div>
                      {/* One of two columns */}
                    </div>
                    <div className="col-5">
                      <div className="d-flex flex-row gap-4">
                        <div className="flex-column text-start" style={{ color: "#EFEFEF" }}>
                          <div className="peopleSubColumn2Textheader1">{this.props.t('umur')}</div>
                          <div className="peopleSubColumn2Textheader1">{this.props.t('jenisKelamin')} </div>
                          <div className="peopleSubColumn2Textheader1">{this.props.t('departemen')} </div>
                        </div>
                        <div className="flex-column text-start" style={{ color: "#EFEFEF" }}>
                          {this.state.detailPatient.map((e) => {
                            return (
                              <>
                                <div className="peopleSubColumn2Textheader1" >: {formattedAge(e.Patient_DOB)}</div>
                                <div className="peopleSubColumn2Textheader1" >: {e.Patient_Gender}</div>
                                <div className="peopleSubColumn2Textheader1" >: {e.Patient_Dept}</div>
                              </>
                            )

                          })}
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <BootstrapTable
                  // striped
                  bordered={false}
                  hover
                  // selectRow={selectRow}
                  {...toolkitprops.baseProps}
                  {...paginationTableProps}
                  // rowEvents={tableRowEvents}
                  // pagination={ paginationFactory() }
                  rowClasses={rowClasses}
                // classes="borderless"

                />
                <PaginationListStandalone
                  style={{ float: "right" }}
                  {...paginationProps}
                />

              </div>
            )}
          </ToolkitProvider>


          {/* <Modal show={this.state.showModal} onHide={handleClose}>
              <ToolkitProvider keyField="id" columns={columnsDetail} data={dummyDetail} search>
                {(toolkitprops) => (
                  <div>
                    <BootstrapTable

                      striped
                      bordered={false}
                      hover
                      // selectRow={selectRow}
                      {...toolkitprops.baseProps}
                      {...paginationTableProps}
                      rowEvents={tableRowEvents}
                    // pagination={ paginationFactory() }
                    />

                    <PaginationListStandalone
                      style={{ float: "right" }}
                      {...paginationProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Modal> */}


        </div>
      )
    }

    const MySearch = (props) => {
      // console.log(props, "propss")
      let input;
      const handleClick = (e) => {
        // console.log(input, "input isis")
        // console.log(e.target.value, "value input")
        // console.log(this.state.searchValue, "search value from mysearch")
        props.onSearch(this.input.value);
        // this.setState({ searchWord: e.target.value })
        // console.log(input.value, "")
      };

      const handleClear = (e) => {
        // console.log(input, "input isis")
        // console.log(e.target.value, "value input")
        // console.log(this.state.searchValue, "search value from mysearch")
        props.onSearch("");
        this.input.value = "";
      };

      return (
        <>
          <InputGroup className="mb-3">
            {/* <input
              className="form-control border-right-0 border"
              style={{ backgroundColor: "white", padding: 0 }}
              ref={(n) => (input = n)}
              type="text"
            />
            <button
              className="btn btn-tropical-rain-forest"
              onClick={handleClick}
            >
              Search
            </button> */}
            {/* <input ref={(n) => (console.log(n, "n value"))} onChange={handleClick} type="text" className="search-query form-control" value={this.props.location.state ? this.props?.location?.state?.Patient_ID : ""} placeholder="   Search" /> */}
            <div className="col-12">
              <div id="">
                <div className="input-group" style={{ width: '100%' }}>
                  <input
                    ref={myinput => (this.input = myinput)}
                    onChange={handleClick}
                    type="text" style={{ borderRadius: '50px' }} className="search-query form-control" value={this.state.searchValue} placeholder={"   " + this.props.t('cari')} />
                  {/* <span className="input-group-btn">
                    <button type="button" disabled>
                      <span className="fa fa-search"></span>
                    </button>
                  </span> */}

                  <span className="clr_child" style={{ display: this.state.clr_child ? null : 'none' }} >
                    <button type="button" className="clr_img_btn" onClick={handleClear}>
                      {/* <span className="fa fa-search"></span> */}
                    </button>
                  </span>
                </div>
              </div>
            </div>

          </InputGroup>
        </>
      );
    };



    const contentTableInbox = ({ paginationProps, paginationTableProps }) => {
      // console.log('contentTableInbox', this.state.temuanClicked)
      return (
        <div>
          <ToolkitProvider keyField="id" columns={columns} data={this.state.datas} search={{
            defaultSearch: this.state.searchValue ? this.state.searchValue : ''
          }}>
            {(toolkitprops) => (
              <div>

                {/* <MySearch {...toolkitprops.searchProps} /> */}

                {/* <div> */}
                {/* <SearchBar {...toolkitprops.searchProps} className="mb-3" style={{
                    width: "300px",
                    height: '32px',
                    borderRadius: "25px"
                  }} /> */}


                {/* <ClearSearchButton {...toolkitprops.searchProps} /> */}

                {/* </div> */}

                {/* <div className="row justify-content-end" >



                  <Col>
                    <MySearch  {...toolkitprops.searchProps} />
                    <ButtonGroup>

                      <DropdownButton
                        align="start"
                        id="dropdown-menu-align-end"
                        onSelect={this.setYearAll}
                        // style={{ float: "right", marginBottom: "10px", marginRight: '20px', marginTop: '10px' }}
                        title={
                          <span>
                            {this.state._yAll} <img alt='img1' src={dropdownIcon} style={{ width: '12px' }} />
                          </span>
                        }

                      >
                        {this.state.selectYearTable.map((item, index) => {
                          return (
                            <Dropdown.Item key={index} className={item === this.state._yAll ? 'dropdown-item-checked' : null} active={item === this.state._yAll ? true : false} eventKey={item}>
                              {item}
                            </Dropdown.Item>
                          );
                        })}
                      </DropdownButton>


                    </ButtonGroup>


                  </Col>


                  <Col>



                    <p style={{ float: "right" }}>{this.props.t('peserta')} <b>{this.state._year_selected}</b></p>
                  </Col>

                </div> */}

                <div className="row justify-content-end" >
                  {/* <div className="col-6">
                                        <p>Hi</p>
                                    </div> */}


                  <Col>
                    <MySearch  {...toolkitprops.searchProps} />
                  </Col>
                  <Col className="">
                    <ButtonGroup style={{ float: 'right' }}>
                      <DropdownButton
                        // align="start"
                        id="dropdown-menu-align-end"
                        onSelect={this.setYearAll}
                        // style={{ float: "right", marginBottom: "10px", marginRight: '20px', marginTop: '10px' }}
                        title={
                          <span>
                            {this.state._yAll} <img alt='img1' src={dropdownIcon} style={{ width: '12px' }} />
                          </span>
                        }

                      >
                        {this.state.selectYearTable.map((item, index) => {
                          return (
                            <Dropdown.Item key={index} className={item === this.state._yAll ? 'dropdown-item-checked' : null} active={item === this.state._yAll ? true : false} eventKey={item}>
                              {item}
                            </Dropdown.Item>
                          );
                        })}
                      </DropdownButton>
                    </ButtonGroup>
                    <p style={{ float: "right", marginRight: '10px' }}>{this.props.t('peserta')}:
                      {/* <b>{this.state._year_selected}</b> */}
                    </p>
                  </Col>



                </div>

                <div className="peopleColumn" style={{ overflowY: 'scroll' }}>
                  {!this.state.temuanClicked && this.state.showModal && <ModalDetail />}
                  {this.state.temuanClicked && this.state.showModal && <ModalDetail isTemuan />}
                  {/* {console.log(paginationProps, "ini pagination props")}
                {console.log(paginationTableProps, "ini pagination table props")} */}

                  {console.log(paginationProps, "ini pagination props")}
                  {console.log(paginationTableProps, "ini pagination table props")}


                  <BootstrapTable
                    striped
                    bordered={false}
                    hover
                    // selectRow={selectRow}
                    {...toolkitprops.baseProps}
                    {...paginationTableProps}
                    rowEvents={tableRowEvents}
                    wrapperClasses="table-responsive"



                  // pagination={ paginationFactory() }
                  />
                </div>


                <div className="paginationSection">
                  {paginationProps.page == 1 ? <button disabled="true" className="paginationSectionButtonPrev" onClick={this.handlePrevPage(paginationProps)}></button> : (<button className="paginationSectionButtonPrev" onClick={this.handlePrevPage(paginationProps)}></button>)}
                  <PaginationListStandalone
                    {...paginationProps}
                  />

                  {paginationProps.page != this.state._totalPage ? (
                    <>
                      <ul class="pagination react-bootstrap-table-page-btns-ul">
                        <li class="paginationSectionList">
                          <a href="#" class="paginationSectionLink">...</a>
                        </li>
                      </ul>
                      <ul class="pagination react-bootstrap-table-page-btns-ul">
                        <li class="paginationSectionList">
                          <a onClick={this.handleLastPage(paginationProps)} href="#" class="paginationSectionLink">{this.state._totalPage}</a>
                        </li>
                      </ul></>) : null}

                  {paginationProps.page == this.state._totalPage ? <button disabled="true" className="paginationSectionButtonNext" onClick={this.handleNextPage(paginationProps)}></button> : <button className="paginationSectionButtonNext" onClick={this.handleNextPage(paginationProps)}></button>}
                </div>


              </div>
            )}
          </ToolkitProvider>
        </div >
      );
    }

    const selectRow = {
      mode: "checkbox",
      style: { backgroundColor: "#c8e6c9" },
      selectColumnStyle: {
        width: 20,
      },
      selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
        <input
          type="checkbox"
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          {...rest}
        />
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <input
          // style={{width:20}}
          type={mode}
          {...rest}
        />
      ),
    };

    // console.log('this.state.temuanClicked', this.state.temuanClicked)

    // back
    if (this.state.redirect === true && this.state.action === true) {
      return (<Redirect to={{
        pathname: this.state.pathname,
        state: {
          id: this.state.idSelected,
          opt: this.state.optSelected,
          prevPage: this.state.prevPage,
          prevState: this.state.prevState,
          goTo: this.state.goTo,
        }
      }} />)
    }

    return (
      <>

        <Row>
          <Col md={12} style={{ cursor: 'pointer' }}>
            {tableInbox ? (
              <PaginationProvider pagination={paginationFactory(options)}>
                {contentTableInbox}
              </PaginationProvider>
            ) : null}
          </Col>

        </Row>



      </>
    );
  }
}
export default withRouter(withTranslation()(People));
