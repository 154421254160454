import React, { Component } from "react";
import '../body.scss';
import {
    Container,
    Row,
    Col,
    // Card,
    Button,
    ButtonGroup,
    Form,
    // FormControl,
    // Breadcrumb,
    // Carousel,
    // List,
    DropdownButton,
    Dropdown,
    Tab,
    Nav
} from "react-bootstrap";
// import ListGroup from "react-bootstrap/ListGroup";
import InputGroup from "react-bootstrap/InputGroup";
// import Modal from "react-bootstrap/Modal";
// import Collapse from "react-bootstrap/Collapse";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
// import filterFactory, {
//   dateFilter,
//   Comparator,
// } from "react-bootstrap-table2-filter";
import ToolkitProvider, {
    Search,
    // CSVExport,
} from "react-bootstrap-table2-toolkit";
// import cellEditFactory from "react-bootstrap-table2-editor";
// import Navbar from './Navbar';
// import maskGroup from "../../assets/maskGroup.png";
import dropdownIcon from "../../../assets/br_down.png";
import Modal from "react-bootstrap/Modal";
import Collapse from "react-bootstrap/Collapse";
import Select from 'react-select';
import moment from "moment";

import axios from 'axios';
import { uAPIlocal } from '../../lib/config';
import { withRouter, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Swal from "sweetalert2";
import { ComboBox } from 'rc-easyui';
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import CryptoJS from "crypto-js";
let source = axios.CancelToken.source();
axios.defaults.withCredentials = true;
axios.defaults.headers.post["X-CSRF-Token"] = Cookies.get('mcu-csrf');
axios.defaults.headers.put["X-CSRF-Token"] = Cookies.get('mcu-csrf');
axios.defaults.headers.delete["X-CSRF-Token"] = Cookies.get('mcu-csrf');

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
});

class People extends Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.state = {
            redirctTo: false,
            tableInbox: true,
            datas: [],
            _totalPage: 0,
            showModal: false,
            dataDetail: [],
            dataDetailTemuan: [],
            idPatient: null,
            selectedRows: null,
            isSelected: false,
            detailPatient: [],

            // nextpage
            redirect: false,
            pathname: "",
            idSelected: "",
            prevState: "",
            prevPage: "",
            goTo: "",
            action: "false",


            // props
            _id: this.props.location.state ? this.props.location.state.id : null,
            _conc_id: this.props.location.state ? this.props.location.state.conc_id : null,
            _prevPage: this.props.location.state ? this.props.location.state.prevPage : null,
            _prevState: this.props.location.state ? this.props.location.state.prevState : null,
            _year_selected: this.props.location.state ? this.props.location.state.year_selected : null,
            selectYearTable: [],
            _yAll: "",

            searchValue: this.props.location.state ? this.props.location.state.id : null,
            searchWord: '',

            peserta_selected: [],
            filter_peserta: false,
            temuanClicked: false,
            redirectDetail: [],
            isSearch: false,
            dataSearch: [],
            getVal: null,

            clr_child: true,
            modalForm: false,
            modalAddResult: false,
            modalAddTemuan: false,

            // form peserta
            peserta_nama: "",
            peserta_nik: "",
            peserta_pid: "",
            peserta_dob: new Date().toISOString().slice(0, 10),
            peserta_gender: "M",

            peserta_mcuYear: "",
            peserta_patientAge: "",
            peserta_patientDiv: "",
            peserta_patientLoc: "",
            peserta_patientFit: "",
            peserta_patientResult: "",
            peserta_patientFitunfit: "",
            peserta_patientAgeRange: "",
            peserta_patientRegNo: "",
            peserta_patientPackageID: "",
            peserta_patientRegNoAdd: "",
            selectYear: [],
            selectAgeRange: [],

            tabkey: 1,
            addPatientPeriode: false,
            _delSwalPatient: false,
            _editFormActive: false,
            updateBtnV2: false,
            updateBtnV3: false,
            updateBtnV4: false,

            // form result
            result_2016: "",
            result_F2016: "",
            result_R2016: "",
            result_N2016: "",
            result_U2016: "",
            result_2017: "",
            result_F2017: "",
            result_R2017: "",
            result_N2017: "",
            result_U2017: "",
            result_2018: "",
            result_F2018: "",
            result_R2018: "",
            result_N2018: "",
            result_U2018: "",
            result_2019: "",
            result_F2019: "",
            result_R2019: "",
            result_N2019: "",
            result_U2019: "",
            result_2020: "",
            result_F2020: "",
            result_R2020: "",
            result_N2020: "",
            result_U2020: "",
            result_2021: "",
            result_F2021: "",
            result_R2021: "",
            result_N2021: "",
            result_U2021: "",
            result_2022: "",
            result_F2022: "",
            result_R2022: "",
            result_N2022: "",
            result_U2022: "",
            result_2023: "",
            result_F2023: "",
            result_R2023: "",
            result_N2023: "",
            result_U2023: "",
            result_2024: "",
            result_F2024: "",
            result_R2024: "",
            result_N2024: "",
            result_U2024: "",

            // form temuan
            temuan_2016: "",
            temuan_2017: "",
            temuan_2018: "",
            temuan_2019: "",
            temuan_2020: "",
            temuan_2021: "",
            temuan_2022: "",
            temuan_2023: "",
            temuan_2024: "",
            loading: true,
            lastPageNumber: 1,

            recentPesertaID: "",




        };
        this.getPatient = this.getPatient.bind(this);
        this.handleRowsClick = this.handleRowsClick.bind(this)
        this.fetchDataDetail = this.fetchDataDetail.bind(this)
        // this.getDataDetailPatient = this.getDataDetailPatient.bind(this)
        // this.modalRef = this.modalRef.bind(this)
        this.setTemuanState = this.setTemuanState.bind(this);
        this.temuanClick = this.temuanClick.bind(this);
        this.temuanClickFetch = this.temuanClickFetch.bind(this);
        this.handleModalRedirect = this.handleModalRedirect.bind(this);
        this.redValueFormatter = this.redValueFormatter.bind(this);
        this.getDept = this.getDept.bind(this);
        this.handleSelect = this.handleSelect.bind(this)
        this.getSelectYear = this.getSelectYear.bind(this);
        this.getSelectAgeRange = this.getSelectAgeRange.bind(this);
        this.getTest_Code = this.getTest_Code.bind(this);
        this.getConc_Code = this.getConc_Code.bind(this);
        this.onChange = this.onChange.bind(this);
        this.autoLogout = this.autoLogout.bind(this);
        source = axios.CancelToken.source()


    }



    async componentDidMount() {

        this.props.i18n.changeLanguage(localStorage.getItem("lang"));
        this.getSelectYearTable();
        localStorage.setItem("path", "DASBOR");
        // window.addEventListener("resize", this.resizeScreen());
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            withCredentials: true
        };
        formData.append('name', localStorage.getItem("name"));
        formData.append('message', "Akses Master Peserta");

        await axios.post(uAPIlocal + '/api/v1/log', formData, config)
            .then(response => {
                console.log(response)
                if (response.data.status === 406) {
                    console.log(response)

                } else {
                    console.log(response)
                }

            })
            .catch(function (error) {
                console.log(error);
            });

        // this.getDept();

    }
    componentDidUpdate(prevProps, prevState) {
        console.log(prevState, "prev state")
        if (prevState.selectedRows !== this.state.selectedRows) {
            // document.('custom-border')
            // console.log(this.state.selectedRows, "selectedRows")
            // console.log(prevState, "prev")
            var element = document.getElementById(`border-custom-${this.state.selectedRows}`)
            if (element) {
                element.classList.add('row-custom-selected')
                var element2 = document.getElementById(`border-custom-${prevState.selectedRows}`)
                if (element2) {
                    element2.classList.remove('row-custom-selected')
                    // console.log(this.state.showModal, "showModal")
                }


            }

            console.log(element, 'element')

        }
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClose)
        if (source) {
            source.cancel("Landing Component got unmounted");
        }
    }

    async getSelectYearTable() {

        this.getSelectYear();
        this.getSelectAgeRange();
        this.getTest_Code()
        this.getConc_Code()

        await axios.post(uAPIlocal + '/api/v1/getselectyear',
            { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
            .then(function (response) {
                return response.data;
            })
            .then(response => {
                const res = response.results;
                console.log("year", res);

                if (res.length === 0) {
                    this.getSelectYearTable();
                } else {
                    res.map((item, index) => {
                        this.setState({ selectYearTable: [...this.state.selectYearTable, item.mcu_year] })
                    })

                    console.log("yearX", this.state.selectYearTable);

                    if (this.state._year_selected == null) {
                        this.setState({
                            _year_selected: this.state.selectYearTable[0],
                        })
                    }

                    this.setState({
                        _yAll: this.state.selectYearTable[0],
                    })

                    if (this.state._conc_id != null) {
                        console.log("kondisi 3");
                        this.getGrafikDash()
                    } else if (this.state._id != null) {
                        console.log("kondisi 2")

                        if (this.state._prevPage == "top10" &&
                            this.state._prevState == "top10_peserta") {
                            this.temuanClickFetch(this.state._id);
                            this.getPatient();
                        }

                        if (this.state._prevPage == "department" &&
                            this.state._prevState == "department_peserta") {
                            this.handleModalRedirect(this.state._id);
                            this.getPatient();
                        }
                    } else {
                        this.getPatient();
                    }

                    this.getDept();


                }


            })
            .catch((e) => {
                console.log(e);
                // setTimeout(() => {
                //     window.location.reload()
                // }, 2000);
                // this.autoLogout();
            });

    }

    autoLogout = () => {
        this.setState({ redirctTo: true });
    }

    setYearAll = (eventkey, event) => {
        event.persist();
        console.log(eventkey);

        // this.getPatient();
        this.setState({
            _yAll: eventkey,
            _year_selected: eventkey,
        }, () => this.locationReload())

    };



    async getGrafikDash() {
        await axios.post(uAPIlocal + '/api/v1/gettop10pemeriksaanpeserta', { _y: this.state._year_selected, conc_id: this.state._conc_id },
            { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
            .then(function (response) {
                return response.data;
                // console.log("grafik_dash", response.data);
            })
            .then(response => {
                let datas = [];
                response.results.map((item, index) => {
                    datas.push(item.Patient_id);
                })
                console.log("grafik_dash_untuk_peserta", datas);
                this.setState({ peserta_selected: datas, filter_peserta: true }, () => this.getPatient());

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    fetchDataDetail(_patientId, id_company) {
        axios.post(uAPIlocal + `/api/v1/detail-patient/${_patientId}?year_selected=${this.state._year_selected}`, { signal: AbortSignal.timeout(10000) })
            .then(function (response) {
                // console.log(response, "this is response")
                return response.data;
            })
            .then(response => {

                this.setState({
                    detailPatient: response.results.patient.map((e) => { return e })
                })


            })
            .catch(function (error) {
                console.log(error);
            });

        setTimeout(() => {
            if (this.state.temuanClicked == false) {
                axios.post(uAPIlocal + `/api/v1/detail-patient/${_patientId}?year_selected=${this.state._year_selected}`, { signal: AbortSignal.timeout(10000) })
                    .then(function (response) {
                        // console.log(response, "this is response")
                        return response.data;
                    })
                    .then(response => {
                        // console.log("mydata", response.results.rows.map((e) => { return e }));
                        this.setState({
                            dataDetail: response.results.rows.map((e) => { return e })
                        })
                        // console.log("myData222", response.results.patient.map((e) =>{return e}))
                        this.setState({
                            detailPatient: response.results.patient.map((e) => { return e })
                        })

                        this.setState({ showModal: true })
                        this.setState({ isSelected: true })
                        this.setState({ clr_child: false })
                        // this.getPatient();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else { }
        }, 500);

    }

    async temuanClickFetch(id) {
        this.setState({ temuanClicked: true })
        await axios.post(uAPIlocal + `/api/v1/detail-temuan/${id}?year_selected=${this.state._year_selected}`, { signal: AbortSignal.timeout(10000) })
            .then(function (response) {
                return response.data;
            })
            .then(response => {
                console.log('response temuan', response)
                // console.log("mydata", response.results.rows.map((e) => { return e }));
                this.setState({
                    dataDetailTemuan: response.results.map((e) => { return e })
                })
                // // console.log("myData222", response.results.patient.map((e) =>{return e}))
                // this.setState({
                //   detailPatient: response.results.patient.map((e) => { return e })
                // })


                // this.fetchDataDetail(id, id_company)

                // this.setState({ showModal: true })
                // this.setState({ isSelected: true })
                // this.setState({ temuanClicked: false })

                if (this.state._prevPage == "top10" && this.state._prevState == "top10_peserta") {
                    this.setState({
                        redirect: true,
                        pathname: "/admin/top10",
                        idSelected: this.state._id,
                        prevState: "back",
                        prevPage: "people",
                        goTo: this.state._prevState,
                    });
                }

                this.setState({
                    searchValue: this.state._id
                })

            })
            .catch(function (error) {
                console.log(error);
            });

        await axios.post(uAPIlocal + `/api/v1/detail-temuan2/${id}`, { signal: AbortSignal.timeout(10000) })
            .then(function (response) {
                return response.data;
            })
            .then(response => {

                this.setState({
                    detailPatient: response.results.map((e) => { return e })
                })

                this.setState({ showModal: true })
                this.setState({ isSelected: true })
                this.setState({ clr_child: false })
            })
            .catch(function (error) {
                console.log(error);
            });


    }



    handleModalRedirect(id) {

        if (this.state._prevPage == "department" && this.state._prevState == "department_peserta") {
            this.setState({
                redirect: true,
                pathname: "/admin/departemen",
                idSelected: this.state._id,
                prevState: "back",
                prevPage: "people",
                goTo: this.state._prevState,
            });
        }

        if (id) {
            this.fetchDataDetail(id)
        }
    }

    handleClose = event => {
        if (this.modalRef && !this.modalRef.textContent) {
            // console.log("test test")
            this.setState({ showModal: false })
            this.setState({ isSelected: false })
            this.setState({ temuanClicked: false })
            var element = document.getElementById(`border-custom-${this.state.selectedRows}`)
            if (element) {
                element.classList.remove(`row-custom-selected`)
            }
        }

    }



    async getPatient() {
        // var id_company = '0383';
        console.log(this.state.searchValue, "search value")
        await axios.post(uAPIlocal + `/api/v1/patient_suspect?year=${this.state._year_selected}`, { signal: AbortSignal.timeout(10000) })
            .then(function (response) {
                console.log(response, "this is response")
                return response.data;
            })
            .then(response => {
                console.log("mydata", response.results);
                const _datas = response.results.sort(function (a, b) {
                    return a.Patient_name > b.Patient_name;
                });

                const matchedPatient = _datas.filter(i => this.state.peserta_selected.indexOf(i.Patient_id) >= 0)

                if (this.state.filter_peserta == true) {
                    this.setState({ datas: matchedPatient })
                } else {
                    this.setState({ datas: _datas })
                }

                console.log('ini datas', this.state.datas)


                let peoples = []
                if (_datas.length > 0) {
                    _datas.forEach(element => {
                        if (element.Patient_id) {
                            peoples.push({
                                value: element.Patient_id,
                                label: element.Patient_name
                            })
                        }
                    });
                }
                this.setState({ optPeserta: peoples, choosePeserta: peoples[0] }, () =>
                    console.log("ok")
                )


                if (Number.isInteger(response.results.length / 10) == true) {
                    var x = (response.results.length / 10);
                    this.setState({
                        _totalPage: x
                    })
                } else {
                    var x = Math.round(this.state.datas.length / 10) < (this.state.datas.length / 10) ? Math.round(this.state.datas.length / 10) + 1 : Math.round(this.state.datas.length / 10);
                    this.setState({
                        _totalPage: x
                    })

                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async getTest_Code() {
        await axios.post(uAPIlocal + '/api/v1/test_code')
            // .then(function (response) {
            //     return response.data.results;
            // })
            .then(response =>
                response.data.results.map(Test_Code => ({
                    value: `${Test_Code.Test_ID}`,
                    label: `${Test_Code.Test_ID} - ${Test_Code.Test_Name}`,
                }))
            )
            .then(Test_Code => {
                this.setState({
                    Test_Code: Test_Code,
                    chooseTest: Test_Code[0]
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    async getConc_Code() {
        await axios.post(uAPIlocal + '/api/v1/conc_code')
            // .then(function (response) {
            //     return response.data.results;
            // })
            .then(response =>
                response.data.results.map(Conc_Code => ({
                    value: `${Conc_Code.Conc_ID}`,
                    text: `${Conc_Code.Conc_ID} - ${Conc_Code.Conc_Name.replace("- ", "")}`,
                    // label: `${Conc_Code.Conc_ID} - ${Conc_Code.Conc_Name.replace("- ", "")}`,
                }))
            )
            .then(Conc_Code => {
                this.setState({
                    Conc_Code: Conc_Code,
                    chooseConc: Conc_Code[0],
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // async getDataDetailPatient() {
    //   var id_company = localStorage.getItem("Company_ID");
    //   console.log(this.state.idPatient, "lihat isinya apa")
    //   await axios.post(uAPIlocal + `/api/v1/detail-patient/${this.state.idPatient}?Company_ID=${id_company}`)
    //     .then(function (response) {
    //       console.log(response, "this is response")
    //       return response.data;
    //     })
    //     .then(response => {
    //       console.log("mydata", response.results);
    //       this.setState({
    //         dataDetail: response.results
    //       })
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // }



    actFormatter = (cell, row) => {
        const onclick = (event) => {
            // console.log("clicked on row ", row);
            this.handleShowModalDetail();
        };
        return (
            <ul className="list-inline m-0 d-flex justify-content-center">
                <li className="list-inline-item">
                    <button
                        className="btn btn-primary btn-sm rounded-0"
                        type="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="View"
                        onClick={onclick}
                    >
                        <i className="fa fa-list" />
                    </button>
                </li>
                <li className="list-inline-item">
                    <button
                        className="btn btn-success btn-sm rounded-0"
                        type="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                    >
                        <i className="fa fa-edit" />
                    </button>
                </li>
                <li className="list-inline-item">
                    <button
                        className="btn btn-danger btn-sm rounded-0"
                        type="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete"
                    >
                        <i className="fa fa-trash" />
                    </button>
                </li>
            </ul>
        );
    };

    imgFormatter(cell, row) {
        return (
            <div className="rounded-corners-users"></div>
        );
    }

    handleRowsClick(cell, row, rowIndex) {
        console.log(this.state.selectedRows, "handle function")
        return (
            <div className={`border-custom-${rowIndex}`} id={`border-custom-${rowIndex}`}>
                {cell}
            </div>
        )
    }
    emptyValueFormatter(cell, row, rowIndex) {
        if (cell === null || cell === undefined || cell === "") {
            return "";
        }
        return cell;
    };
    btnFormatter = (cell, row) => {
        const onclick = (event) => {
            // console.log("clicked on row ", row);
            this.handleShowModalSentTo();
        };
        return (
            <Button
                className="button-block btn-tropical-rain-forest"
                onClick={onclick}
                style={{ zIndex: 99 }}
            >
                1/1
            </Button>
        );
    };
    redValueFormatter(cell, row, rowIndex) {
        console.log(cell, "ini cell")
        // console.log(row.F2022, "ini row")
        console.log(rowIndex, "ini rowindex")
        // console.log("INI YEAR YEAR YEAR",this.state._year_selected);

        if (this.state._year_selected == "2023") {
            if (row.F2023 === "High") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
            if (row.F2023 === "Low") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
        } else if (this.state._year_selected == "2022") {
            if (row.F2022 === "High") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
            if (row.F2022 === "Low") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
        } else if (this.state._year_selected == "2021") {
            if (row.F2021 === "High") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
            if (row.F2021 === "Low") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
        } else if (this.state._year_selected == "2020") {
            if (row.F2020 === "High") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
            if (row.F2020 === "Low") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
        } else if (this.state._year_selected == "2019") {
            if (row.F2019 === "High") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
            if (row.F2019 === "Low") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
        } else if (this.state._year_selected == "2018") {
            if (row.F2018 === "High") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
            if (row.F2018 === "Low") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
        } else if (this.state._year_selected == "2017") {
            if (row.F2017 === "High") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
            if (row.F2017 === "Low") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
        } else if (this.state._year_selected == "2016") {
            if (row.F2016 === "High") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
            if (row.F2016 === "Low") {
                return (<p style={{ color: 'red' }}>{cell}</p>);
            }
        } else { }


        return cell;
    };

    setTemuanState = () => {
        console.log('setTemuanState')
        this.setState({ temuanClicked: true })
    }
    temuanClick = (data) => {
        axios.post(uAPIlocal + `/api/v1/detail-temuan/${data.Patient_id}?year_selected=${this.state._year_selected}`, { signal: AbortSignal.timeout(10000) })
            .then(function (response) {
                return response.data;
            })
            .then(response => {
                console.log('response temuan', response)
                // console.log("mydata", response.results.rows.map((e) => { return e }));
                this.setState({
                    dataDetailTemuan: response.results.map((e) => { return e })
                })
                // // console.log("myData222", response.results.patient.map((e) =>{return e}))
                // this.setState({
                //   detailPatient: response.results.patient.map((e) => { return e })
                // })
                this.setState({ showModal: true })
                this.setState({ isSelected: true })
                this.setState({ clr_child: false })
                // this.setState({ temuanClicked: false })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleLastPage = ({
        page,
        onPageChange
    }) => () => {
        onPageChange(this.state._totalPage);
    }


    handleNextPage = ({
        page,
        onPageChange
    }) => () => {
        onPageChange(page + 1);
        this.setState({ lastPageNumber: page + 1 });
    }

    handlePrevPage = ({
        page,
        onPageChange
    }) => () => {
        onPageChange(page - 1);
        this.setState({ lastPageNumber: page - 1 });
    }

    handleSizePerPage = ({
        page,
        onSizePerPageChange
    }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }



    handleCloseModalDetail = () => () => {

        this.setState({ showModal: false })
        this.setState({ isSelected: false })
        this.setState({ temuanClicked: false })
        this.setState({ clr_child: true })

        var element = document.getElementById(`border-custom-${this.state.selectedRows}`)
        if (element) {
            // element.classList.remove(`row-custom-selected`)
        }

        if (this.state._prevPage == "top10" && this.state._prevState == "top10_peserta") {
            this.setState({ action: true })
        }

        if (this.state._prevPage == "department" && this.state._prevState == "department_peserta") {
            this.setState({ action: true })
        }
    }


    handleCloseModalForm() {
        this.setState({ modalForm: false });
        this.resetForm();
    }

    handleCloseModalAddResult() {
        this.setState({ modalAddResult: false });
        this.resetForm();
    }

    handleCloseModalAddTemuan() {
        this.setState({ modalAddTemuan: false });
        this.resetForm();
    }


    handleCloseModalAddFitunfit() {
        this.setState({ modalAddFitunfit: false });
        this.resetForm();
    }

    resetForm() {
        this.setState({
            peserta_nama: "",
            peserta_nik: "",
            peserta_pid: "",
            peserta_dob: new Date().toISOString().slice(0, 10),
            peserta_gender: "M",
            peserta_mcuYear: this.state.selectYear[0],
            peserta_patientAge: "",
            peserta_patientDiv: "",
            peserta_patientLoc: "",
            peserta_patientFit: "",
            peserta_patientResult: "",
            peserta_patientFitunfit: "",
            peserta_patientAgeRange: this.state.selectAgeRange[0],
            peserta_patientRegNo: "",
            peserta_patientPackageID: "",
            peserta_patientRegNoAdd: "",
            tabkey: 1,
            addPatientPeriode: false,
            _delSwalPatient: false,
            _editFormActive: false,
            updateBtnV2: false,
            updateBtnV3: false,
            updateBtnV4: false,
            temuanClicked: false,
            showModal: false,
            result_2016: "",
            result_F2016: "",
            result_R2016: "",
            result_N2016: "",
            result_U2016: "",
            result_2017: "",
            result_F2017: "",
            result_R2017: "",
            result_N2017: "",
            result_U2017: "",
            result_2018: "",
            result_F2018: "",
            result_R2018: "",
            result_N2018: "",
            result_U2018: "",
            result_2019: "",
            result_F2019: "",
            result_R2019: "",
            result_N2019: "",
            result_U2019: "",
            result_2020: "",
            result_F2020: "",
            result_R2020: "",
            result_N2020: "",
            result_U2020: "",
            result_2021: "",
            result_F2021: "",
            result_R2021: "",
            result_N2021: "",
            result_U2021: "",
            result_2022: "",
            result_F2022: "",
            result_R2022: "",
            result_N2022: "",
            result_U2022: "",
            result_2023: "",
            result_F2023: "",
            result_R2023: "",
            result_N2023: "",
            result_U2023: "",
            result_2024: "",
            result_F2024: "",
            result_R2024: "",
            result_N2024: "",
            result_U2024: "",
            temuan_2016: "",
            temuan_2017: "",
            temuan_2018: "",
            temuan_2019: "",
            temuan_2020: "",
            temuan_2021: "",
            temuan_2022: "",
            temuan_2023: "",
            temuan_2024: "",
            choosePeserta: this.state.optPeserta[0],
            chooseTest: this.state.Test_Code[0],
            chooseConc: this.state.Conc_Code[0],
            chooseDept: this.state.datas[0],

        })

        // if (this.state.showModal){
        //     this.setState({})
        // }
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async getDept() {

        axios.post(uAPIlocal + '/api/v1/patient_dept', { _y: this.state._year_selected }, { signal: AbortSignal.timeout(10000) },
            { headers: { 'content-type': 'multipart/form-data' } })
            .then((res) => {
                let { results } = res.data
                let datas = []
                if (results.length > 0) {

                    datas.push({
                        value: "",
                        label: "-- Tidak Terdefinisi --"
                    })

                    results.forEach(element => {
                        if (element.Patient_Dept) {
                            datas.push({
                                value: element.Patient_Dept,
                                label: element.Patient_Dept
                            })
                        }
                    });
                }
                this.setState({ optDept: datas, chooseDept: datas[0] }, () =>
                    console.log("ok")
                )
            })
            .catch((err) => {
                console.log('err', err)
            })

    }


    save_v2() {

        // ini save
        console.log('name', this.state.peserta_nama);
        console.log('nik', this.state.peserta_nik);
        console.log('name', this.state.peserta_pid);
        console.log('dob', moment(this.state.peserta_dob, 'YYYY-MM-DD').format('DD/MM/YYYY'));
        console.log('gender', this.state.peserta_gender);
        console.log('depart', this.state.chooseDept?.value);

        let f = new FormData();
        f.append('name', this.state.peserta_nama);
        f.append('nik', this.state.peserta_nik);
        f.append('pid', this.state.peserta_pid);
        f.append('dob', moment(this.state.peserta_dob, 'YYYY-MM-DD').format('DD/MM/YYYY'));
        f.append('gender', this.state.peserta_gender);

        if (this.state.chooseDept?.value == undefined) {
            f.append('department', "");
        } else {
            f.append('department', this.state.chooseDept?.value);
        }


        // f.append('company_id', localStorage.getItem("Company_ID"));

        f.append('mcuYear', this.state.peserta_mcuYear);
        f.append('patientAge', this.state.peserta_patientAge);
        f.append('patientDiv', this.state.peserta_patientDiv);
        f.append('patientLoc', this.state.peserta_patientLoc);
        f.append('patientFit', this.state.peserta_patientFit);
        f.append('patientResult', this.state.peserta_patientResult);
        f.append('patientAgeRange', this.state.peserta_patientAgeRange);
        f.append('patientRegNo', this.state.peserta_patientRegNo);
        f.append('patientPackageID', this.state.peserta_patientPackageID);
        f.append('patientRegNoAdd', this.state.peserta_patientRegNoAdd);

        if (this.state.addPatientPeriode) {
            f.append('patientPID', this.state.choosePeserta?.value);
        }



        for (var pair of f.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        if (this.state.peserta_nama == "" && !this.state.addPatientPeriode) {
            swalWithBootstrapButtons
                .fire({
                    icon: "error",
                    title: "Nama Peserta Harus Diisi!",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                })
                .then(function () { });
        } else if (this.state.peserta_pid == "" && !this.state.addPatientPeriode) {
            swalWithBootstrapButtons
                .fire({
                    icon: "error",
                    title: "PID Harus Diisi!",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                })
                .then(function () { });
        } else {

            axios({
                method: "post",
                url: uAPIlocal + "/api/v1/master_patient",
                data: f,
                headers: { 'content-type': 'multipart/form-data' }
            }, { signal: AbortSignal.timeout(10000) })
                .then((response) => {
                    console.log("ini res", response);
                    let data = response.data;

                    if (data.status == 406) {
                        swalWithBootstrapButtons
                            .fire({
                                icon: "error",
                                title: data.message,
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener("mouseenter", Swal.stopTimer);
                                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                                },
                            })
                    } else if (data.status == 200) {
                        swalWithBootstrapButtons
                            .fire({
                                icon: "success",
                                title: data.message,
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener("mouseenter", Swal.stopTimer);
                                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                                },
                            })
                            .then(function () {
                                // window.location.reload()
                            });

                        this.handleCloseModalForm();
                        this.locationReload();
                    }



                })
                .catch((e) => {
                    console.error(e);
                });


        }

    }


    save_v3() {


        let f = new FormData();

        f.append('patientPID', this.state.choosePeserta?.value);
        f.append('patientTest', this.state.chooseTest?.value);

        f.append('result_2016', this.state.result_2016);
        f.append('result_F2016', this.state.result_F2016);
        f.append('result_R2016', this.state.result_R2016);
        f.append('result_N2016', this.state.result_N2016);
        f.append('result_U2016', this.state.result_U2016);

        f.append('result_2017', this.state.result_2017);
        f.append('result_F2017', this.state.result_F2017);
        f.append('result_R2017', this.state.result_R2017);
        f.append('result_N2017', this.state.result_N2017);
        f.append('result_U2017', this.state.result_U2017);

        f.append('result_2018', this.state.result_2018);
        f.append('result_F2018', this.state.result_F2018);
        f.append('result_R2018', this.state.result_R2018);
        f.append('result_N2018', this.state.result_N2018);
        f.append('result_U2018', this.state.result_U2018);

        f.append('result_2019', this.state.result_2019);
        f.append('result_F2019', this.state.result_F2019);
        f.append('result_R2019', this.state.result_R2019);
        f.append('result_N2019', this.state.result_N2019);
        f.append('result_U2019', this.state.result_U2019);

        f.append('result_2020', this.state.result_2020);
        f.append('result_F2020', this.state.result_F2020);
        f.append('result_R2020', this.state.result_R2020);
        f.append('result_N2020', this.state.result_N2020);
        f.append('result_U2020', this.state.result_U2020);

        f.append('result_2021', this.state.result_2021);
        f.append('result_F2021', this.state.result_F2021);
        f.append('result_R2021', this.state.result_R2021);
        f.append('result_N2021', this.state.result_N2021);
        f.append('result_U2021', this.state.result_U2021);

        f.append('result_2022', this.state.result_2022);
        f.append('result_F2022', this.state.result_F2022);
        f.append('result_R2022', this.state.result_R2022);
        f.append('result_N2022', this.state.result_N2022);
        f.append('result_U2022', this.state.result_U2022);

        f.append('result_2023', this.state.result_2023);
        f.append('result_F2023', this.state.result_F2023);
        f.append('result_R2023', this.state.result_R2023);
        f.append('result_N2023', this.state.result_N2023);
        f.append('result_U2023', this.state.result_U2023);

        f.append('result_2024', this.state.result_2024);
        f.append('result_F2024', this.state.result_F2024);
        f.append('result_R2024', this.state.result_R2024);
        f.append('result_N2024', this.state.result_N2024);
        f.append('result_U2024', this.state.result_U2024);

        // f.append('company_id', localStorage.getItem("Company_ID"));


        for (var pair of f.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // if (this.state.peserta_nama == "" && this.state.addPatientPeriode) {
        //     swalWithBootstrapButtons
        //         .fire({
        //             icon: "error",
        //             title: "Nama Peserta Harus Diisi!",
        //             toast: true,
        //             position: "top-end",
        //             showConfirmButton: false,
        //             timer: 3000,
        //             timerProgressBar: true,
        //             didOpen: (toast) => {
        //                 toast.addEventListener("mouseenter", Swal.stopTimer);
        //                 toast.addEventListener("mouseleave", Swal.resumeTimer);
        //             },
        //         })
        //         .then(function () { });
        // } else if (this.state.peserta_pid == "" && this.state.addPatientPeriode) {
        //     swalWithBootstrapButtons
        //         .fire({
        //             icon: "error",
        //             title: "PID Harus Diisi!",
        //             toast: true,
        //             position: "top-end",
        //             showConfirmButton: false,
        //             timer: 3000,
        //             timerProgressBar: true,
        //             didOpen: (toast) => {
        //                 toast.addEventListener("mouseenter", Swal.stopTimer);
        //                 toast.addEventListener("mouseleave", Swal.resumeTimer);
        //             },
        //         })
        //         .then(function () { });
        // } else {

        axios({
            method: "post",
            url: uAPIlocal + "/api/v1/master_result",
            data: f,
            headers: { 'content-type': 'multipart/form-data' }
        }, { signal: AbortSignal.timeout(10000) })
            .then((response) => {
                console.log("ini res", response);
                let data = response.data;

                if (data.status == 406) {
                    swalWithBootstrapButtons
                        .fire({
                            icon: "error",
                            title: data.message,
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        })
                } else if (data.status == 200) {
                    swalWithBootstrapButtons
                        .fire({
                            icon: "success",
                            title: data.message,
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        })
                        .then(function () {
                            // window.location.reload()
                        });


                    this.handleCloseModalAddResult();
                    this.locationReload();
                }



            })
            .catch((e) => {
                console.error(e);
            });


        // }

    }


    save_v4() {

        // ini save


        let f = new FormData();

        f.append('patientPID', this.state.choosePeserta?.value);
        f.append('patientConc', this.state.chooseConc);

        f.append('temuan_2016', this.state.temuan_2016);
        f.append('temuan_2017', this.state.temuan_2017);
        f.append('temuan_2018', this.state.temuan_2018);
        f.append('temuan_2019', this.state.temuan_2019);
        f.append('temuan_2020', this.state.temuan_2020);
        f.append('temuan_2021', this.state.temuan_2021);
        f.append('temuan_2022', this.state.temuan_2022);
        f.append('temuan_2023', this.state.temuan_2023);
        f.append('temuan_2024', this.state.temuan_2024);

        // f.append('company_id', localStorage.getItem("Company_ID"));


        // if (this.state.addPatientPeriode) {

        // }


        for (var pair of f.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // if (users.length == 0 && bagian.length == 0 && this.state._draft == 0) {
        //   swalWithBootstrapButtons
        //     .fire({
        //       icon: "error",
        //       title: "Pilih Setidaknya Satu Penerima/Bagian!",
        //       toast: true,
        //       position: "top-end",
        //       showConfirmButton: false,
        //       timer: 3000,
        //       timerProgressBar: true,
        //       didOpen: (toast) => {
        //         toast.addEventListener("mouseenter", Swal.stopTimer);
        //         toast.addEventListener("mouseleave", Swal.resumeTimer);
        //       },
        //     })
        //     .then(function () { });
        // } else {

        axios({
            method: "post",
            url: uAPIlocal + "/api/v1/master_temuan",
            data: f,
            headers: { 'content-type': 'multipart/form-data' }
        }, { signal: AbortSignal.timeout(10000) })
            .then((response) => {
                console.log("ini res", response);
                let data = response.data;

                if (data.status == 406) {
                    swalWithBootstrapButtons
                        .fire({
                            icon: "error",
                            title: data.message,
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        })
                } else if (data.status == 200) {
                    swalWithBootstrapButtons
                        .fire({
                            icon: "success",
                            title: data.message,
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        })
                        .then(function () {
                            // window.location.reload()
                        });
                    this.handleCloseModalAddTemuan();
                    this.locationReload();
                }



            })
            .catch((e) => {
                console.error(e);
            });


        // }

    }

    update_v2() {
        // ini save

        let f = new FormData();
        f.append('name', this.state.peserta_nama);
        f.append('nik', this.state.peserta_nik);
        f.append('pid', this.state.peserta_pid);
        f.append('dob', moment(this.state.peserta_dob, 'YYYY-MM-DD').format('DD/MM/YYYY'));
        f.append('gender', this.state.peserta_gender);
        f.append('department', this.state.chooseDept?.value);
        // f.append('company_id', localStorage.getItem("Company_ID"));

        f.append('mcuYear', this.state.peserta_mcuYear);
        f.append('patientAge', this.state.peserta_patientAge);
        f.append('patientDiv', this.state.peserta_patientDiv);
        f.append('patientLoc', this.state.peserta_patientLoc);
        f.append('patientFit', this.state.peserta_patientFit);
        f.append('patientResult', this.state.peserta_patientResult);
        f.append('patientAgeRange', this.state.peserta_patientAgeRange);
        f.append('patientRegNo', this.state.peserta_patientRegNo);
        f.append('patientPackageID', this.state.peserta_patientPackageID);
        f.append('patientRegNoAdd', this.state.peserta_patientRegNoAdd);


        for (var pair of f.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        if (this.state.peserta_nama == "" && !this.state.addPatientPeriode) {
            swalWithBootstrapButtons
                .fire({
                    icon: "error",
                    title: "Nama Peserta Harus Diisi!",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                })
                .then(function () { });
        } else if (this.state.peserta_pid == "" && !this.state.addPatientPeriode) {
            swalWithBootstrapButtons
                .fire({
                    icon: "error",
                    title: "PID Harus Diisi!",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                })
                .then(function () { });
        } else {

            axios({
                method: "put",
                url: uAPIlocal + "/api/v1/master_patient",
                data: f,
                headers: { 'content-type': 'multipart/form-data' }
            }, { signal: AbortSignal.timeout(10000) })
                .then((response) => {
                    console.log("ini res", response);
                    let data = response.data;

                    if (data.status == 406) {
                        swalWithBootstrapButtons
                            .fire({
                                icon: "error",
                                title: data.message,
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener("mouseenter", Swal.stopTimer);
                                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                                },
                            })
                    } else if (data.status == 200) {
                        swalWithBootstrapButtons
                            .fire({
                                icon: "success",
                                title: data.message,
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener("mouseenter", Swal.stopTimer);
                                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                                },
                            })
                            .then(function () {
                                // window.location.reload()
                            });


                        this.handleCloseModalForm();
                        this.locationReload();
                    }



                })
                .catch((e) => {
                    console.error(e);
                });

        }
    }

    update_v3() {
        let f = new FormData();

        f.append('patientPID', this.state.choosePeserta?.value);
        f.append('patientTest', this.state.chooseTest?.value);

        f.append('result_2016', this.state.result_2016);
        f.append('result_F2016', this.state.result_F2016);
        f.append('result_R2016', this.state.result_R2016);
        f.append('result_N2016', this.state.result_N2016);
        f.append('result_U2016', this.state.result_U2016);

        f.append('result_2017', this.state.result_2017);
        f.append('result_F2017', this.state.result_F2017);
        f.append('result_R2017', this.state.result_R2017);
        f.append('result_N2017', this.state.result_N2017);
        f.append('result_U2017', this.state.result_U2017);

        f.append('result_2018', this.state.result_2018);
        f.append('result_F2018', this.state.result_F2018);
        f.append('result_R2018', this.state.result_R2018);
        f.append('result_N2018', this.state.result_N2018);
        f.append('result_U2018', this.state.result_U2018);

        f.append('result_2019', this.state.result_2019);
        f.append('result_F2019', this.state.result_F2019);
        f.append('result_R2019', this.state.result_R2019);
        f.append('result_N2019', this.state.result_N2019);
        f.append('result_U2019', this.state.result_U2019);

        f.append('result_2020', this.state.result_2020);
        f.append('result_F2020', this.state.result_F2020);
        f.append('result_R2020', this.state.result_R2020);
        f.append('result_N2020', this.state.result_N2020);
        f.append('result_U2020', this.state.result_U2020);

        f.append('result_2021', this.state.result_2021);
        f.append('result_F2021', this.state.result_F2021);
        f.append('result_R2021', this.state.result_R2021);
        f.append('result_N2021', this.state.result_N2021);
        f.append('result_U2021', this.state.result_U2021);

        f.append('result_2022', this.state.result_2022);
        f.append('result_F2022', this.state.result_F2022);
        f.append('result_R2022', this.state.result_R2022);
        f.append('result_N2022', this.state.result_N2022);
        f.append('result_U2022', this.state.result_U2022);

        f.append('result_2023', this.state.result_2023);
        f.append('result_F2023', this.state.result_F2023);
        f.append('result_R2023', this.state.result_R2023);
        f.append('result_N2023', this.state.result_N2023);
        f.append('result_U2023', this.state.result_U2023);

        f.append('result_2024', this.state.result_2024);
        f.append('result_F2024', this.state.result_F2024);
        f.append('result_R2024', this.state.result_R2024);
        f.append('result_N2024', this.state.result_N2024);
        f.append('result_U2024', this.state.result_U2024);

        // f.append('company_id', localStorage.getItem("Company_ID"));



        for (var pair of f.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }



        axios({
            method: "put",
            url: uAPIlocal + "/api/v1/master_result",
            data: f,
            headers: { 'content-type': 'multipart/form-data' }
        }, { signal: AbortSignal.timeout(10000) })
            .then((response) => {
                console.log("ini res", response);
                let data = response.data;

                if (data.status == 406) {
                    swalWithBootstrapButtons
                        .fire({
                            icon: "error",
                            title: data.message,
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        })
                } else if (data.status == 200) {
                    swalWithBootstrapButtons
                        .fire({
                            icon: "success",
                            title: data.message,
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        })
                        .then(function () {
                            // window.location.reload()
                        });

                    this.handleCloseModalAddResult();
                    // this.locationReload();

                    this.fetchDataDetail(this.state.recentPesertaID);

                }



            })
            .catch((e) => {
                console.error(e);
            });

    }

    update_v4() {

        let f = new FormData();

        f.append('patientPID', this.state.choosePeserta?.value);
        f.append('patientConc', this.state.chooseConc);

        f.append('temuan_2016', this.state.temuan_2016);
        f.append('temuan_2017', this.state.temuan_2017);
        f.append('temuan_2018', this.state.temuan_2018);
        f.append('temuan_2019', this.state.temuan_2019);
        f.append('temuan_2020', this.state.temuan_2020);
        f.append('temuan_2021', this.state.temuan_2021);
        f.append('temuan_2022', this.state.temuan_2022);
        f.append('temuan_2023', this.state.temuan_2023);
        f.append('temuan_2024', this.state.temuan_2024);

        // f.append('company_id', localStorage.getItem("Company_ID"));


        for (var pair of f.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }



        axios({
            method: "put",
            url: uAPIlocal + "/api/v1/master_temuan",
            data: f,
            headers: { 'content-type': 'multipart/form-data' }
        }, { signal: AbortSignal.timeout(10000) })
            .then((response) => {
                console.log("ini res", response);
                let data = response.data;

                if (data.status == 406) {
                    swalWithBootstrapButtons
                        .fire({
                            icon: "error",
                            title: data.message,
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        })
                } else if (data.status == 200) {
                    swalWithBootstrapButtons
                        .fire({
                            icon: "success",
                            title: data.message,
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        })
                        .then(function () {
                            // window.location.reload()
                        });
                    this.handleCloseModalAddTemuan();
                    this.locationReload();
                }



            })
            .catch((e) => {
                console.error(e);
            });

    }


    update_v5() {

        let f = new FormData();

        // f.append('patientPID', this.state.choosePeserta?.value);
        f.append('patientPID', this.state.peserta_pid);
        f.append('patientFitUnfit', this.state.peserta_patientFitunfit);
        f.append('Reg_No', this.state.peserta_patientRegNo);
        // f.append('company_id', localStorage.getItem("Company_ID"));


        for (var pair of f.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }



        axios({
            method: "put",
            url: uAPIlocal + "/api/v1/master_fitunfit",
            data: f,
            headers: { 'content-type': 'multipart/form-data' }
        }, { signal: AbortSignal.timeout(10000) })
            .then((response) => {
                console.log("ini res", response);
                let data = response.data;

                if (data.status == 406) {
                    swalWithBootstrapButtons
                        .fire({
                            icon: "error",
                            title: data.message,
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        })
                } else if (data.status == 200) {
                    swalWithBootstrapButtons
                        .fire({
                            icon: "success",
                            title: data.message,
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        })
                        .then(function () {
                            // window.location.reload()
                        });
                    this.handleCloseModalForm();
                    this.locationReload();
                }



            })
            .catch((e) => {
                console.error(e);
            });

    }

    changeSelect = (e) => {
        console.log('change', e)
        this.setState({ chooseDept: e })
    }

    changeSelectPeserta = (e) => {
        console.log('change', e)
        this.setState({ choosePeserta: e })
    }

    changeSelectTest = (e) => {
        console.log('change', e)
        this.setState({ chooseTest: e })
    }

    changeSelectConc = (e) => {
        console.log('change conc', e)
        this.setState({ chooseConc: e })
    }

    handleSelect(key) {
        this.setState({ tabkey: key });
    }


    async getSelectYear() {
        await axios.post(uAPIlocal + '/api/v1/getselectyear',
            { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
            .then(function (response) {
                return response.data;
            })
            .then(response => {
                const res = response.results;
                console.log("year", res);

                if (res.length === 0) {
                    this.getSelectYear();
                } else {
                    res.map((item, index) => {
                        this.setState({ selectYear: [...this.state.selectYear, item.mcu_year] })
                    })

                    this.setState({
                        peserta_mcuYear: this.state.selectYear[0],
                    })

                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }


    async getSelectAgeRange() {
        await axios.post(uAPIlocal + '/api/v1/getselectagerange',
            { signal: AbortSignal.timeout(10000) }, { headers: { 'content-type': 'multipart/form-data' } })
            .then(function (response) {
                return response.data;
            })
            .then(response => {
                const res = response.results;

                if (res.length === 0) {
                    this.getSelectAgeRange();
                } else {
                    res.map((item, index) => {
                        if (item.Patient_Age_Range != null) {
                            this.setState({ selectAgeRange: [...this.state.selectAgeRange, item.Patient_Age_Range] })
                        }

                    })

                    this.setState({
                        peserta_patientAgeRange: this.state.selectAgeRange[0],
                    })

                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    deleteRowPatient(id, n) {
        this.setState({ _delSwalPatient: true, _editFormActive: false })
        Swal.fire({
            text: "Apakah Anda yakin menghapus " + n + "?",
            toast: true,
            showCancelButton: true,
            confirmButtonText: "Hapus",
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteDataPatient(id);
            } else {
                this.setState({ _delSwalPatient: false, temuanClicked: false, showModal: false })
                // this.setState({
                //     //   _swalDel: false,
                //     //   val_dailyreport: [],
                // });
            }
        });
    }

    deleteDataPatient(p, t) {
        let f = new FormData();
        f.append('patientPID', p);
        axios({
            method: "delete",
            url: uAPIlocal + "/api/v1/master_patient/",
            data: f,
            headers: {
                'content-type': 'multipart/form-data'
            }
        }, { signal: AbortSignal.timeout(10000) })
            .then((response) => {
                swalWithBootstrapButtons
                    .fire({
                        icon: "success",
                        title: "berhasil dihapus",
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                        },
                    })
                    .then(function () {
                        // location.reload()
                    });

                this.locationReload();


            })
            .catch((e) => {
                console.error(e);
            });
    };

    deleteRowResult(p, t, n) {
        Swal.fire({
            text: "Apakah Anda yakin menghapus " + n + "?",
            toast: true,
            showCancelButton: true,
            confirmButtonText: "Hapus",
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteDataResult(p, t);
            } else {
                // this.setState({
                //     //   _swalDel: false,
                //     //   val_dailyreport: [],
                // });
            }
        });
    }

    deleteDataResult(p, t) {
        let f = new FormData();
        f.append('patientPID', p);
        f.append('patientTest', t);

        axios({
            method: "delete",
            url: uAPIlocal + "/api/v1/master_result/",
            data: f,
            headers: {
                'content-type': 'multipart/form-data'
            }
        }, { signal: AbortSignal.timeout(10000) })
            .then((response) => {
                swalWithBootstrapButtons
                    .fire({
                        icon: "success",
                        title: "berhasil dihapus",
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                        },
                    })
                    .then(function () {

                    });
                this.fetchDataDetail(p)
                setTimeout(() => {
                    this.locationReload();
                }, 1000);


            })
            .catch((e) => {
                console.error(e);
            });
    };

    deleteRowTemuan(id, n, pid) {
        console.log('idtemuan', id)
        Swal.fire({
            text: "Apakah Anda yakin menghapus " + n + "?",
            toast: true,
            showCancelButton: true,
            confirmButtonText: "Hapus",
        }).then((result) => {
            this.deleteDataTemuan(id, pid);
        });
    }

    deleteDataTemuan(id, pid) {
        let f = new FormData();
        f.append('patientConc', id);
        axios({
            method: "delete",
            url: uAPIlocal + "/api/v1/master_temuan/",
            data: f,
            headers: {
                'content-type': 'multipart/form-data'
            }
        }, { signal: AbortSignal.timeout(10000) })
            .then((response) => {
                swalWithBootstrapButtons
                    .fire({
                        icon: "success",
                        title: "berhasil dihapus",
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 10,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                        },
                    })
                    .then(response => {

                    })

                this.temuanClickFetch(pid);
                this.locationReload();
                // setTimeout(() => {
                //     this.locationReload();
                // }, 1000);

            })
            .catch((e) => {
                console.error(e);
            });



    };


    locationReload() {

        this.setState({
            tableInbox: false,
        })

        this.getPatient();

        setTimeout(() => {
            this.setState({
                tableInbox: true
            })
        }, 1000);

    };

    //   batas fungsi

    render() {

        if (this.state.redirctTo) {
            Cookies.remove("mcu-login", { path: '/', domain: '.biomedika.co.id' })
        } else { }

        const { tableInbox } = this.state;
        const { SearchBar, ClearSearchButton } = Search;
        // console.log('this.props', this.props?.location?.state)


        const columnsDetail = [
            {
                dataField: "test",
                text: 'act',
                headerClasses: 'table-head-fixed',
                // formatter: this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '200px',
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '200px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
                },
                formatter: (cell, row, rowIndex) => {
                    const _edit = (event) => {
                        console.log("happyhappy", row);


                        let indexPeserta = this.state.optPeserta.findIndex(function (item, i) {
                            return item.value === row.patient_id
                        });

                        let indexTest = this.state.Test_Code.findIndex(function (item, i) {
                            return item.value === row.test_id
                        });


                        this.setState({
                            modalAddResult: true,
                            showModal: false,

                            result_2016: row['2016'] == "null" ? "" : row['2016'],
                            result_F2016: row.F2016 == "null" ? "" : row.F2016,
                            result_R2016: row.R2016 == "null" ? "" : row.R2016,
                            result_N2016: row.N2016 == "null" ? "" : row.N2016,
                            result_U2016: row.U2016 == "null" ? "" : row.U2016,
                            result_2017: row['2017'] == "null" ? "" : row['2017'],
                            result_F2017: row.F2017 == "null" ? "" : row.F2017,
                            result_R2017: row.R2017 == "null" ? "" : row.R2017,
                            result_N2017: row.N2017 == "null" ? "" : row.N2017,
                            result_U2017: row.U2017 == "null" ? "" : row.U2017,
                            result_2018: row['2018'] == "null" ? "" : row['2018'],
                            result_F2018: row.F2018 == "null" ? "" : row.F2018,
                            result_R2018: row.R2018 == "null" ? "" : row.R2018,
                            result_N2018: row.N2018 == "null" ? "" : row.N2018,
                            result_U2018: row.U2018 == "null" ? "" : row.U2018,
                            result_2019: row['2019'] == "null" ? "" : row['2019'],
                            result_F2019: row.F2019 == "null" ? "" : row.F2019,
                            result_R2019: row.R2019 == "null" ? "" : row.R2019,
                            result_N2019: row.N2019 == "null" ? "" : row.N2019,
                            result_U2019: row.U2019 == "null" ? "" : row.U2019,
                            result_2020: row['2020'] == "null" ? "" : row['2020'],
                            result_F2020: row.F2020 == "null" ? "" : row.F2020,
                            result_R2020: row.R2020 == "null" ? "" : row.R2020,
                            result_N2020: row.N2020 == "null" ? "" : row.N2020,
                            result_U2020: row.U2020 == "null" ? "" : row.U2020,
                            result_2021: row['2021'] == "null" ? "" : row['2021'],
                            result_F2021: row.F2021 == "null" ? "" : row.F2021,
                            result_R2021: row.R2021 == "null" ? "" : row.R2021,
                            result_N2021: row.N2021 == "null" ? "" : row.N2021,
                            result_U2021: row.U2021 == "null" ? "" : row.U2021,
                            result_2022: row['2022'] == "null" ? "" : row['2022'],
                            result_F2022: row.F2022 == "null" ? "" : row.F2022,
                            result_R2022: row.R2022 == "null" ? "" : row.R2022,
                            result_N2022: row.N2022 == "null" ? "" : row.N2022,
                            result_U2022: row.U2022 == "null" ? "" : row.U2022,
                            result_2023: row['2023'] == "null" ? "" : row['2023'],
                            result_F2023: row.F2023 == "null" ? "" : row.F2023,
                            result_R2023: row.R2023 == "null" ? "" : row.R2023,
                            result_N2023: row.N2023 == "null" ? "" : row.N2023,
                            result_U2023: row.U2023 == "null" ? "" : row.U2023,
                            result_2024: row['2024'] == "null" ? "" : row['2024'],
                            result_F2024: row.F2024 == "null" ? "" : row.F2024,
                            result_R2024: row.R2024 == "null" ? "" : row.R2024,
                            result_N2024: row.N2024 == "null" ? "" : row.N2024,
                            result_U2024: row.U2024 == "null" ? "" : row.U2024,

                            updateBtnV3: true,

                            choosePeserta: this.state.optPeserta[indexPeserta],
                            chooseTest: this.state.Test_Code[indexTest],
                        })
                    };

                    const _delete = (event) => {
                        // alert(row.id);
                        console.log("happyhappy", row);
                        this.deleteRowResult(row.patient_id, row.test_id, row.test);

                    };

                    return (
                        <ul className="list-inline m-0 d-flex justify-content-center">
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-success btn-sm rounded-0"
                                    type="button"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Edit"
                                    onClick={_edit}
                                >
                                    <i className="fa fa-edit" />
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-danger btn-sm rounded-0"
                                    type="button"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Delete"
                                    onClick={_delete}
                                >
                                    <i className="fa fa-trash" />
                                </button>
                            </li>
                        </ul>
                    );
                },

            },
            {
                dataField: "test",
                text: 'Test Name',
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '200px',
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '200px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
                },

            },
            {
                dataField: "reference",
                text: "Reference Value",
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '100px',
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '100px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "units",
                text: "Units",
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '80px',
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "status",
                text: "Status",
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.redValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'bold',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '80px',
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "2024",
                text: "2024",
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.state._year_selected == "2024" ? this.redValueFormatter : this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '80px',
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "2023",
                text: "2023",
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.state._year_selected == "2023" ? this.redValueFormatter : this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '80px',
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "2022",
                text: "2022",
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.state._year_selected == "2022" ? this.redValueFormatter : this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '80px',
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "2021",
                text: "2021",
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.state._year_selected == "2021" ? this.redValueFormatter : this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '80px',
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "2019",
                text: "2019",
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.state._year_selected == "2019" ? this.redValueFormatter : this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '80px',
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "2018",
                text: "2018",
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.state._year_selected == "2018" ? this.redValueFormatter : this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '80px',
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            // {
            //     dataField: "2017",
            //     text: "2017",
            //     sort: true,
            //     headerClasses: 'table-head-fixed',
            //     formatter: this.state._year_selected == "2017" ? this.redValueFormatter : this.emptyValueFormatter,
            //     style: {
            //         // height: '75px',
            //         fontWeight: 'normal',
            //         margin: '2px auto',
            //         fontSize: '12px',
            //         textAlign: 'center',
            //         minWidth: '80px',
            //     },
            //     headerStyle: (colum, colIndex) => {
            //         return { width: '80px', fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#fff" };
            //     },
            // }
        ]

        const columnsDetailTemuan = [
            {
                dataField: "Conc_Name",
                text: 'act',
                headerClasses: 'table-head-fixed',
                style: {
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                },
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },
                formatter: (cell, row, rowIndex) => {
                    const _edit = (event) => {

                        console.log("happyhappy", row);


                        let indexPeserta = this.state.optPeserta.findIndex(function (item, i) {
                            return item.value === row.Patient_ID
                        });

                        let indexConc = this.state.Conc_Code.findIndex(function (item, i) {
                            return item.value === row.Conc_ID
                        });

                        this.setState({
                            modalAddTemuan: true,
                            showModal: false,
                            loading: true,
                            temuan_2016: "",
                            temuan_2017: "",
                            temuan_2018: "",
                            temuan_2019: "",
                            temuan_2020: "",
                            temuan_2021: "",
                            temuan_2022: "",
                            temuan_2023: "",
                            temuan_2024: "",

                            updateBtnV4: true,

                            choosePeserta: this.state.optPeserta[indexPeserta],
                            chooseConc: this.state.Conc_Code[indexConc],
                        })


                    };

                    const _delete = (event) => {
                        // alert(row.id);
                        console.log("happyhappy", row);
                        this.deleteRowTemuan(row.temuan_id, row.Conc_Name, row.Patient_id);
                    };

                    return (
                        <ul className="list-inline m-0 d-flex justify-content-center">
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-success btn-sm rounded-0"
                                    type="button"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Edit"
                                    onClick={_edit}
                                >
                                    <i className="fa fa-edit" />
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-danger btn-sm rounded-0"
                                    type="button"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Delete"
                                    onClick={_delete}
                                >
                                    <i className="fa fa-trash" />
                                </button>
                            </li>
                        </ul>
                    );
                },

            },
            {
                dataField: "Conc_Name",
                text: 'Temuan',
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                },
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },

            },
            {
                dataField: "Test_Name",
                text: 'Nama Test',
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                },
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },

            },
            {
                dataField: "N" + this.state._year_selected,
                text: 'Nilai Normal ' + this.state._year_selected,
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                },
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },

            },
            {
                dataField: "hasilTemuan",
                text: 'Nilai Test ' + this.state._year_selected,
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                },
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },

            },
            {
                dataField: "F" + this.state._year_selected,
                text: 'Status ' + this.state._year_selected,
                sort: true,
                headerClasses: 'table-head-fixed',
                formatter: this.emptyValueFormatter,
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                    textAlign: 'center',
                },
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },

            },
        ]


        const columns = [
            // {
            //   dataField: "id",
            //   text: "act",
            //   formatter: this.actFormatter,
            //   style: {
            //     width: "100px",
            //   },
            // },
            {
                dataField: "id",
                text: "",
                formatter: this.imgFormatter,
                style: {
                    width: "20px",
                    textAlign: 'center',
                    // minWidth: '2%',

                },
                headerAttrs: {
                    hidden: false
                },
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '20px', textAlign: 'center', color: "#fff" };
                },
                formatter: (cell, row, rowIndex) => {
                    const _edit = (event) => {
                        console.log("happyhappy", row);

                        let indexMcuYear = this.state.selectYear.findIndex(function (item, i) {
                            return item === row.Mcu_Year
                        });

                        let indexDept = this.state.optDept.findIndex(function (item, i) {
                            return item.value === row.Patient_Dept
                        });

                        let indexAge = this.state.selectAgeRange.findIndex(function (item, i) {
                            return item === row.Patient_Age_Range
                        });

                        this.setState({
                            modalForm: true,
                            addPatientPeriode: false,

                            peserta_nama: row.Patient_name,
                            peserta_nik: row.Patient_NIK,
                            peserta_pid: row.Patient_id,
                            peserta_dob: moment(row.Patient_DOB, 'DD-MM-YYYY').format("YYYY-MM-DD"),
                            peserta_gender: row.Patient_Gender,
                            peserta_mcuYear: this.state.selectYear[indexMcuYear],
                            peserta_patientAge: row.Patient_Age,
                            peserta_patientDiv: row.Patient_Div,
                            peserta_patientLoc: row.Patient_Loc,
                            peserta_patientFit: row.Patient_Fit,
                            peserta_patientResult: row.Patient_Result,
                            peserta_patientFitunfit: row.fu2023,
                            peserta_patientAgeRange: this.state.selectAgeRange[indexAge],
                            peserta_patientRegNo: row.Reg_No,
                            peserta_patientPackageID: row.Package_ID,
                            peserta_patientRegNoAdd: row.Reg_No_Add,
                            tabkey: 1,

                            _editFormActive: true,
                            updateBtnV2: true,

                            chooseDept: this.state.optDept[indexDept],
                        })
                    };

                    const _delete = (event) => {
                        // alert(row.id);
                        console.log("happyhappy", row);
                        this.deleteRowPatient(row.Patient_id, row.Patient_name);
                    };

                    return (
                        <ul className="list-inline m-0 d-flex justify-content-center">
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-success btn-sm rounded-0"
                                    type="button"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Edit"
                                    onClick={_edit}
                                >
                                    <i className="fa fa-edit" />
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-danger btn-sm rounded-0"
                                    type="button"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Delete"
                                    onClick={_delete}
                                >
                                    <i className="fa fa-trash" />
                                </button>
                            </li>
                        </ul>
                    );
                },
            },

            {
                dataField: "id",
                text: "",
                formatter: this.imgFormatter,
                style: {
                    width: "20px",
                    textAlign: 'center',
                    minWidth: '2%',

                },
                headerAttrs: {
                    hidden: false
                },
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '20px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "Patient_name",
                style: {
                    // height: '75px',
                    fontWeight: 'normal',
                    margin: '2px auto',
                    fontSize: '12px',
                },
                text: "Nama Lengkap",
                sort: true,
                formatter: this.handleRowsClick,
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "Patient_NIK",
                style: {
                    fontSize: '12px',
                    textAlign: 'center',
                },
                text: "NIK",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "Patient_id",
                style: {
                    fontSize: '12px',
                    textAlign: 'center',
                },
                text: "PID",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "Reg_No",
                style: {
                    fontSize: '12px',
                    textAlign: 'center',
                },
                text: "Reg No",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "Patient_DOB",
                style: {
                    fontSize: '12px',
                    textAlign: 'center',
                },
                text: "Tanggal Lahir",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            // {
            //   dataField: "Patient_DOB",
            //   formatter: (cell, row, rowIndex, formatExtraData) => {
            //     console.log(row.Patient_DOB);
            //     var from = row.Patient_DOB.split("/");
            //     var birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
            //     var cur = new Date();
            //     var diff = cur - birthdateTimeStamp;
            //     // This is the difference in milliseconds
            //     var currentAge = Math.floor(diff / 31557600000);
            //     return currentAge;
            //   },
            //   text: "AGE",
            //   sort: true,
            // },
            {
                dataField: "Patient_Gender",
                style: {
                    fontSize: '12px',
                    textAlign: 'center',
                },
                text: "Jenis Kelamin",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "Patient_Dept",
                style: {
                    fontSize: '12px',
                    textAlign: 'center',
                },
                text: "Departemen",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            },
            {
                dataField: "temuan",
                style: {
                    fontSize: '12px',
                    textAlign: 'center',
                },
                text: "Temuan",
                sort: true,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        e.preventDefault();
                        // console.log('temuan klik', e)
                        // console.log('temuan klik', column)
                        // console.log('temuan klik', columnIndex)
                        // console.log('temuan klik', row)
                        // console.log('temuan klik', rowIndex)
                        this.setTemuanState();
                        this.temuanClick(row);
                    },
                },
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },
                formatter: (cell, row, rowIndex) => {
                    return (
                        <div className={`${cell > 0 ? "text-danger" : "text-dark"}`}>
                            {cell}
                        </div>
                    )
                },


            },
            {
                dataField: "fu2023",
                style: {
                    fontSize: '12px',
                    textAlign: 'center',
                },
                text: "Fit Unfit",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', width: '60px', height: '10px', textAlign: 'center', color: "#fff" };
                },
            }
        ];


        const pageButtonRenderer = ({
            page,
            active,
            disable,
            title,
            onPageChange
        }) => {
            const handleClick = (e) => {
                e.preventDefault();
                onPageChange(page);
            };

            console.log("pageACTIVEX", title)
            if (title != "Pre page") {
                if (title != "First page")
                    if (active) {
                        return (
                            <li className="paginationSectionList">
                                <a href="#" className="paginationSectionLinkActive" onClick={handleClick} >{page}</a>
                            </li>
                        );
                    } else {

                        return (
                            <li className="paginationSectionList">
                                <a href="#" className="paginationSectionLink" onClick={handleClick} >{page}</a>
                            </li>
                        );

                    }

            }

        };




        const options = {
            pageButtonRenderer,
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: "First",
            prePageText: "Back",
            nextPageText: "Next",
            lastPageText: "Last",
            nextPageTitle: "First page",
            prePageTitle: "Pre page",
            firstPageTitle: "Next page",
            lastPageTitle: "Last page",
            withFirstAndLast: false,
            alwaysShowAllBtns: false,
            showTotal: true,
            totalSize: this.state.datas.length,
            page: this.state.lastPageNumber

            // hidePageListOnlyOnePage:
        };

        const tableRowEvents = {
            onClick: (e, row, rowIndex) => {
                // console.log(e.detail, "apa isi e?");
                // console.log(row, "ini apa?")
                // console.log('temuanClicked', this.state.temuanClicked)
                switch (e.detail) {
                    case 1:
                        console.log("click");
                        handleShow(row)
                        handleRowClick(rowIndex)

                        break;
                    case 2:
                        console.log("double click");
                        break;
                    case 3:
                        console.log("triple click");
                        break;
                }
            },
            onMouseEnter: (e, row, rowIndex) => {
                // console.log('onMouseEnter', e);
                // console.log('onMouseEnter', row);
                // console.log('onMouseEnter', rowIndex);
            },
        };

        const handleRowClick = (row) => {
            // Memperbarui state untuk menandai baris yang dipilih
            // console.log(row, "isSelected0 test")

            this.setState({ selectedRows: row });

            // let isSelected = row === this.state.selectedRows;

            // console.log(this.state.selectedRows, "isSelectedROws")
        };

        const formattedAge = (age) => {
            var from = age.split("/");
            var birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
            var cur = new Date();
            var diff = cur - birthdateTimeStamp;
            // This is the difference in milliseconds
            var currentAge = Math.floor(diff / 31557600000);
            return currentAge + " " + "Tahun";
        }

        const handleShow = (data) => {
            var id_fromOnClick = data.Patient_id
            // alert(id_fromOnClick);
            // "LAST EDITTTTTTTTTTTTTTTTTTT"
            this.fetchDataDetail(id_fromOnClick);
            this.setState({
                recentPesertaID: data.Patient_id
            })


        }

        const rowClasses = () => {
            return 'table-custom-width'; // Gunakan class CSS 'row-height' pada baris genap
        };


        const updateInput = (event) => {
            // console.log(props, "props")
            var val = event.target.value
            // console.log(props.onSearch(event.target.value), "coba")
            console.log(val, "val")
            this.setState({
                searchValue: event.target.value
            })

            // var result = []
            // var temp = this.state.datas
            // console.log(temp, "temp")
            // var filter = temp.filter(e => e.Patient_name === val)
            // // result.push(filter)
            // this.setState({
            //   datas: filter
            // })
            // console.log(filter, "filter")
            // e.onSearch() 
        }
        const ModalDetail = ({ paginationProps, paginationTableProps, isTemuan }) => {
            // console.log('isTemuan', isTemuan)
            // console.log('this.state.dataDetail', this.state.dataDetail)
            return (

                <div className="position-absolute" style={{
                    top: "102px",
                    zIndex: 6,
                }} ref={this.modalRef} id="slide" >
                    {/* {console.log(this.state.dataDetail, "ini apaa ya?")} */}
                    <ToolkitProvider keyField="id" columns={isTemuan ? columnsDetailTemuan : columnsDetail} data={isTemuan ? this.state.dataDetailTemuan : this.state.dataDetail} search>
                        {(toolkitprops) => (
                            <div className="shadow rounded zindex-fixed overflow-scroll mr-3 peopleSubColumn1" style={{ backgroundColor: '#EFEFEF' }}   >

                                <button className="btnCloseTable" onClick={this.handleCloseModalDetail()}></button>

                                <div className="peopleSubColumn2 text-center bg-darkGreen rounded-top h-[50]" style={{ position: 'fixed' }}>
                                    <div className="row justify-content-center ">
                                        <div className="col-6">
                                            <div className="d-flex flex-row gap-4">
                                                <div className="flex-column text-start" style={{ color: "#EFEFEF" }}>
                                                    <div className="peopleSubColumn2Textheader1">Nama</div>
                                                    <div className="peopleSubColumn2Textheader1">NIK </div>
                                                    <div className="peopleSubColumn2Textheader1">PID </div>
                                                </div>
                                                <div className="flex-column text-start" style={{ color: "#EFEFEF" }}>
                                                    {this.state.detailPatient.map((e) => {
                                                        return (
                                                            <>
                                                                <div className="peopleSubColumn2Textheader1" >: {e.Patient_name}</div>
                                                                <div className="peopleSubColumn2Textheader1" >: {e.Patient_NIK}</div>
                                                                <div className="peopleSubColumn2Textheader1" >: {e.Patient_id}</div>
                                                            </>
                                                        )

                                                    })}

                                                </div>
                                            </div>
                                            {/* One of two columns */}
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex flex-row gap-4">
                                                <div className="flex-column text-start" style={{ color: "#EFEFEF" }}>
                                                    <div className="peopleSubColumn2Textheader1">Umur</div>
                                                    <div className="peopleSubColumn2Textheader1">Gender </div>
                                                    <div className="peopleSubColumn2Textheader1">Departemen </div>
                                                </div>
                                                <div className="flex-column text-start" style={{ color: "#EFEFEF" }}>
                                                    {this.state.detailPatient.map((e) => {
                                                        return (
                                                            <>
                                                                <div className="peopleSubColumn2Textheader1" >: {formattedAge(e.Patient_DOB)}</div>
                                                                <div className="peopleSubColumn2Textheader1" >: {e.Patient_Gender}</div>
                                                                <div className="peopleSubColumn2Textheader1" >: {e.Patient_Dept}</div>
                                                            </>
                                                        )

                                                    })}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <BootstrapTable
                                    // striped
                                    bordered={false}
                                    hover
                                    // selectRow={selectRow}
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    // rowEvents={tableRowEvents}
                                    // pagination={ paginationFactory() }
                                    rowClasses={rowClasses}
                                // classes="borderless"

                                />


                                <PaginationListStandalone
                                    style={{ float: "right" }}
                                    {...paginationProps}
                                />

                            </div>
                        )}
                    </ToolkitProvider>


                    {/* <Modal show={this.state.showModal} onHide={handleClose}>
              <ToolkitProvider keyField="id" columns={columnsDetail} data={dummyDetail} search>
                {(toolkitprops) => (
                  <div>
                    <BootstrapTable

                      striped
                      bordered={false}
                      hover
                      // selectRow={selectRow}
                      {...toolkitprops.baseProps}
                      {...paginationTableProps}
                      rowEvents={tableRowEvents}
                    // pagination={ paginationFactory() }
                    />

                    <PaginationListStandalone
                      style={{ float: "right" }}
                      {...paginationProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Modal> */}


                </div>
            )
        }

        const MySearch = (props) => {
            // console.log(props, "propss")
            let input;
            const handleClick = (e) => {
                // console.log(input, "input isis")
                // console.log(e.target.value, "value input")
                // console.log(this.state.searchValue, "search value from mysearch")
                props.onSearch(this.input.value);
                // this.setState({ searchWord: e.target.value })
                // console.log(input.value, "")
            };

            const handleClear = (e) => {
                // console.log(input, "input isis")
                // console.log(e.target.value, "value input")
                // console.log(this.state.searchValue, "search value from mysearch")
                props.onSearch("");
                this.input.value = "";
            };

            return (
                <>
                    <InputGroup className="mb-3">
                        {/* <input
              className="form-control border-right-0 border"
              style={{ backgroundColor: "white", padding: 0 }}
              ref={(n) => (input = n)}
              type="text"
            />
            <button
              className="btn btn-tropical-rain-forest"
              onClick={handleClick}
            >
              Search
            </button> */}
                        {/* <input ref={(n) => (console.log(n, "n value"))} onChange={handleClick} type="text" className="search-query form-control" value={this.props.location.state ? this.props?.location?.state?.Patient_ID : ""} placeholder="   Search" /> */}


                        <div className="input-group" style={{ width: '100%' }}>
                            <input
                                ref={myinput => (this.input = myinput)}
                                onChange={handleClick}
                                type="text" style={{ borderRadius: '50px' }} className="search-query form-control" value={this.state.searchValue} placeholder="   Search" />
                            {/* <span className="input-group-btn">
                    <button type="button" disabled>
                      <span className="fa fa-search"></span>
                    </button>
                  </span> */}

                            <span className="clr_child" style={{ display: this.state.clr_child ? null : 'none' }} >
                                <button type="button" className="clr_img_btn" onClick={handleClear}>
                                    {/* <span className="fa fa-search"></span> */}
                                </button>
                            </span>
                        </div>



                    </InputGroup>
                </>
            );
        };



        const contentTableInbox = ({ paginationProps, paginationTableProps }) => {
            // console.log('contentTableInbox', this.state.temuanClicked)
            return (
                <div>
                    <ToolkitProvider keyField="id" columns={columns} data={this.state.datas} search={{
                        defaultSearch: this.state.searchValue ? this.state.searchValue : ''
                    }}>
                        {(toolkitprops) => (
                            <div>



                                <div className="row justify-content-end" >
                                    {/* <div className="col-6">
                                        <p>Hi</p>
                                    </div> */}


                                    <Col className="">
                                        <ButtonGroup>
                                            <button className="btn btn-primary btn-sm" onClick={() => this.setState({ modalForm: true, addPatientPeriode: false, tabkey: 1 })}>
                                                <i className="fa fa-plus" /> Add Patient
                                            </button>

                                            <button className="btn btn-primary btn-sm" onClick={() => this.setState({ modalForm: true, addPatientPeriode: true, tabkey: 2 })}>
                                                <i className="fa fa-plus" /> Add Period
                                            </button>
                                            <button className="btn btn-primary btn-sm" onClick={() => this.setState({ modalAddResult: true })}>
                                                <i className="fa fa-plus" /> Add Result
                                            </button>
                                            <button className="btn btn-primary btn-sm" onClick={() => this.setState({ modalAddTemuan: true })}>
                                                <i className="fa fa-plus" /> Add Temuan
                                            </button>
                                            {/* <button className="btn btn-primary btn-sm"
                                            // onClick={this.deleteRow.bind(this)}
                                            >
                                                <i className="fa fa-trash" /> Hapus
                                            </button> */}


                                            <DropdownButton
                                                // align="start"
                                                id="dropdown-menu-align-end"
                                                onSelect={this.setYearAll}
                                                // style={{ float: "right", marginBottom: "10px", marginRight: '20px', marginTop: '10px' }}
                                                title={
                                                    <span>
                                                        {this.state._yAll} <img alt='img1' src={dropdownIcon} style={{ width: '12px' }} />
                                                    </span>
                                                }

                                            >
                                                {this.state.selectYearTable.map((item, index) => {
                                                    return (
                                                        <Dropdown.Item key={index} className={item === this.state._yAll ? 'dropdown-item-checked' : null} active={item === this.state._yAll ? true : false} eventKey={item}>
                                                            {item}
                                                        </Dropdown.Item>
                                                    );
                                                })}
                                            </DropdownButton>


                                        </ButtonGroup>








                                    </Col>


                                    <Col>
                                        <MySearch  {...toolkitprops.searchProps} />
                                    </Col>

                                </div>



                                <Row className="justify-content-end">




                                </Row>

                                <div className="peopleColumn" style={{ overflowY: 'scroll' }}>
                                    {!this.state.temuanClicked && this.state.showModal && !this.state._delSwalPatient && !this.state._editFormActive && <ModalDetail />}
                                    {this.state.temuanClicked && this.state.showModal && !this.state._delSwalPatient && !this.state._editFormActive && <ModalDetail isTemuan />}
                                    {/* {console.log(paginationProps, "ini pagination props")}
                {console.log(paginationTableProps, "ini pagination table props")} */}

                                    {console.log(paginationProps, "ini pagination props")}
                                    {console.log(paginationTableProps, "ini pagination table props")}


                                    <BootstrapTable
                                        striped
                                        bordered={false}
                                        hover
                                        // selectRow={selectRow}
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        rowEvents={tableRowEvents}
                                        wrapperClasses="table-responsive"



                                    // pagination={ paginationFactory() }
                                    />
                                </div>


                                <div className="paginationSection">
                                    {paginationProps.page == 1 ? <button disabled="true" className="paginationSectionButtonPrev" onClick={this.handlePrevPage(paginationProps)}></button> : (<button className="paginationSectionButtonPrev" onClick={this.handlePrevPage(paginationProps)}></button>)}
                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />

                                    {paginationProps.page != this.state._totalPage ? (
                                        <>
                                            <ul class="pagination react-bootstrap-table-page-btns-ul">
                                                <li class="paginationSectionList">
                                                    <a href="#" class="paginationSectionLink">...</a>
                                                </li>
                                            </ul>
                                            <ul class="pagination react-bootstrap-table-page-btns-ul">
                                                <li class="paginationSectionList">
                                                    <a onClick={this.handleLastPage(paginationProps)} href="#" class="paginationSectionLink">{this.state._totalPage}</a>
                                                </li>
                                            </ul></>) : null}

                                    {paginationProps.page == this.state._totalPage ? <button disabled="true" className="paginationSectionButtonNext" onClick={this.handleNextPage(paginationProps)}></button> : <button className="paginationSectionButtonNext" onClick={this.handleNextPage(paginationProps)}></button>}
                                </div>


                            </div>
                        )}
                    </ToolkitProvider>
                </div >
            );
        }

        const selectRow = {
            mode: "checkbox",
            style: { backgroundColor: "#c8e6c9" },
            selectColumnStyle: {
                width: 20,
            },
            selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
                <input
                    type="checkbox"
                    ref={(input) => {
                        if (input) input.indeterminate = indeterminate;
                    }}
                    {...rest}
                />
            ),
            selectionRenderer: ({ mode, ...rest }) => (
                <input
                    // style={{width:20}}
                    type={mode}
                    {...rest}
                />
            ),
        };

        // console.log('this.state.temuanClicked', this.state.temuanClicked)

        // back
        if (this.state.redirect === true && this.state.action === true) {
            return (<Redirect to={{
                pathname: this.state.pathname,
                state: {
                    id: this.state.idSelected,
                    prevPage: this.state.prevPage,
                    prevState: this.state.prevState,
                    goTo: this.state.goTo,
                }
            }} />)
        }

        return (
            <>

                {/* <Modal
                    show={this.state.modalForm}
                    onHide={this.handleCloseModalForm.bind(this)}
                    backdrop="static"
                    dialogClassName="custom-modal"
                    keyboard
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>ADD PATIENT</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form action="#" encType="multipart/form-data" id="form_add">
                            <input type="hidden" name="id_dailyreports" defaultValue />
                            <div className="form-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Name</label>
                                            <input
                                                type="text"
                                                name="peserta_nama"
                                                className="form-control"
                                                required
                                                value={this.state.peserta_nama}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">NIK</label>
                                            <input
                                                type="text"
                                                name="peserta_nik"
                                                className="form-control"
                                                required
                                                value={this.state.peserta_nik}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">PID</label>
                                            <input
                                                type="text"
                                                name="peserta_pid"
                                                className="form-control"
                                                required
                                                value={this.state.peserta_pid}
                                                onChange={this.onChange}
                                            />
                                        </div>




                                    </div>
                                    <div className="col-md-6">


                                    <div className="form-group">
                                            <label className="form-label">DOB</label>
                                            <input
                                                type="date"
                                                name="peserta_dob"
                                                defaultValue={new Date().toISOString().slice(0, 10)}
                                                className="form-control"
                                                value={moment(new Date(this.state.peserta_dob)).format("YYYY-MM-DD")}
                                                onChange={this.onChange}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label">Gender</label>
                                            <select
                                                name="peserta_gender"
                                                className="form-control"
                                                onChange={this.onChange}
                                                value={this.state.peserta_gender}
                                            >
                                                <option value="M">Laki - Laki</option>
                                                <option value="F">Perempuan</option>
                                            </select>
                                        </div>


                                        <div className="form-group">
                                            <label className="form-label">Department</label>
                                            <Select
                                                className='title'
                                                value={this.state.chooseDept}
                                                isSearchable={true}
                                                name="dept"
                                                options={this.state.optDept}
                                                placeholder="Pilih Departemen"
                                                onChange={this.changeSelect}

                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? 'grey' : '#7E7E7E',
                                                        color: "#000",
                                                        backgroundColor: 'rgba(231,230,227,255)',
                                                        borderRadius: '10px',
                                                        // width: "140px",
                                                        fontSize: '12px',
                                                    }),
                                                    option: provided => ({
                                                        ...provided,
                                                        fontSize: '12px',
                                                        zIndex: 99,
                                                    }),
                                                    dropdownIndicator: styles => ({
                                                        ...styles,
                                                        color: '#000',
                                                    }),
                                                    indicatorsContainer: (base) => ({
                                                        ...base,
                                                        minHeight: 1,
                                                    }),
                                                    indicatorSeparator: () => null,
                                                }}
                                            />
                                        </div>

                                        <div className="d-flex flex-column  align-items-center">
                                            <div className="d-grid gap-2">
                                                <Button
                                                    className="button-block btn-compose"
                                                    size="lg"
                                                    // onClick={() => this.setState({ modalForm: true })}
                                                    onClick={this.save_v2.bind(this)}
                                                >
                                                    <span>Send</span>
                                                </Button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <input
                                hidden="true"
                                name="dailyreports[status]"
                                defaultValue="Open"
                                type="radio"
                                defaultChecked
                                id="status_open"
                            />
                        </form>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: "0px solid #ffffff" }}>
                        
                    </Modal.Footer>
                </Modal> */}

                <Modal
                    show={this.state.modalForm}
                    onHide={this.handleCloseModalForm.bind(this)}
                    backdrop="static"
                    dialogClassName="custom-modal"
                    keyboard
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>ADD Pasien</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tab.Container id="left-tabs-example" activeKey={this.state.tabkey}>
                            <Row>
                                <Col md={12}>
                                    <Nav
                                        variant="pills"
                                    // defaultActiveKey="/home"
                                    // style={{ marginLeft: 8, marginRight: 8 }}
                                    >
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey={1}
                                                onClick={() => this.handleSelect(1)}
                                                style={{ display: this.state.addPatientPeriode ? 'none' : null }}
                                            >
                                                Data Pasien
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey={2}
                                                onClick={() => this.handleSelect(2)}
                                            >
                                                Pasien Period
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey={3}
                                                onClick={() => this.handleSelect(3)}
                                            >
                                                Fit Unfit
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col>
                                    <Tab.Content style={{ marginTop: 20 }}>
                                        <Tab.Pane eventKey={1} >
                                            <form action="#" encType="multipart/form-data" id="form_add">
                                                <div className="form-content">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Name</label>
                                                                <input
                                                                    type="text"
                                                                    name="peserta_nama"
                                                                    className="form-control"
                                                                    required
                                                                    value={this.state.peserta_nama}
                                                                    onChange={this.onChange}
                                                                    placeholder="Nama Peserta"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="form-label">NIK</label>
                                                                <input
                                                                    type="text"
                                                                    name="peserta_nik"
                                                                    className="form-control"
                                                                    required
                                                                    value={this.state.peserta_nik}
                                                                    onChange={this.onChange}
                                                                // placeholder="1211166"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="form-label">PID</label>
                                                                <input
                                                                    type="text"
                                                                    name="peserta_pid"
                                                                    className="form-control"
                                                                    required
                                                                    value={this.state.peserta_pid}
                                                                    onChange={this.onChange}
                                                                    readOnly={this.state.updateBtnV2 ? true : null}
                                                                // placeholder="123456"
                                                                />
                                                            </div>


                                                        </div>
                                                        <div className="col-md-6">

                                                            <div className="form-group">
                                                                <label className="form-label">Department</label>
                                                                <Select
                                                                    className='title'
                                                                    value={this.state.chooseDept}
                                                                    isSearchable={true}
                                                                    name="dept"
                                                                    options={this.state.optDept}
                                                                    placeholder="Pilih Departemen"
                                                                    onChange={this.changeSelect}
                                                                    menuPosition={'fixed'}

                                                                    styles={{
                                                                        menu: provided => ({ ...provided, zIndex: 9999 }),
                                                                        control: (baseStyles, state) => ({
                                                                            ...baseStyles,
                                                                            borderColor: state.isFocused ? 'grey' : '#7E7E7E',
                                                                            color: "#000",
                                                                            // backgroundColor: 'rgba(231,230,227,255)',
                                                                            borderRadius: '10px',
                                                                            // width: "140px",
                                                                            fontSize: '12px',
                                                                        }),
                                                                        option: provided => ({
                                                                            ...provided,
                                                                            fontSize: '12px',
                                                                            zIndex: 999,
                                                                        }),
                                                                        dropdownIndicator: styles => ({
                                                                            ...styles,
                                                                            color: '#000',
                                                                        }),
                                                                        indicatorsContainer: (base) => ({
                                                                            ...base,
                                                                            minHeight: 1,
                                                                        }),
                                                                        indicatorSeparator: () => null,
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="form-label">DOB</label>
                                                                <input
                                                                    type="date"
                                                                    name="peserta_dob"
                                                                    defaultValue={new Date().toISOString().slice(0, 10)}
                                                                    className="form-control"
                                                                    value={moment(new Date(this.state.peserta_dob)).format("YYYY-MM-DD")}
                                                                    onChange={this.onChange}
                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="form-label">Gender</label>
                                                                <select
                                                                    name="peserta_gender"
                                                                    className="form-control"
                                                                    onChange={this.onChange}
                                                                    value={this.state.peserta_gender}
                                                                >
                                                                    <option value="M">Laki - Laki</option>
                                                                    <option value="F">Perempuan</option>
                                                                </select>
                                                            </div>






                                                            {/* <div className="d-flex flex-column  align-items-center">
                                                                <div className="d-grid gap-2">
                                                                    <Button
                                                                        className="button-block btn-compose"
                                                                        size="lg"
                                                                        // onClick={() => this.setState({ modalForm: true })}
                                                                        onClick={this.save_v2.bind(this)}
                                                                    >
                                                                        <span>Send</span>
                                                                    </Button>
                                                                </div>
                                                            </div> */}

                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={2}>

                                            <form action="#" encType="multipart/form-data" id="form_add">
                                                <div className="form-content">
                                                    <div className="row">
                                                        <div className="col-md-6">


                                                            <div className="form-group" style={{ display: this.state.addPatientPeriode ? null : 'none' }}>
                                                                <label className="form-label">Peserta</label>
                                                                <Select
                                                                    className='title'
                                                                    value={this.state.choosePeserta}
                                                                    isSearchable={true}
                                                                    name="dept"
                                                                    options={this.state.optPeserta}
                                                                    placeholder="Pilih Peserta"
                                                                    onChange={this.changeSelectPeserta}
                                                                    menuPosition={'fixed'}

                                                                    styles={{
                                                                        menu: provided => ({ ...provided, zIndex: 9999 }),
                                                                        control: (baseStyles, state) => ({
                                                                            ...baseStyles,
                                                                            borderColor: state.isFocused ? 'grey' : '#7E7E7E',
                                                                            color: "#000",
                                                                            // backgroundColor: 'rgba(231,230,227,255)',
                                                                            borderRadius: '10px',
                                                                            // width: "140px",
                                                                            fontSize: '12px',
                                                                        }),
                                                                        option: provided => ({
                                                                            ...provided,
                                                                            fontSize: '12px',
                                                                            zIndex: 999,
                                                                        }),
                                                                        dropdownIndicator: styles => ({
                                                                            ...styles,
                                                                            color: '#000',
                                                                        }),
                                                                        indicatorsContainer: (base) => ({
                                                                            ...base,
                                                                            minHeight: 1,
                                                                        }),
                                                                        indicatorSeparator: () => null,
                                                                    }}
                                                                />
                                                            </div>


                                                            <div className="form-group">
                                                                <label className="form-label">MCU Year</label>
                                                                <select
                                                                    name="peserta_mcuYear"
                                                                    className="form-control"
                                                                    onChange={this.onChange}
                                                                    value={this.state.peserta_mcuYear}
                                                                >

                                                                    {this.state.selectYear.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item} eventKey={item}>
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="form-label">Patient Age</label>
                                                                <input
                                                                    type="text"
                                                                    name="peserta_patientAge"
                                                                    className="form-control"
                                                                    required
                                                                    value={this.state.peserta_patientAge}
                                                                    onChange={this.onChange}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="form-label">Patient Div</label>
                                                                <input
                                                                    type="text"
                                                                    name="peserta_patientDiv"
                                                                    className="form-control"
                                                                    required
                                                                    value={this.state.peserta_patientDiv}
                                                                    onChange={this.onChange}
                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="form-label">Patient Loc</label>
                                                                <input
                                                                    type="text"
                                                                    name="peserta_patientLoc"
                                                                    className="form-control"
                                                                    required
                                                                    value={this.state.peserta_patientLoc}
                                                                    onChange={this.onChange}
                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="form-label">Patient Fit</label>
                                                                <input
                                                                    type="text"
                                                                    name="peserta_patientFit"
                                                                    className="form-control"
                                                                    required
                                                                    value={this.state.peserta_patientFit}
                                                                    onChange={this.onChange}
                                                                />
                                                            </div>


                                                            <div className="form-group">
                                                                <label className="form-label">Patient Age Range</label>
                                                                <select
                                                                    name="peserta_patientAgeRange"
                                                                    className="form-control"
                                                                    onChange={this.onChange}
                                                                    value={this.state.peserta_patientAgeRange}
                                                                >

                                                                    {this.state.selectAgeRange.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item} eventKey={item}>
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>


                                                        </div>
                                                        <div className="col-md-6">


                                                            <div className="form-group">
                                                                <label className="form-label">Patient Result</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="peserta_patientResult"
                                                                    value={this.state.peserta_patientResult}
                                                                    onChange={this.onChange}
                                                                    style={{ height: '210px' }}
                                                                />
                                                            </div>



                                                            <div className="form-group">
                                                                <label className="form-label">Patient Reg No</label>
                                                                <input
                                                                    type="text"
                                                                    name="peserta_patientRegNo"
                                                                    className="form-control"
                                                                    required
                                                                    value={this.state.peserta_patientRegNo}
                                                                    onChange={this.onChange}
                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="form-label">Patient Package ID</label>
                                                                <input
                                                                    type="text"
                                                                    name="peserta_patientPackageID"
                                                                    className="form-control"
                                                                    required
                                                                    value={this.state.peserta_patientPackageID}
                                                                    onChange={this.onChange}
                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="form-label">Patient Reg No Add</label>
                                                                <input
                                                                    type="text"
                                                                    name="peserta_patientRegNoAdd"
                                                                    className="form-control"
                                                                    required
                                                                    value={this.state.peserta_patientRegNoAdd}
                                                                    onChange={this.onChange}
                                                                />
                                                            </div>




                                                            {/* <div className="d-flex flex-column  align-items-center">
                                                                <div className="d-grid gap-2">
                                                                    <Button
                                                                        className="button-block btn-compose"
                                                                        size="lg"
                                                                        // onClick={() => this.setState({ modalForm: true })}
                                                                        onClick={this.save_v2.bind(this)}
                                                                    >
                                                                        <span>Send</span>
                                                                    </Button>
                                                                </div>
                                                            </div> */}

                                                        </div>
                                                    </div>
                                                </div>

                                            </form>



                                        </Tab.Pane>
                                        <Tab.Pane eventKey={3}>

                                            <form action="#" encType="multipart/form-data" id="form_fitunfit">
                                                <div className="form-content">
                                                    <div className="row">
                                                        <div className="col-md-12">


                                                            <div className="form-group" style={{ display: this.state.addPatientPeriode ? null : 'block' }}>
                                                                <label className="form-label">Peserta</label>
                                                                {/* <Select
                                                                    className='title'
                                                                    value={this.state.choosePeserta}
                                                                    isSearchable={true}
                                                                    name="dept"
                                                                    options={this.state.optPeserta}
                                                                    placeholder="Pilih Peserta"
                                                                    onChange={this.changeSelectPeserta}
                                                                    menuPosition={'fixed'}
                                                                    readOnly="true"

                                                                    styles={{
                                                                        menu: provided => ({ ...provided, zIndex: 9999 }),
                                                                        control: (baseStyles, state) => ({
                                                                            ...baseStyles,
                                                                            borderColor: state.isFocused ? 'grey' : '#7E7E7E',
                                                                            color: "#000",
                                                                            // backgroundColor: 'rgba(231,230,227,255)',
                                                                            borderRadius: '10px',
                                                                            // width: "140px",
                                                                            fontSize: '12px',
                                                                        }),
                                                                        option: provided => ({
                                                                            ...provided,
                                                                            fontSize: '12px',
                                                                            zIndex: 999,
                                                                        }),
                                                                        dropdownIndicator: styles => ({
                                                                            ...styles,
                                                                            color: '#000',
                                                                        }),
                                                                        indicatorsContainer: (base) => ({
                                                                            ...base,
                                                                            minHeight: 1,
                                                                        }),
                                                                        indicatorSeparator: () => null,
                                                                    }}
                                                                /> */}


                                                                <input type="text" className="form-control" value={this.state.peserta_nama} readOnly />


                                                            </div>

                                                            <div className="form-group">
                                                                <label className="form-label">Fit Unfit</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="peserta_patientFitunfit"
                                                                    value={this.state.peserta_patientFitunfit}
                                                                    onChange={this.onChange}
                                                                    style={{ height: '210px' }}
                                                                />
                                                            </div>


                                                        </div>

                                                        <a
                                                            href="#"
                                                            id="simpanData"
                                                            className="btn btn-success waves-effect"
                                                            onClick={this.update_v5.bind(this)}
                                                            style={{ display: this.state.updateBtnV2 ? null : 'none' }}
                                                        >
                                                            {" "}
                                                            Simpan
                                                        </a>
                                                        {/* <div className="col-md-6">


                                                            <div className="form-group">
                                                                <label className="form-label">Fit Unfit</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="peserta_patientResult"
                                                                    value={this.state.peserta_patientResult}
                                                                    onChange={this.onChange}
                                                                    style={{ height: '210px' }}
                                                                />
                                                            </div>


                                                        </div> */}
                                                    </div>
                                                </div>

                                            </form>



                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: "0px solid #ffffff" }}>
                        <div>
                            <Row>
                                <Col
                                    style={{
                                        display: this.state.tabkey == 1 ? "flex" : "none",
                                        padding: 2,
                                    }}
                                >
                                    <a
                                        href="#"
                                        id="lanjut"
                                        className="btn btn-info waves-effect"
                                        onClick={() => this.handleSelect(2)}
                                    >
                                        {" "}
                                        next
                                    </a>
                                </Col>
                                <Col
                                    style={{
                                        display: this.state.tabkey == 2 ? "flex" : "none",
                                        padding: 2,
                                    }}
                                >
                                    <a
                                        href="#"
                                        id="simpanData"
                                        className="btn btn-success waves-effect"
                                        onClick={this.save_v2.bind(this)}
                                        style={{ display: this.state.updateBtnV2 ? 'none' : null }}
                                    >
                                        {" "}
                                        simpan
                                    </a>

                                    <a
                                        href="#"
                                        id="simpanData"
                                        className="btn btn-success waves-effect"
                                        onClick={this.update_v2.bind(this)}
                                        style={{ display: this.state.updateBtnV2 ? null : 'none' }}
                                    >
                                        {" "}
                                        update
                                    </a>
                                </Col>
                                <Col style={{ padding: 2 }}>
                                    <a
                                        href="#"
                                        id="btnCancelForm"
                                        className="btn btn-danger"
                                        onClick={this.handleCloseModalForm.bind(this)}
                                    >
                                        {" "}
                                        cancel
                                    </a>
                                </Col>
                            </Row>
                        </div>

                        {/* <button
              type="button"
              className="close"
              onClick={this.handleCloseModalForm.bind(this)}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button> */}
                    </Modal.Footer>
                </Modal>


                <Modal
                    show={this.state.modalAddResult}
                    onHide={this.handleCloseModalAddResult.bind(this)}
                    backdrop="static"
                    dialogClassName="custom-modal"
                    keyboard
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>ADD Result</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>

                            <Col>
                                <form action="#" encType="multipart/form-data" id="form_add">
                                    <div className="form-content">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Peserta</label>
                                                    <Select
                                                        className='title'
                                                        value={this.state.choosePeserta}
                                                        isSearchable={true}
                                                        name="dept"
                                                        options={this.state.optPeserta}
                                                        placeholder="Pilih Peserta"
                                                        onChange={this.changeSelectPeserta}
                                                        menuPosition={'fixed'}
                                                        isDisabled={this.state.updateBtnV3 ? true : false}

                                                        styles={{
                                                            menu: provided => ({ ...provided, zIndex: 9999 }),
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: state.isFocused ? 'grey' : '#7E7E7E',
                                                                color: "#000",
                                                                // backgroundColor: 'rgba(231,230,227,255)',
                                                                borderRadius: '10px',
                                                                // width: "140px",
                                                                fontSize: '12px',
                                                            }),
                                                            option: provided => ({
                                                                ...provided,
                                                                fontSize: '12px',
                                                                zIndex: 999,
                                                            }),
                                                            dropdownIndicator: styles => ({
                                                                ...styles,
                                                                color: '#000',
                                                            }),
                                                            indicatorsContainer: (base) => ({
                                                                ...base,
                                                                minHeight: 1,
                                                            }),
                                                            indicatorSeparator: () => null,
                                                        }}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label">2016</label>
                                                    <input
                                                        type="text"
                                                        name="result_2016"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_2016}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">F2016</label>
                                                    <input
                                                        type="text"
                                                        name="result_F2016"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_F2016}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">R2016</label>
                                                    <input
                                                        type="text"
                                                        name="result_R2016"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_R2016}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">N2016</label>
                                                    <input
                                                        type="text"
                                                        name="result_N2016"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_N2016}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">U2016</label>
                                                    <input
                                                        type="text"
                                                        name="result_U2016"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_U2016}
                                                        onChange={this.onChange}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label">2017</label>
                                                    <input
                                                        type="text"
                                                        name="result_2017"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_2017}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">F2017</label>
                                                    <input
                                                        type="text"
                                                        name="result_F2017"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_F2017}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">R2017</label>
                                                    <input
                                                        type="text"
                                                        name="result_R2017"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_R2017}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">N2017</label>
                                                    <input
                                                        type="text"
                                                        name="result_N2017"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_N2017}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">U2017</label>
                                                    <input
                                                        type="text"
                                                        name="result_U2017"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_U2017}
                                                        onChange={this.onChange}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">2018</label>
                                                    <input
                                                        type="text"
                                                        name="result_2018"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_2018}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">F2018</label>
                                                    <input
                                                        type="text"
                                                        name="result_F2018"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_F2018}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">R2018</label>
                                                    <input
                                                        type="text"
                                                        name="result_R2018"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_R2018}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">N2018</label>
                                                    <input
                                                        type="text"
                                                        name="result_N2018"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_N2018}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">U2018</label>
                                                    <input
                                                        type="text"
                                                        name="result_U2018"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_U2018}
                                                        onChange={this.onChange}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">2019</label>
                                                    <input
                                                        type="text"
                                                        name="result_2019"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_2019}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">F2019</label>
                                                    <input
                                                        type="text"
                                                        name="result_F2019"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_F2019}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">R2019</label>
                                                    <input
                                                        type="text"
                                                        name="result_R2019"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_R2019}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">N2019</label>
                                                    <input
                                                        type="text"
                                                        name="result_N2019"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_N2019}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">U2019</label>
                                                    <input
                                                        type="text"
                                                        name="result_U2019"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_U2019}
                                                        onChange={this.onChange}
                                                    />
                                                </div>




                                            </div>
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label className="form-label">Test</label>
                                                    <Select
                                                        className='title'
                                                        value={this.state.chooseTest}
                                                        isSearchable={true}
                                                        name="dept"
                                                        options={this.state.Test_Code}
                                                        placeholder="Pilih Peserta"
                                                        onChange={this.changeSelectTest}
                                                        menuPosition={'fixed'}
                                                        isDisabled={this.state.updateBtnV3 ? true : false}

                                                        styles={{
                                                            menu: provided => ({ ...provided, zIndex: 9999 }),
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: state.isFocused ? 'grey' : '#7E7E7E',
                                                                color: "#000",
                                                                // backgroundColor: 'rgba(231,230,227,255)',
                                                                borderRadius: '10px',
                                                                // width: "140px",
                                                                fontSize: '12px',
                                                            }),
                                                            option: provided => ({
                                                                ...provided,
                                                                fontSize: '12px',
                                                                zIndex: 999,
                                                            }),
                                                            dropdownIndicator: styles => ({
                                                                ...styles,
                                                                color: '#000',
                                                            }),
                                                            indicatorsContainer: (base) => ({
                                                                ...base,
                                                                minHeight: 1,
                                                            }),
                                                            indicatorSeparator: () => null,
                                                        }}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">2020</label>
                                                    <input
                                                        type="text"
                                                        name="result_2020"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_2020}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">F2020</label>
                                                    <input
                                                        type="text"
                                                        name="result_F2020"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_F2020}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">R2020</label>
                                                    <input
                                                        type="text"
                                                        name="result_R2020"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_R2020}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">N2020</label>
                                                    <input
                                                        type="text"
                                                        name="result_N2020"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_N2020}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">U2020</label>
                                                    <input
                                                        type="text"
                                                        name="result_U2020"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_U2020}
                                                        onChange={this.onChange}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">2021</label>
                                                    <input
                                                        type="text"
                                                        name="result_2021"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_2021}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">F2021</label>
                                                    <input
                                                        type="text"
                                                        name="result_F2021"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_F2021}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">R2021</label>
                                                    <input
                                                        type="text"
                                                        name="result_R2021"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_R2021}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">N2021</label>
                                                    <input
                                                        type="text"
                                                        name="result_N2021"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_N2021}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">U2021</label>
                                                    <input
                                                        type="text"
                                                        name="result_U2021"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_U2021}
                                                        onChange={this.onChange}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">2022</label>
                                                    <input
                                                        type="text"
                                                        name="result_2022"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_2022}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">F2022</label>
                                                    <input
                                                        type="text"
                                                        name="result_F2022"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_F2022}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">R2022</label>
                                                    <input
                                                        type="text"
                                                        name="result_R2022"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_R2022}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">N2022</label>
                                                    <input
                                                        type="text"
                                                        name="result_N2022"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_N2022}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">U2022</label>
                                                    <input
                                                        type="text"
                                                        name="result_U2022"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_U2022}
                                                        onChange={this.onChange}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label">2023</label>
                                                    <input
                                                        type="text"
                                                        name="result_2023"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_2023}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">F2023</label>
                                                    <input
                                                        type="text"
                                                        name="result_F2023"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_F2023}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">R2023</label>
                                                    <input
                                                        type="text"
                                                        name="result_R2023"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_R2023}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">N2023</label>
                                                    <input
                                                        type="text"
                                                        name="result_N2023"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_N2023}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">U2023</label>
                                                    <input
                                                        type="text"
                                                        name="result_U2023"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_U2023}
                                                        onChange={this.onChange}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label">2024</label>
                                                    <input
                                                        type="text"
                                                        name="result_2024"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_2024}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">F2024</label>
                                                    <input
                                                        type="text"
                                                        name="result_F2024"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_F2024}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">R2024</label>
                                                    <input
                                                        type="text"
                                                        name="result_R2024"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_R2024}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">N2024</label>
                                                    <input
                                                        type="text"
                                                        name="result_N2024"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_N2024}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">U2024</label>
                                                    <input
                                                        type="text"
                                                        name="result_U2024"
                                                        className="form-control"
                                                        required
                                                        value={this.state.result_U2024}
                                                        onChange={this.onChange}
                                                    />
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: "0px solid #ffffff" }}>
                        <div>
                            <Row>
                                <Col
                                    style={{
                                        // display: this.state.tabkey == 2 ? "flex" : "none",
                                        padding: 2,
                                    }}
                                >
                                    <a
                                        href="#"
                                        id="simpanData"
                                        className="btn btn-success waves-effect"
                                        onClick={this.save_v3.bind(this)}
                                        style={{ display: this.state.updateBtnV3 ? 'none' : null }}
                                    >
                                        {" "}
                                        simpan
                                    </a>
                                    <a
                                        href="#"
                                        id="simpanData"
                                        className="btn btn-success waves-effect"
                                        onClick={this.update_v3.bind(this)}
                                        style={{ display: this.state.updateBtnV3 ? null : 'none' }}
                                    >
                                        {" "}
                                        update
                                    </a>

                                </Col>
                            </Row>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalAddTemuan}
                    onHide={this.handleCloseModalAddTemuan.bind(this)}
                    backdrop="static"
                    dialogClassName="custom-modal"
                    keyboard
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>ADD Result</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>

                            <Col>
                                <form action="#" encType="multipart/form-data" id="form_add">
                                    <div className="form-content">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Peserta</label>
                                                    <Select
                                                        className='title'
                                                        value={this.state.choosePeserta}
                                                        isSearchable={true}
                                                        name="dept"
                                                        options={this.state.optPeserta}
                                                        placeholder="Pilih Peserta"
                                                        onChange={this.changeSelectPeserta}
                                                        menuPosition={'fixed'}
                                                        isDisabled={this.state.updateBtnV4 ? true : false}

                                                        styles={{
                                                            menu: provided => ({ ...provided, zIndex: 9999 }),
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: state.isFocused ? 'grey' : '#7E7E7E',
                                                                color: "#000",
                                                                // backgroundColor: 'rgba(231,230,227,255)',
                                                                borderRadius: '10px',
                                                                // width: "140px",
                                                                fontSize: '12px',
                                                            }),
                                                            option: provided => ({
                                                                ...provided,
                                                                fontSize: '12px',
                                                                zIndex: 999,
                                                            }),
                                                            dropdownIndicator: styles => ({
                                                                ...styles,
                                                                color: '#000',
                                                            }),
                                                            indicatorsContainer: (base) => ({
                                                                ...base,
                                                                minHeight: 1,
                                                            }),
                                                            indicatorSeparator: () => null,
                                                        }}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label">2016</label>
                                                    <select
                                                        name="temuan_2016"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={this.state.temuan_2016}
                                                    >
                                                        <option value="">Normal</option>
                                                        <option value="1">Abnormal</option>
                                                    </select>
                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label">2017</label>
                                                    <select
                                                        name="temuan_2017"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={this.state.temuan_2017}
                                                    >
                                                        <option value="">Normal</option>
                                                        <option value="1">Abnormal</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">2018</label>
                                                    <select
                                                        name="temuan_2018"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={this.state.temuan_2018}
                                                    >
                                                        <option value="">Normal</option>
                                                        <option value="1">Abnormal</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">2019</label>
                                                    <select
                                                        name="temuan_2019"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={this.state.temuan_2019}
                                                    >
                                                        <option value="">Normal</option>
                                                        <option value="1">Abnormal</option>
                                                    </select>
                                                </div>





                                            </div>
                                            <div className="col-md-6">

                                                {/* <div className="form-group">
                                                    <label className="form-label">Conclusion</label>
                                                    <Select
                                                        className='title'
                                                        value={this.state.chooseConc}
                                                        isSearchable={true}
                                                        name="dept"
                                                        options={this.state.Conc_Code}
                                                        placeholder="Pilih Peserta"
                                                        onChange={this.changeSelectConc}
                                                        menuPosition={'fixed'}

                                                        styles={{
                                                            menu: provided => ({ ...provided, zIndex: 9999 }),
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: state.isFocused ? 'grey' : '#7E7E7E',
                                                                color: "#000",
                                                                // backgroundColor: 'rgba(231,230,227,255)',
                                                                borderRadius: '10px',
                                                                // width: "140px",
                                                                fontSize: '12px',
                                                            }),
                                                            option: provided => ({
                                                                ...provided,
                                                                fontSize: '12px',
                                                                zIndex: 999,
                                                            }),
                                                            dropdownIndicator: styles => ({
                                                                ...styles,
                                                                color: '#000',
                                                            }),
                                                            indicatorsContainer: (base) => ({
                                                                ...base,
                                                                minHeight: 1,
                                                            }),
                                                            indicatorSeparator: () => null,
                                                        }}
                                                    />
                                                </div> */}


                                                <div className="form-group">
                                                    <label className="form-label">Conclusion</label>
                                                    <ComboBox onChange={this.changeSelectConc}
                                                        disabled={this.state.updateBtnV4 ? true : null}
                                                        style={{ width: '100%', height: '40px' }} data={this.state.Conc_Code} value={this.state.chooseConc} ></ComboBox>

                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label">2020</label>
                                                    <select
                                                        name="temuan_2020"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={this.state.temuan_2020}
                                                    >
                                                        <option value="">Normal</option>
                                                        <option value="1">Abnormal</option>
                                                    </select>
                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label">2021</label>
                                                    <select
                                                        name="temuan_2021"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={this.state.temuan_2021}
                                                    >
                                                        <option value="">Normal</option>
                                                        <option value="1">Abnormal</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">2022</label>
                                                    <select
                                                        name="temuan_2022"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={this.state.temuan_2022}
                                                    >
                                                        <option value="">Normal</option>
                                                        <option value="1">Abnormal</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">2023</label>
                                                    <select
                                                        name="temuan_2023"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={this.state.temuan_2023}
                                                    >
                                                        <option value="">Normal</option>
                                                        <option value="1">Abnormal</option>
                                                    </select>
                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label">2024</label>
                                                    <select
                                                        name="temuan_2024"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={this.state.temuan_2024}
                                                    >
                                                        <option value="">Normal</option>
                                                        <option value="1">Abnormal</option>
                                                    </select>
                                                </div>



                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: "0px solid #ffffff" }}>
                        <div>
                            <Row>
                                <Col
                                    style={{
                                        // display: this.state.tabkey == 2 ? "flex" : "none",
                                        padding: 2,
                                    }}
                                >
                                    <a
                                        href="#"
                                        id="simpanData"
                                        className="btn btn-success waves-effect"
                                        onClick={this.save_v4.bind(this)}
                                        style={{ display: this.state.updateBtnV4 ? 'none' : null }}
                                    >
                                        {" "}
                                        simpan
                                    </a>

                                    <a
                                        href="#"
                                        id="simpanData"
                                        className="btn btn-success waves-effect"
                                        onClick={this.save_v4.bind(this)}
                                        style={{ display: this.state.updateBtnV4 ? null : 'none' }}
                                    >
                                        {" "}
                                        simpan
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Row>
                    <Col md={12} style={{ cursor: 'pointer' }}>
                        {tableInbox ? (
                            <PaginationProvider pagination={paginationFactory(options)}>
                                {contentTableInbox}
                            </PaginationProvider>
                        ) : null}
                    </Col>

                </Row>



            </>
        );
    }
}
export default withRouter(withTranslation()(People));
