import axios from 'axios';
import { ButtonGroup, ComboBox, DataGrid, Dialog, Form, FormField, GridColumn, Label, Layout, LayoutPanel, LinkButton, Panel, TextBox } from 'rc-easyui';
import React, { Component } from 'react';
import { uAPIlocal } from '../../lib/config';
import { withRouter, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import {
    Row,
    Col,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import CryptoJS from "crypto-js";
let source = axios.CancelToken.source();
axios.defaults.withCredentials = true;
axios.defaults.headers.post["X-CSRF-Token"] = Cookies.get('mcu-csrf');

class MasterConc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirctTo: false,
            master_conc: {
                total: 0,
                pageSize: 20,
                data: [],
                pagePosition: "bottom",
                pageOptions: {
                    layout: ['list', 'sep', 'first', 'prev', 'next', 'last', 'sep', 'refresh', 'sep', 'manual', 'info']
                },
                selection: null,
                loading: true,
            },
            // Test_Code: [],
            // Conc_Code: [],
            formTambah: {
                field: {
                    Test_ID: "",
                    Test_Name: "",
                    Conc_ID: "",
                    Conc_Name: "",
                },
                rules: {
                    Test_ID: ["required"],
                    Test_Name: ["required"],
                    Conc_ID: ["required"],
                    Conc_Name: ["required"],
                }
            },
            formEdit: {
                field: {
                    Test_ID: "",
                    Test_Name: "",
                    Conc_ID: "",
                    Conc_Name: "",
                },
                rules: {
                    Test_ID: ["required"],
                    Test_Name: ["required"],
                    Conc_ID: ["required"],
                    Conc_Name: ["required"],
                }
            },
            loader: false,
        }
        this.errorDialog = React.createRef();
        this.tambahConc = React.createRef();
        this.editConc = React.createRef();

        this.pageChange = this.pageChange.bind(this);
        this.selectionChange = this.selectionChange.bind(this);

        this.formTambah = React.createRef();
        this.handleChangeFormTambah = this.handleChangeFormTambah.bind(this);
        this.handleSubmitFormTambah = this.handleSubmitFormTambah.bind(this);

        this.formEdit = React.createRef();
        this.handleEditClick = this.handleEditClick.bind(this)
        this.handleChangeformEdit = this.handleChangeformEdit.bind(this);
        this.handleSubmitformEdit = this.handleSubmitformEdit.bind(this);

        this.removeDialog = React.createRef();
        this.removedData = this.removedData.bind(this);
        this.handleRemoveClick = this.handleRemoveClick.bind(this);
        this.autoLogout = this.autoLogout.bind(this);
    }
    async getData() {
        await axios.post(uAPIlocal + '/api/v1/getmaster_conc')
            .then(function (response) {
                return response.data.results;
            })
            .then(response => {
                // console.log(response)
                this.setState({
                    master_conc: {
                        ...this.state.master_conc,
                        data: response,
                        loading: false
                    }
                })
            })
            .catch((e) => {
                console.log(e);
                // setTimeout(() => {
                //     window.location.reload()
                // }, 2000);
                // this.autoLogout();
            });
    }

    autoLogout = () => {
        this.setState({ redirctTo: true });
    }

    async getTest_Code() {
        await axios.post(uAPIlocal + '/api/v1/test_code')
            // .then(function (response) {
            //     return response.data.results;
            // })
            .then(response =>
                response.data.results.map(Test_Code => ({
                    value: `${Test_Code.Test_ID}-${Test_Code.Test_Name}`,
                    text: `${Test_Code.Test_ID} - ${Test_Code.Test_Name}`,
                }))
            )
            .then(Test_Code => {
                this.setState({
                    Test_Code
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async getConc_Code() {
        await axios.post(uAPIlocal + '/api/v1/conc_code')
            // .then(function (response) {
            //     return response.data.results;
            // })
            .then(response =>
                response.data.results.map(Conc_Code => ({
                    value: `${Conc_Code.Conc_ID}/${Conc_Code.Conc_Name.replace("- ", "")}`,
                    text: `${Conc_Code.Conc_ID} - ${Conc_Code.Conc_Name.replace("- ", "")}`,
                }))
            )
            .then(Conc_Code => {
                this.setState({
                    Conc_Code
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    pageChange(event) {
        // console.log(event)
        if (event.refresh) {
            // console.log(event)
            this.setState({
                master_conc: {
                    ...this.state.master_conc,
                    loading: true,
                    selection: null
                },
            })
            this.getData();
        }

    }
    async selectionChange(event) {
        // console.log(event)
        await this.setState({
            master_conc: {
                ...this.state.master_conc,
                selection: event
            },
        })
    }

    async handleChangeFormTambah(name, value) {
        let field = Object.assign({}, this.state.formTambah.field);
        field[name] = value;
        await this.setState({
            formTambah: {
                ...this.state.formTambah,
                field: field
            }
        }, () => console.log(this.state.formTambah.field))
    }

    async handleSubmitFormTambah() {
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        const Test_Code = this.state.formTambah.field.Test_ID;
        const Test_Name = this.state.formTambah.field.Test_Name;
        const Conc_Code = this.state.formTambah.field.Conc_ID;
        const Conc_Name = this.state.formTambah.field.Conc_Name;
        formData.append('Test_ID', Test_Code);
        formData.append('Test_Name', Test_Name);
        formData.append('Conc_ID', Conc_Code);
        formData.append('Conc_Name', Conc_Name);

        for (var pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        if (Test_Code == '' || Test_Name == '' || Conc_Code == '' || Conc_Name == '') {

            this.setState({
                errorMessage: "Form tidak boleh kosong!",
            })
            this.errorDialog.current.open();

        } else {
            await axios.post(uAPIlocal + '/api/v1/master_conc', formData, config)
                .then(response => {
                    // console.log(response)
                    if (response.data.status === 406) {
                        this.setState({
                            errorMessage: response.data.message,
                        })
                        this.errorDialog.current.open();
                    } else {
                        this.getData();
                        this.tambahConc.current.close();
                        this.setState({
                            formTambah: {
                                ...this.state.formTambah,
                                field: {
                                    Test_ID: "",
                                    Conc_ID: "",
                                },
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }



    }

    async handleRemoveClick() {
        if (this.state.master_conc.selection) {
            this.removeDialog.current.open();
        } else {
            // console.log('tidak ada yang di select');
            this.setState({
                errorMessage: "Tidak Ada Data yang Dipilih",
            })
            this.errorDialog.current.open();
        }
    }

    async removedData() {
        await this.setState({
            loader: true
        })
        const id = this.state.master_conc.selection.id_conc
        await axios.delete(uAPIlocal + `/api/v1/master_conc/${id}`)
            .then(function (response) {
                // console.log(response)this.state.pasien.selection.id_regis
                return response.data;
            })
            .then(response => {
                // console.log(response)
                this.getData();
                this.removeDialog.current.close();
                this.setState({
                    loader: false,
                    master_conc: {
                        ...this.state.master_conc,
                        selection: null
                    },
                })




            })
            .catch(error => {
                console.log(error);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    if (error.response.status === 500) {
                        this.setState({
                            loader: false,
                            errorMessage: error.response.data,
                        })
                        this.errorDialog.current.open();
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                    this.setState({
                        loader: false,
                        errorMessage: "Terjadi Kesalahan",
                    })
                    this.errorDialog.current.open();
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    this.setState({
                        loader: false,
                        errorMessage: "Terjadi Kesalahan",
                    })
                    this.errorDialog.current.open();
                }

            });
    }

    async handleEditClick() {
        if (this.state.master_conc.selection) {
            console.log(this.state.master_conc.selection)
            const Test_ID = `${this.state.master_conc.selection.Test_ID}`
            const Conc_ID = `${this.state.master_conc.selection.Conc_ID}`
            const Test_Name = `${this.state.master_conc.selection.Test_Name}`
            const Conc_Name = `${this.state.master_conc.selection.Conc_Name}`
            await this.setState({
                formEdit: {
                    ...this.state.formEdit,
                    field: {
                        Test_ID: Test_ID,
                        Test_Name: Test_Name,
                        Conc_ID: Conc_ID,
                        Conc_Name: Conc_Name,
                    },
                }
            })
            this.editConc.current.open();
        } else {
            // console.log('tidak ada yang di select');
            this.setState({
                errorMessage: "Tidak Ada Data yang Dipilih",
            })
            this.errorDialog.current.open();
        }
    }

    async handleChangeformEdit(name, value) {
        let field = Object.assign({}, this.state.formEdit.field);
        field[name] = value;
        await this.setState({
            formEdit: {
                ...this.state.formEdit,
                field: field
            }
        })
    }
    async handleSubmitformEdit() {
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        const Test_Code = this.state.formEdit.field.Test_ID;
        const Test_Name = this.state.formEdit.field.Test_Name;
        const Conc_Code = this.state.formEdit.field.Conc_ID;
        const Conc_Name = this.state.formEdit.field.Conc_Name;
        formData.append('id_conc', this.state.master_conc.selection.id_conc);
        formData.append('Test_ID', Test_Code);
        formData.append('Test_Name', Test_Name);
        formData.append('Conc_ID', Conc_Code);
        formData.append('Conc_Name', Conc_Name);

        for (var pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        if (Test_Code == '' || Test_Name == '' || Conc_Code == '' || Conc_Name == '') {

            this.setState({
                errorMessage: "Form tidak boleh kosong!",
            })
            this.errorDialog.current.open();

        } else {

            await axios.put(uAPIlocal + '/api/v1/master_conc', formData, config)
                .then(response => {
                    if (response.data.status === 406) {
                        this.setState({
                            errorMessage: response.data.message,
                        })
                        this.errorDialog.current.open();
                    } else {
                        this.setState({
                            master_conc: {
                                ...this.state.master_conc,
                                loading: true
                            }
                        })
                        this.getData();
                        this.editConc.current.close();
                        this.setState({
                            formEdit: {
                                ...this.state.formEdit,
                                field: {
                                    Test_ID: "",
                                    Conc_ID: "",
                                },
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    }

    componentDidMount() {
        this.getData();
        // this.getTest_Code();
        // this.getConc_Code();
    }
    render() {

        if (this.state.redirctTo) {
            Cookies.remove("mcu-login", { path: '/', domain: '.biomedika.co.id' })
        } else { }

        return (

            <Row>


                <Dialog
                    title="Alert"
                    style={{ width: '300px', height: '84px' }}
                    modal
                    closed={true}
                    ref={this.errorDialog}
                >
                    <p style={{ textAlign: 'center', margin: '10px', fontSize: '16px' }}>{this.state.errorMessage}</p>
                </Dialog>

                <Dialog title="Tambah Conclusion"
                    style={{ width: '500px', }}
                    modal
                    closed={true}
                    ref={this.tambahConc}
                >
                    <div className='pd-20'>
                        <Form
                            ref={this.formTambah}
                            style={{ maxWidth: 500 }}
                            model={this.state.formTambah.field}
                            rules={this.state.formTambah.rules}
                            labelWidth={200}
                            // labelAlign="right"
                            labelPosition="top"
                            // floatingLabel
                            // validateRules={this.getCustomRules}
                            onChange={this.handleChangeFormTambah}
                        >
                            <FormField name="Test_ID" label="Test ID">
                                {/* <ComboBox data={this.state.Test_Code} value={this.state.formTambah.field.Test_ID} ></ComboBox> */}
                                <TextBox inputId="t1" name="ftestid" value={this.state.formTambah.field.Test_ID} style={{ width: '100%' }}></TextBox>
                            </FormField>
                            <FormField name="Test_Name" label="Test Name">
                                <TextBox inputId="t2" name="ftestname" value={this.state.formTambah.field.Test_Name} style={{ width: '100%' }}></TextBox>
                            </FormField>
                            <FormField name="Conc_ID" label="Conc ID">
                                <TextBox inputId="t3" name="fconcid" value={this.state.formTambah.field.Conc_ID} style={{ width: '100%' }}></TextBox>
                            </FormField>
                            <FormField name="Conc_Name" label="Conc Name">
                                <TextBox inputId="t4" name="fconcname" value={this.state.formTambah.field.Conc_Name} style={{ width: '100%' }}></TextBox>
                            </FormField>
                            <FormField >
                                <LinkButton onClick={this.handleSubmitFormTambah} >Submit</LinkButton>
                            </FormField>
                        </Form>
                    </div>
                </Dialog>

                <Dialog title="Edit Conclusion"
                    style={{ width: '500px', }}
                    modal
                    closed={true}
                    ref={this.editConc}
                >
                    <div className='pd-20'>
                        <Form
                            ref={this.formEdit}
                            style={{ maxWidth: 500 }}
                            model={this.state.formEdit.field}
                            rules={this.state.formEdit.rules}
                            labelWidth={200}
                            // labelAlign="right"
                            labelPosition="top"
                            // floatingLabel
                            // validateRules={this.getCustomRules}
                            onChange={this.handleChangeformEdit}
                        >
                            <FormField name="Test_ID" label="Test ID">
                                <TextBox inputId="t1" name="ftestid" value={this.state.formEdit.field.Test_ID} style={{ width: '100%' }}></TextBox>
                            </FormField>
                            <FormField name="Test_Name" label="Test Name">
                                <TextBox inputId="t2" name="ftestname" value={this.state.formEdit.field.Test_Name} style={{ width: '100%' }}></TextBox>
                            </FormField>
                            <FormField name="Conc_ID" label="Conc ID">
                                <TextBox inputId="t3" name="fconcid" value={this.state.formEdit.field.Conc_ID} style={{ width: '100%' }}></TextBox>
                            </FormField>
                            <FormField name="Conc_Name" label="Conc Name">
                                <TextBox inputId="t4" name="fconcname" value={this.state.formEdit.field.Conc_Name} style={{ width: '100%' }}></TextBox>
                            </FormField>
                            <FormField >
                                <LinkButton onClick={this.handleSubmitformEdit} >Submit</LinkButton>
                            </FormField>
                        </Form>
                    </div>
                </Dialog>
                <Dialog
                    title="Please Confirm"
                    style={{ width: '300px', height: '190px' }}
                    modal
                    closed={true}
                    ref={this.removeDialog}
                    footer={() => {
                        return (
                            this.state.loader ? (
                                <div className="text-center">
                                    <Loader type="TailSpin" color="#00BFFF" height={40} width={100} />
                                </div>
                            ) : (

                                <>
                                    <ButtonGroup style={{ width: '100%', height: '50px' }}>
                                        <LinkButton className="f-full mybtn" plain onClick={this.removedData}>Yakin</LinkButton>
                                        <LinkButton className="f-full mybtn" plain onClick={() => this.removeDialog.current.close()}>Cancel</LinkButton>
                                    </ButtonGroup>
                                </>
                            )
                        )
                    }}

                >
                    <>
                        <p style={{ textAlign: 'center', margin: '10px', fontSize: '16px' }}>Apakah Anda Yakin ?</p>

                    </>
                </Dialog>


                <Col md="12" style={{ backgroundColor: "white", padding: "2px" }}>
                    <Panel
                        title="Master Conclusion"
                        collapsible
                    // bodyStyle={{ padding: 20 }}
                    // style={{ height: 500 }}
                    >
                        <Row style={{ padding: 0, marginBottom: 4, marginLeft: 2 }}>
                            <Col className="col-8">
                                <div style={{ marginTop: 10, marginLeft: 4 }}>
                                    <LinkButton
                                        iconCls="icon-add"
                                        style={{ margin: 4 }}
                                        // onClick={() => this.addRow()}
                                        onClick={() => this.tambahConc.current.open()}
                                    >
                                        Tambah
                                    </LinkButton>
                                    <LinkButton
                                        iconCls="icon-edit"
                                        style={{ margin: 4 }}
                                        // onClick={() => this.editRow()}
                                        onClick={this.handleEditClick}
                                        disabled={this.state.master_conc.selection ? false : true}
                                    >
                                        Ubah
                                    </LinkButton>
                                    <LinkButton
                                        iconCls="icon-cancel"
                                        style={{ margin: 4 }}
                                        // onClick={() => this.deleteRow()}
                                        onClick={this.handleRemoveClick}
                                        disabled={this.state.master_conc.selection ? false : true}
                                    >
                                        Hapus
                                    </LinkButton>
                                </div>
                            </Col>
                        </Row>

                        <div>
                            <DataGrid
                                {...this.state.master_conc}
                                style={{ height: '70vh', }}
                                pagination
                                columnResizing
                                selectionMode="single"
                                onSelectionChange={this.selectionChange}
                                // onPageChange={this.pageChange}
                                filterable
                                virtualScroll
                                columnMoving
                            >
                                <GridColumn
                                    field="rn"
                                    align="center"
                                    width="30px"
                                    cellCss="datagrid-td-rownumber"
                                    render={({ rowIndex }) => <span>{rowIndex + 1}</span>}
                                    filterable={false}
                                />
                                <GridColumn
                                    field="Test_ID"
                                    title="Test ID"
                                    width="200px"
                                ></GridColumn>
                                <GridColumn
                                    sortable
                                    field="Test_Name"
                                    title="Test Name"
                                    align="center"
                                    width="200px"
                                ></GridColumn>
                                <GridColumn
                                    field="Conc_ID"
                                    title="Conc ID"
                                    align="center"
                                    width="80px"
                                ></GridColumn>
                                <GridColumn
                                    field="Conc_Name"
                                    title="Conc Name"
                                    align="center"
                                    width="100px"
                                ></GridColumn>
                            </DataGrid>
                        </div>
                    </Panel>
                </Col>
            </Row>
        )
    }
}
export default withRouter(withTranslation()(MasterConc));