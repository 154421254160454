import React from "react";
import { Link, Navigate, Route, Routes, useNavigate, Redirect } from "react-router-dom";
import logo from "../../assets/logoBioMedika.svg";
import logo2 from "../../assets/new/dashboard.png";
import dropdownIcon from "../../assets/br_down.png";
// import CustomComponent from "./component/CustomComponent";
// import plus from "./../images/font-awesome/black/png/32/plus.png";
// import mail from "./../images/font-awesome/black/png/32/envelope-o.png";
// import flag from "./../images/font-awesome/black/png/32/flag-o.png";
// import bell from "./../images/font-awesome/black/png/32/bell-o.png";
// import user from "./../images/font-awesome/black/png/32/user-o.png";
// import user256 from "./../images/font-awesome/black/png/256/user-o.png";
// import doc from "./../images/font-awesome/black/png/32/file-text-o.png";

import messageIcon from "../../assets/messageIcon.svg";
import notificationIcon from "../../assets/notificationIcon.svg";
import ellipse1 from "../../assets/ellipse1.svg";
import vector1 from "../../assets/vector1.svg";

import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import axios from "axios";
import $ from "jquery";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  ButtonGroup,
  Form,
  FormControl,
  Breadcrumb,
  Carousel,
  List,
  Alert,
  Badge,
  Nav,
  Modal,
  NavDropdown,
  Popover,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";

import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, } from '@fortawesome/free-solid-svg-icons';
import { uAPIlocal } from '../lib/config';
import Cookies from 'js-cookie';
import { withTranslation } from 'react-i18next';
axios.defaults.withCredentials = true;
axios.defaults.headers.post["X-CSRF-Token"] = Cookies.get('mcu-csrf');
axios.defaults.headers.put["X-CSRF-Token"] = Cookies.get('klinik-csrf');
axios.defaults.headers.delete["X-CSRF-Token"] = Cookies.get('klinik-csrf');
moment.locale('id');


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      menuLogout: false,
      show: false,
      redirctTo: false,
      statusLogout: 0,
    };
    this.LogoutClick = this.LogoutClick.bind(this);
    this.HideLogout = this.HideLogout.bind(this);
    this.Logout = this.Logout.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }


  SetLanguage(lang) {
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  LogoutClick() {
    this.setState({
      menuLogout: !this.state.menuLogout,
    })
  }
  HideLogout() {
    this.setState({
      menuLogout: false,
    });
  }
  async Logout(ex) {
    // const formData = new FormData();
    // const config = {
    //   headers: {
    //     'content-type': 'multipart/form-data'
    //   },
    //   withCredentials: true
    // };

    // formData.append('id_user', localStorage.getItem("id_user"));
    // formData.append('name', localStorage.getItem("name"));
    // formData.append('message', "Logout");


    // await axios.post(uAPIlocal + '/api/v1/log', formData, config)
    //   .then(response => {
    //     console.log(response)
    //     if (response.data.status === 406) {
    //       console.log(response)

    //     } else {
    //       console.log(response)
    //     }

    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    axios({
      method: "post",
      url: uAPIlocal + "/logout",
    })
      .then((response) => {
        console.log(response);

        Cookies.remove("mcu-login", { path: '/', domain: '.biomedika.co.id' })
        Cookies.remove("mcu-csrf", { path: '/', domain: '.biomedika.co.id' })
        Cookies.remove("mcu-session", { path: '/', domain: '.biomedika.co.id' })
        Cookies.remove("mcu-userID", { path: '/', domain: '.biomedika.co.id' })
        Cookies.remove("mcu-Company_ID", { path: '/', domain: '.biomedika.co.id' })
        Cookies.remove("mcu-exp", { path: '/', domain: '.biomedika.co.id' })
        localStorage.clear();
        sessionStorage.clear();

        if (ex == 0) {
          this.setState({ redirctTo: true, statusLogout: 1 });
        } else {
          this.setState({ redirctTo: true, statusLogout: 0 });
        }

        // buat bedain logout dan sesi habis

      })
      .catch((error) => {
        console.log(error);
      });

  }

  myalert() {
    alert("testing");
  }

  componentDidMount() {
    // const match = this.props.match.path;

    this.timerID = setInterval(
      () => this.tick(),
      1000
    );

    if (Cookies.get('mcu-login') == undefined) {
      this.Logout(1);
      this.setState({ redirctTo: true });
    }

  }

  componentWillUnmount() {
    this.props.i18n.changeLanguage(localStorage.getItem("lang"));
    clearInterval(this.timerID);
    // if (this.state.redirctTo) {
    //   this.Logout();
    // }
  }

  tick() {
    this.setState({
      date: new Date()
    });
  }


  render() {

    if (this.state.redirctTo && this.state.statusLogout == 1) {
      return (<Redirect
        to={{
          pathname: "/",
          login_alert: {
            msg: "Berhasil Log Out",
          },
        }} />)
    } else if (this.state.redirctTo && this.state.statusLogout != 1) {

      return (<Redirect
        to={{
          pathname: "/",
          login_alert: {
            msg: "Mohon login terlebih dahulu!",
          },
        }} />)
    } else { }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <span>
          <i className="fa fa-user"></i>
        </span>
      </a>
    ));


    const CustomMenuAdd = React.forwardRef(
      ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        return (
          <>
            <div
              className="col-12"
              style={{
                background: 'linear-gradient(45deg, rgba(56, 88, 249, 0.9), rgba(142, 121, 253, 0.9))'
              }}
            >
            </div>
            <div className="col-12">{children}</div>
          </>
        );
      }
    );



    return (
      <>
        <Navbar>

          {/* <Row style={{ paddingTop: "0px" }}>
                            <Col md={6}>
                                <h1 className='dashboard27'>Bahasa</h1>
                            </Col>

                            <Col md={6}>
                                <div className="form-group" style={{ marginTop: "40px" }}>
                                    <select
                                        className="form-control"
                                        style={{ width: "200px", float: "right", marginBottom: "10px", marginRight: "40px" }}
                                        // onChange={this.setYear.bind(this)}
                                        // value={this.state._y}
                                    >
                                        <option value="en">en</option>
                                        <option value="fr">fr</option>
                                    </select>
                                </div>
                            </Col>
                        </Row> */}

          <Container fluid>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />


            <Navbar.Collapse id="basic-navbar-nav1">
              <Nav className="me-auto">
                <Nav.Link href="#" >

                  <div id="rectangle-36-new-btn">
                    <span>{localStorage.getItem("path") ? this.props.t(localStorage.getItem("path").toLowerCase()).toUpperCase() : null}</span>
                  </div>
                </Nav.Link>

                <Nav.Link href="#" >

                  <div id="rectangle-36-new-btn">
                    <span>{localStorage.getItem("company_name") ? this.props.t(localStorage.getItem("company_name")) : null}</span>
                  </div>
                </Nav.Link>

                <Nav.Link href="#">

                  <div id="rectangle-37-new-btn">
                    <span align="center">{this.state.date.toLocaleString(localStorage.getItem("lang") == 'id' ? 'id-ID' : 'en-US', {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric"

                    })}</span>
                  </div>
                </Nav.Link>

                <Nav.Link href="#">

                  <div id="rectangle-38-new-btn">
                    <span>{this.state.date.toLocaleString("id-ID", {
                      hour: '2-digit',
                      minute: '2-digit'
                    })}</span>
                  </div>
                </Nav.Link>


              </Nav>
            </Navbar.Collapse>

            <Navbar.Collapse
              id="basic-navbar-nav2"
              className="justify-content-end"
            >
              <Nav className="ml-auto">
                <NavDropdown
                  // alignRight
                  align="end"
                  title={
                    <a href="#" style={{ fontSize: 10 }}>
                      <img src={messageIcon} alt="" style={{ width: 25 }} />
                      <span className="notify-badge">{this.state.countNotReadAdd}</span>
                    </a>
                  }
                >
                  <Dropdown.Menu as={CustomMenuAdd}>

                    {/* <div className="menu-header-content bg-primary-gradient d-flex justify-content-between">
                      <div className>
                        <h6 className="menu-header-title text-white mb-0 notification-new_add">{this.state.countNotReadAdd} new My Reports</h6>
                      </div>
                      <div className="my-auto ml-auto" style={{ float: 'right' }}>
                        <Badge bg="warning" text="dark" onclick="read_all_notif()">
                          Mark All Read
                        </Badge>
                      </div>
                    </div> */}

                    <div className="main-message-list Notification-scroll" style={{ maxHeight: '320px', overflow: 'scroll' }} id="notif_cont_add">



                    </div>

                    {/* <div className="d-grid">
                      <Button variant="primary" size="lg">
                        VIEW ALL
                      </Button>
                    </div> */}


                  </Dropdown.Menu>
                </NavDropdown>


                <NavDropdown
                  // alignRight
                  align="end"
                  title={
                    <a href="#" style={{ fontSize: 10 }}>
                      <img src={notificationIcon} alt="" style={{ width: 20 }} />
                      <span className="notify-badge">{this.state.countNotReadDoc}</span>
                    </a>
                  }

                >
                  <Dropdown.Menu as={CustomMenuAdd}>

                    {/* <div className="menu-header-content bg-primary-gradient d-flex justify-content-between">
                      <div className>
                        <h6 className="menu-header-title text-white mb-0 notification-new_add">{this.state.countNotReadDoc} new My Reports</h6>
                      </div>
                      <div className="my-auto ml-auto" style={{ float: 'right' }}>
                        <Badge bg="warning" text="dark" onclick="read_all_notif()">
                          Mark All Read
                        </Badge>
                      </div>
                    </div> */}

                    <div className="main-message-list Notification-scroll" style={{ maxHeight: '320px', overflow: 'scroll' }} id="notif_cont_add">



                    </div>
                    {/* 
                    <div className="d-grid">
                      <Button variant="primary" size="lg">
                        VIEW ALL
                      </Button>
                    </div> */}


                  </Dropdown.Menu>
                </NavDropdown>

                <NavDropdown
                  // alignRight
                  align="end"
                  title={
                    <>
                      <div style={{ display: 'flex' }}>
                        <div class="multiLineLabel">

                          {localStorage.getItem("nama_pt").length > 10 ? (
                            <span class="textMaxLine" style={{ fontWeight: 'bold' }}>{localStorage.getItem("nama_pt").substring(0, 10) + ",,,"}</span>
                          ) : (
                            <span class="textMaxLine" style={{ fontWeight: 'bold' }}>{localStorage.getItem("nama_pt")}</span>
                          )
                          }

                          {localStorage.getItem("name").length > 10 ? (
                            <span class="textMaxLine" style={{ fontWeight: 'bold' }}>@{localStorage.getItem("name").substring(0, 10) + ",,,"}</span>
                          ) : (
                            <span class="textMaxLine" style={{ fontWeight: 'bold' }}>@{localStorage.getItem("name")}</span>
                          )
                          }

                        </div>
                        <span>
                          <img alt='img1' src={dropdownIcon} />
                        </span>
                      </div>
                    </>
                  }
                >
                  <NavDropdown.Item href="#" onClick={() => this.handleShow()}>
                    <div class="multiLineLabel">
                      <span class="textMaxLine" style={{ fontWeight: 'bold' }}>{localStorage.getItem("nama_pt")}</span>
                      <span class="textMaxLine">@{localStorage.getItem("name")}</span>
                    </div>
                    <span className="dropdown-item" href="#">
                      <i className="fa fa-unlock" /> Ubah Password
                    </span>
                  </NavDropdown.Item>

                  <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Ubah Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="container-fluid">
                        <div className="App">
                          <div className="form-group mb-3">
                            <div className="text-center d-flex justify-content-between mt-4">
                              <p style={{ fontFamily: "Montserrat" }}>Masukkan Password Lama</p>
                            </div>
                            <input
                              className="num-09-input-field-instance-new"
                              placeholder=""
                              type="password"
                              name="old_password"
                              id="myInput"
                              // onChange={(e) => {
                              //     settomail(e.target.value);
                              // }}
                              autoComplete="off"
                            />
                            <div className="text-center d-flex justify-content-between mt-4">
                              <p style={{ fontFamily: "Montserrat" }}>Masukkan Password Baru</p>
                            </div>
                            <input
                              className="num-09-input-field-instance-new"
                              placeholder=""
                              type="password"
                              name="new_password"
                              id="myInput"
                              // onChange={(e) => {
                              //     settomail(e.target.value);
                              // }}
                              autoComplete="off"
                            />
                            <div className="text-center d-flex justify-content-between mt-4">
                              <p style={{ fontFamily: "Montserrat" }}>Masukkan Konfirmasi Password Baru</p>
                            </div>
                            <input
                              className="num-09-input-field-instance-new"
                              placeholder=""
                              type="password"
                              name="new_password"
                              id="myInput"
                              // onChange={(e) => {
                              //     settomail(e.target.value);
                              // }}
                              autoComplete="off"
                            />
                          </div>

                          <div className="submit-button">
                            <div className="accordion-reset">
                              <input
                                type="submit"
                                // onClick={sendmail}
                                name=""
                                // style={{ display: displayReset }}
                                value="Simpan"
                                className="num-03-filled-button-instance-1-new"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                  </Modal>


                  <NavDropdown.Item href="#" onClick={() => this.Logout(0)}>
                    <span className="dropdown-item" href="#">
                      <i className="fa fa-sign-out" /> Log Out
                    </span>
                  </NavDropdown.Item>

                </NavDropdown>

                <NavDropdown
                  // alignRight
                  align="end"
                  title={
                    <>
                      <div style={{ display: 'flex' }}>
                        <span>
                          {localStorage.getItem("lang") ? localStorage.getItem("lang").toUpperCase() : null}
                        </span>
                      </div>
                    </>
                  }
                >



                  <NavDropdown.Item href="#" onClick={() => this.SetLanguage("id")}>
                    <span className="dropdown-item" href="#">Bahasa Indonesia</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={() => this.SetLanguage("en")}>
                    <span className="dropdown-item" href="#">English</span>
                  </NavDropdown.Item>

                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default withTranslation()(Header);
